import React, { useMemo } from "react";
import TableContainer from "../../../components/Common/TableContainer";

//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { Card, CardBody, Container } from "reactstrap";
import { Link } from "react-router-dom";
import { customers } from "../../../common/data/ecommerce";

const CustomerCouponBalance = () => {

  const columns = useMemo(
    () => [
      {
        Header: "Customer Name",
        accessor: "name",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Coupon Balance",
        accessor: "balance",
        disableFilters: true,
        filterable: false,
      },

      {
        Header: "Action",
        accessor: (cellProps) => {
          return (
            <React.Fragment>
              <Link to="#" className="me-3 text-primary"><i className="mdi mdi-eye font-size-18"></i></Link>
            </React.Fragment>
          )
        },
        disableFilters: true,
        filterable: false,
      },
    ],
    []
  );

  const breadcrumbItems = [
    { title: "Users", link: "/" },
    { title: "Coupon Balances", link: "#" },
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Coupon Balances"
            breadcrumbItems={breadcrumbItems}
          />
          <Card>
            <CardBody>
              <TableContainer
                columns={columns || []}
                data={[] || []} 
                isPagination={false}
                // isGlobalFilter={false}
                iscustomPageSize={false}
                isBordered={false}
                customPageSize={10}
              />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CustomerCouponBalance;