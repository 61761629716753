import React, { useMemo } from "react";
import TableContainer from "../../../components/Common/TableContainer";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Card, CardBody, Container } from "reactstrap";
import { Link } from "react-router-dom";

const InactiveCustomers = () => {
    const columns = useMemo(
        () => [
            {
                Header: "Name ",
                accessor: "name",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Balance",
                accessor: "balance",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "No. of Transactions",
                accessor: "transaction",
                disableFilters: true,
                filterable: false,
            },

            {
                Header: "Action",
                accessor: (cellProps) => {
                    return (
                        <React.Fragment>
                            <Link to="#" className="me-3 text-primary">
                                <i className="mdi mdi-pencil font-size-18"></i>
                            </Link>
                            <Link to="#" className="text-danger">
                                <i className="mdi mdi-account-cancel font-size-18"></i>
                            </Link>
                        </React.Fragment>
                    );
                },
                disableFilters: true,
                filterable: false,
            },
        ],
        []
    );

    const breadcrumbItems = [
        { title: "Users", link: "/" },
        { title: "Inactive Customers", link: "#" },
    ];

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Inactive Customers" breadcrumbItems={breadcrumbItems} />
                    <Card>
                        <CardBody>
                            <TableContainer
                                columns={columns || []}
                                data={[] || []}
                                isPagination={false}
                                // isGlobalFilter={false}
                                iscustomPageSize={false}
                                isBordered={false}
                                customPageSize={10}
                            />
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default InactiveCustomers;
