import axios from "axios";

export const fetchUsers = async (token, apiUrl) => {
    try {
        const response = await axios.get(apiUrl, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });

        // Handle the different response formats
        if (response.data.results) {
            return response.data.results;
        } else if (response.data.data) {
            return response.data.data;
        } else {
            throw new Error("Unexpected response format");
        }
    } catch (error) {
        console.error("Error fetching users: ", error);
        throw error;
    }
};
