import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, CardBody, Label, Button, Alert } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { uploadOfferRequest, uploadOfferSuccess, uploadOfferFailure } from "../../thunks";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import BACKEND_DOMAIN from "../../../../service";

const MerchantProductOfferEdit = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    const { offerId } = useParams();

    const [offer, setOffer] = useState({
        name: "",
        offer_code: "",
        percentage_value: 0,
        description: "",
        offer_value_type: "",
        products: [],
        start_datetime: "",
        end_datetime: "",
        item_price: "",
        exclusive: true,
        status: "Open",
        priority: 0,
        max_global_applications: 100,
        max_user_applications: 50,
        max_discount: 50.0,
        max_applications: 1000,
        offer_type: "Site",
        cash_value: 0,
    });

    const [productsList, setProductsList] = useState([]);
    const [successMessage, setSuccessMessage] = useState("");
    const [discountType, setDiscountType] = useState("");
    const [cashValue, setCashValue] = useState(0);
    const [percentageValue, setPercentageValue] = useState(0);

    const token = useSelector((state) => state.Login.token);

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (token) {
            const apiUrl = `${BACKEND_DOMAIN}/business/merchant/products/list`;
            fetch(apiUrl, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then((response) => response.json())
                .then((data) => setProductsList(data.results))
                .catch((error) => console.error("Error fetching products:", error));
        }
    }, [token]);

    useEffect(() => {
        if (token && offerId) {
            const offerDetailUrl = `${BACKEND_DOMAIN}/business/offer/view/${offerId}/`;
            fetch(offerDetailUrl, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then((res) => res.json())
                .then((res) => {
                    if (res) {
                        // Destructure offer information from the fetched offer:
                        const {
                            name,
                            offer_code,
                            percentage_value,
                            description,
                            offer_value_type,
                            products,
                            start_datetime,
                            end_datetime,
                            item_price,
                            cash_value,
                        } = res.data;

                        const offerDetails = {
                            name,
                            offer_code,
                            percentage_value,
                            description,
                            offer_value_type,
                            products,
                            start_datetime,
                            end_datetime,
                            item_price,
                            cash_value,
                        };

                        const updatedOffer = {
                            ...offer,
                            ...offerDetails,
                        };

                        setOffer(updatedOffer);
                        setCashValue(offer.offer_value_type === "CASH" ? offer.cash_value : 0);
                        setPercentageValue(offer.offer_value_type === "PERCENTAGE" ? offer.percentage_value : 0);
                    }
                })
                .catch((error) => console.error("Error fetching offer details: ", error));
        }
    }, [token, offerId]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name === "offer_value_type") {
            setDiscountType(value);
            // Reset values for percentageValue and cashValue
            setPercentageValue(0);
            setCashValue(0);
        } else if (name === "cash_value") {
            setCashValue(value);
        } else if (name === "percentage_value") {
            setPercentageValue(value);
        }

        setOffer({
            ...offer,
            [name]: value,
        });
    };

    const handleMultiSelectChange = (products) => {
        const selectedProduct = products.length > 0 ? products[0] : null;

        setOffer({
            ...offer,
            products,
            item_price: selectedProduct ? selectedProduct.price : "",
        });
    };

    const handleStartDateChange = (date) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
    };

    const handleFormSubmit = async (event) => {
        event.preventDefault();

        setIsLoading(true);

        try {
            dispatch(uploadOfferRequest());

            const formData = new FormData();
            formData.append("name", offer.name);
            formData.append("offer_code", offer.offer_code);
            formData.append("percentage_value", discountType === "PERCENTAGE" ? percentageValue : 0);
            formData.append("cash_value", discountType === "CASH" ? cashValue : 0);
            formData.append("offer_value_type", offer.offer_value_type);
            formData.append("description", offer.description);
            formData.append("start_datetime", startDate.toISOString().slice(0, -5));
            formData.append("end_datetime", endDate.toISOString().slice(0, -5));
            formData.append("item_price", offer.item_price);
            formData.append("exclusive", true);
            formData.append("status", "Open");
            formData.append("priority", 0);
            formData.append("max_global_applications", 100);
            formData.append("max_user_applications", 50);
            formData.append("max_discount", 50.0);
            formData.append("max_applications", 1000);
            formData.append("offer_type", "Site");

            const productIds = offer.products.map((product) => product.value);
            formData.append("products", JSON.stringify(productIds));

            const apiurl = `${BACKEND_DOMAIN}/business/offer/update/${offerId}/`;
            const response = await fetch(apiurl, {
                method: "PUT",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: formData,
            });

            if (!response.ok) {
                throw new Error("Error updating product");
            }

            const data = await response.json();
            dispatch(uploadOfferSuccess(data));

            // Show a success message:
            setSuccessMessage("Offer has been updated successfully!");

            setTimeout(() => {
                navigate("/route-money/merchant/product-offers");
            }, 2000);

            setTimeout(() => {
                setSuccessMessage("");
            }, 5000);
        } catch (error) {
            dispatch(uploadOfferFailure(error.message));
        }

        setIsLoading(false);
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        title="Edit Offer"
                        breadcrumbItems={[
                            { title: "Offers", link: "/offers" },
                            { title: "Edit Offer", link: "#" },
                        ]}
                    />
                    <Row>
                        <Col xl="12">
                            <Card>
                                <CardBody>
                                    <h4 className="card-title">Edit Offer</h4>
                                    {successMessage && <Alert color="success">{successMessage}</Alert>}
                                    <AvForm onValidSubmit={handleFormSubmit}>
                                        <Row>
                                            <Col md="6">
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="offerName">
                                                        Offer Name
                                                    </Label>
                                                    <AvField
                                                        name="name"
                                                        id="offerName"
                                                        type="text"
                                                        placeholder="Enter Offer Name"
                                                        errorMessage="Enter Offer Name"
                                                        className="form-control"
                                                        onChange={handleInputChange}
                                                        validate={{ required: { value: true } }}
                                                        value={offer.name}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="6">
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="offerCode">
                                                        Offer Code
                                                    </Label>
                                                    <AvField
                                                        name="offer_code"
                                                        id="offerCode"
                                                        type="text"
                                                        placeholder="Enter Offer Code"
                                                        errorMessage="Enter Offer Code"
                                                        className="form-control"
                                                        onChange={handleInputChange}
                                                        validate={{ required: { value: true } }}
                                                        value={offer.offer_code}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="6">
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="discountType">
                                                        Discount Type
                                                    </Label>
                                                    <AvField
                                                        type="select"
                                                        name="offer_value_type"
                                                        id="discountType"
                                                        onChange={handleInputChange}
                                                        validate={{ required: { value: true } }}
                                                    >
                                                        <option value="">Select Discount Type</option>
                                                        <option value="CASH">Cash</option>
                                                        <option value="PERCENTAGE">Percentage</option>
                                                    </AvField>
                                                </div>
                                            </Col>
                                            {discountType === "CASH" && (
                                                <Col md="6">
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="cashValue">
                                                            Cash Value
                                                        </Label>
                                                        <AvField
                                                            name="cash_value"
                                                            id="cashValue"
                                                            type="number"
                                                            placeholder="Enter Cash Value"
                                                            errorMessage="Enter Cash Value"
                                                            className="form-control"
                                                            onChange={handleInputChange}
                                                            validate={{ required: { value: true } }}
                                                            value={offer.cash_value}
                                                        />
                                                    </div>
                                                </Col>
                                            )}

                                            {discountType === "PERCENTAGE" && (
                                                <Col md="6">
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="percentageValue">
                                                            Percentage Value
                                                        </Label>
                                                        <AvField
                                                            name="percentage_value"
                                                            id="percentageValue"
                                                            type="number"
                                                            placeholder="Enter Percentage Value"
                                                            errorMessage="Enter Percentage Value"
                                                            className="form-control"
                                                            onChange={handleInputChange}
                                                            validate={{ required: { value: true } }}
                                                        />
                                                    </div>
                                                </Col>
                                            )}
                                            <Col md="6">
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="offerDescription">
                                                        Description
                                                    </Label>
                                                    <AvField
                                                        name="description"
                                                        id="offerDescription"
                                                        type="text"
                                                        placeholder="Enter Offer Description"
                                                        errorMessage="Enter Offer Description"
                                                        className="form-control"
                                                        onChange={handleInputChange}
                                                        validate={{ required: { value: true } }}
                                                        value={offer.description}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="6">
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="products">
                                                        Select Products
                                                    </Label>
                                                    <Select
                                                        value={offer.products}
                                                        isMulti
                                                        onChange={(selectedOptions) =>
                                                            handleMultiSelectChange(selectedOptions)
                                                        }
                                                        options={productsList.map((product) => ({
                                                            value: product.id,
                                                            label: product.name,
                                                            price: product.price,
                                                        }))}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="6">
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="startDate">
                                                        Start Date
                                                    </Label>
                                                    <DatePicker
                                                        selected={startDate}
                                                        onChange={handleStartDateChange}
                                                        className="form-control"
                                                        id="startDate"
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="6">
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="endDate">
                                                        End Date
                                                    </Label>
                                                    <DatePicker
                                                        selected={endDate}
                                                        onChange={handleEndDateChange}
                                                        className="form-control"
                                                        id="endDate"
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Button color="primary" type="submit" disabled={isLoading}>
                                            {isLoading ? (
                                                <>
                                                    <span
                                                        className="spinner-border spinner-border-sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    ></span>
                                                    <span className="ms-2">Updating..</span>
                                                </>
                                            ) : (
                                                "Update Offer"
                                            )}
                                        </Button>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default MerchantProductOfferEdit;
