import React, { useState } from "react";
import { Container, Row, Col, Card, CardBody, Label, Button, Alert } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { uploadCategoryRequest, uploadCategorySuccess, uploadCategoryFailure } from "../../thunks";
import BACKEND_DOMAIN from "../../../../service";

const MerchantCategoryAdd = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [category, setCategory] = useState({
        name: "",
        image: null,
    });

    const [successMessage, setSuccessMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const token = useSelector((state) => state.Login.token);

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        // If the input is the category dropdown, store the id
        const categoryId = e.target.options ? e.target.options[e.target.selectedIndex].value : null;

        setCategory({
            ...category,
            [name]: name === "category_id" ? categoryId : value,
            image: name === "image" ? e.target.files[0] : category.image,
        });
    };

    const handleFormSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);

        try {
            dispatch(uploadCategoryRequest());

            const formData = new FormData();
            formData.append("name", category.name);
            formData.append("image", category.image);

            const apiurl = `${BACKEND_DOMAIN}/business/merchant/category/create/`;

            const response = await fetch(apiurl, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: formData,
            });

            if (!response.ok) {
                throw new Error("Error adding categoryt");
            }

            const data = await response.json();
            dispatch(uploadCategorySuccess(data));

            // Show a success message:
            setSuccessMessage("Category has been added successfully!");

            setCategory({
                name: "",
                image: null,
            });

            setTimeout(() => {
                navigate("/route-money/merchant/product/categories");
            }, 2000);

            setTimeout(() => {
                setSuccessMessage("");
            }, 5000);
        } catch (error) {
            dispatch(uploadCategoryFailure(error.message));
        }

        setIsLoading(false);
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        title="Add Category"
                        breadcrumbItems={[
                            { title: "Category", link: "/categoriess" },
                            { title: "Add Category", link: "#" },
                        ]}
                    />

                    <Row>
                        <Col xl="12">
                            <Card>
                                <CardBody>
                                    <h4 className="card-title">Add Category</h4>
                                    {successMessage && <Alert color="success">{successMessage}</Alert>}
                                    <AvForm onValidSubmit={handleFormSubmit} enctype="multipart/form-data">
                                        <Row>
                                            <Col md="6">
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="categoryName">
                                                        Category Name
                                                    </Label>
                                                    <AvField
                                                        name="name" // Updated to match the API field name
                                                        id="categoryName"
                                                        type="text"
                                                        placeholder="Enter Category Name"
                                                        errorMessage="Enter Category Name"
                                                        className="form-control"
                                                        onChange={handleInputChange}
                                                        validate={{ required: { value: true } }}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="6">
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="productImage">
                                                        Product Image
                                                    </Label>
                                                    <AvField
                                                        name="image"
                                                        id="productImage"
                                                        type="file"
                                                        errorMessage="Select Product Image"
                                                        className="form-control"
                                                        onChange={handleInputChange}
                                                        validate={{ required: { value: true } }}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>

                                        <Button color="primary" type="submit" disabled={isLoading}>
                                            {isLoading ? (
                                                <>
                                                    <span
                                                        className="spinner-border spinner-border-sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    ></span>
                                                    <span className="ms-2">Adding..</span>
                                                </>
                                            ) : (
                                                "Add Category"
                                            )}
                                        </Button>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default MerchantCategoryAdd;
