import React, { useMemo, useState, useEffect } from "react";
import TableContainer from "../../../components/Common/TableContainer";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Col, Card, CardBody, Container, Modal, ModalHeader,Spinner, ModalBody, ModalFooter, Button, Row } from "reactstrap";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import BACKEND_DOMAIN from "../../../service";
import dateFormatter from "../utilities/dateFormatter";
import { ORDER_TYPE_COLORS } from "../utilities/orderTypes";

const MerchantPendingOrders = () => {
    const token = useSelector((state) => state.Login.token);

    const [orders, setOrders] = useState([]);
    const [menuOpen, setMenuOpen] = useState({});
    const [modal, setModal] = useState(false);
    const [selectedOrderDetails, setSelectedOrderDetails] = useState(null);
    const [totalOrders, setTotalOrders] = useState(0);
    const [pendingOrders, setPendingOrders] = useState(0);
    const [rejectedOrders, setRejectedOrders] = useState(0);
    const [approvedOrders, setApprovedOrders] = useState(0);
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [orderListIsEmpty, setOrderListIsEmpty] = useState(false);
    const [loading, setLoading]= useState(true);

    // Order Badge color for the modal:
    const [orderBadgeColor, setOrderBadgeColor] = useState("success");

    const toggleTooltip = () => {
        setTooltipOpen(!tooltipOpen);
    };

    const toggleModal = () => {
        setModal(!modal);
    };
    const openModal = (orderId) => {
        const selectedOrder = orders.find((order) => order.id === orderId);

        setSelectedOrderDetails(selectedOrder);
        setOrderBadgeColor(ORDER_TYPE_COLORS[selectedOrder.order_type]);
        toggleModal();
    };

   
    const fetchOrders = () => {
        setLoading(true);
        const apiUrl = `${BACKEND_DOMAIN}/business/orders/list`;

        fetch(apiUrl, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                return response.json();
            })
            .then((data) => {
                const total = data.length;
                if (total.length === 0) {
                    setOrderListIsEmpty(true);
                }
                const pending = data.filter((order) => order.status === "pending").length;
                const rejected = data.filter((order) => order.status === "rejected").length;
                const approved = data.filter((order) => order.status === "complete").length;
                const filteredData = data.filter((order) => order.status === "rejected" || order.status === "complete");

                // Update the state
                setTotalOrders(total);
                setPendingOrders(pending);
                setRejectedOrders(rejected);
                setApprovedOrders(approved);

                setOrders(filteredData.reverse());
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                console.error("Error fetching orders:", error);
            });
    };

    // Call fetchOrders on initial render
    useEffect(() => {
        fetchOrders();
    }, [token]);

    const columns = useMemo(
        () => [
            {
                Header: "OrderID",
                accessor: "order_number",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Merchant",
                accessor: "merchant",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Customer",
                accessor: "customer_name",
                disableFilters: true,
                filterable: false,
            },

            {
                Header: "Amount",
                accessor: "total_order_amount",
                disableFilters: true,
                filterable: false,
            },

            {
                Header: "Order Type",
                accessor: "order_type",
                Cell: ({ cell: { value, row } }) => {
                    let typeIcon;
                    let tooltipContent;

                    if (value === "DELIVERY" || "Delivery") {
                        typeIcon = <i className="mdi mdi-moped"></i>;
                        tooltipContent = row.original.order_type;
                    } else if (value === "RESERVE" || "Book Table") {
                        typeIcon = <i className="mdi mdi-calendar-clock-outline"></i>;
                        tooltipContent = row.original.order_type;
                    } else if (value === "PICK-UP" || "pickup") {
                        typeIcon = <i className="mdi mdi-car-back"></i>;
                        tooltipContent = row.original.order_type;
                    } else if (value === "IN-STORE" || "Dine In") {
                        typeIcon = <i className="mdi mdi-silverware-fork-knife"></i>;
                        tooltipContent = row.original.order_type;
                    }

                    return (
                        <React.Fragment>
                            <div>{tooltipContent}</div>
                        </React.Fragment>
                    );
                },
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Date",
                accessor: "created_at",
                Cell: ({ cell: { value } }) => {
                    const datePart = dateFormatter(value);
                    return datePart;
                },
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Status",
                accessor: "payment_status",
                disableFilters: true,
                filterable: false,
                Cell: ({ cell: { value } }) => {
                    let statusBadge;
                    if (value === "PAID") {
                        statusBadge = <span className="badge bg-success-subtle text-success text-uppercase">PAID</span>;
                    } else if (value === "UNPAID") {
                        statusBadge = <span className="badge bg-danger-subtle text-danger text-uppercase">UNPAID</span>;
                    }
                    return <React.Fragment>{statusBadge}</React.Fragment>;
                },
            },
            {
                Header: "Stage",
                accessor: "status",
                Cell: ({ cell: { value } }) => {
                    let statusBadge, statusText;
                    if (value === "complete") {
                        statusBadge = (
                            <span className="badge bg-success-subtle text-success text-uppercase">Accepted</span>
                        );
                        statusText = "Accepted";
                    } else if (value === "rejected") {
                        statusBadge = (
                            <span className="badge bg-danger-subtle text-danger text-uppercase">Rejected</span>
                        );
                        statusText = "Rejected";
                    }
                    return <React.Fragment>{statusBadge}</React.Fragment>;
                },
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Action",
                accessor: (cellProps) => {
                    const orderId = cellProps.id ? cellProps.id : null;
                    return (
                        <React.Fragment>
                            <div className="d-flex align-items-center justify-content-center">
                                <Link to="#" className="me-3 text-primary" onClick={() => openModal(orderId)}>
                                    <i className="mdi mdi-eye font-size-18"></i>
                                </Link>
                            </div>
                        </React.Fragment>
                    );
                },
                disableFilters: true,
                filterable: false,
            },
        ],
        [menuOpen, orders]
    );

    const breadcrumbItems = [
        { title: "Orders", link: "#" },
        { title: "All Orders", link: "#" },
    ];

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Order History" breadcrumbItems={breadcrumbItems} />
                    <Row>
                        <Col md={3}>
                            <Card>
                                <CardBody>
                                    <div className="d-flex">
                                        <div className="flex-1 overflow-hidden">
                                            <p className="text-truncate font-size-14 mb-2">Total Orders</p>
                                            <h4 className="mb-0">{totalOrders}</h4>
                                        </div>
                                        <div className="text-primary">
                                            <i className="mdi mdi-order-bool-descending font-size-24"></i>
                                        </div>
                                    </div>
                                </CardBody>

                                <CardBody className="border-top py-3">
                                    <div className="text-truncate">
                                        <span className="badge bg-success-subtle text-success font-size-11 me-1"></span>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md={3}>
                            <Card>
                                <CardBody>
                                    <div className="d-flex">
                                        <div className="flex-1 overflow-hidden">
                                            <p className="text-truncate font-size-14 mb-2">Pending Orders</p>
                                            <h4 className="mb-0">{pendingOrders}</h4>
                                        </div>
                                        <div className="text-warning">
                                            <i className="mdi mdi-receipt font-size-24"></i>
                                        </div>
                                    </div>
                                </CardBody>

                                <CardBody className="border-top py-3">
                                    <div className="text-truncate">
                                        <span className="badge bg-success-subtle text-success font-size-11 me-1"></span>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md={3}>
                            <Card>
                                <CardBody>
                                    <div className="d-flex">
                                        <div className="flex-1 overflow-hidden">
                                            <p className="text-truncate font-size-14 mb-2">Rejected Orders</p>
                                            <h4 className="mb-0">{rejectedOrders}</h4>
                                        </div>
                                        <div className="text-danger">
                                            <i className="mdi mdi-cancel font-size-24"></i>
                                        </div>
                                    </div>
                                </CardBody>

                                <CardBody className="border-top py-3">
                                    <div className="text-truncate">
                                        <span className="badge bg-success-subtle text-success font-size-11 me-1"></span>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md={3}>
                            <Card>
                                <CardBody>
                                    <div className="d-flex">
                                        <div className="flex-1 overflow-hidden">
                                            <p className="text-truncate font-size-14 mb-2">Accepted Orders</p>
                                            <h4 className="mb-0">{approvedOrders}</h4>
                                        </div>
                                        <div className="text-success">
                                            <i className="mdi mdi-check-circle font-size-24"></i>
                                        </div>
                                    </div>
                                </CardBody>

                                <CardBody className="border-top py-3">
                                    <div className="text-truncate">
                                        <span className="badge bg-success-subtle text-success font-size-11 me-1"></span>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Card>
                        <CardBody>
                            <TableContainer
                                columns={columns || []}
                                data={orders || []}
                                isPagination={false}
                                iscustomPageSize={false}
                                isGlobalFilter={true}
                                isBordered={false}
                                customPageSize={10}
                                isEmpty={orderListIsEmpty}
                                isEmptyContent="There are no orders available."
                            />
                        </CardBody>
                    </Card>
                </Container>
            </div>
            <Modal
                isOpen={modal}
                role="document"
                autoFocus={true}
                centered={true}
                size="lg"
                className="composemodal"
                tabIndex="-1"
                toggle={toggleModal}
            >
                <div className="modal-content">
                    <ModalHeader className="position-relative">
                        <span className="">ORDER SUMMARY</span>
                        <span
                            style={{ position: "absolute", right: 0 }}
                            className={`badge bg-${orderBadgeColor}-subtle text-${orderBadgeColor} text-uppercase p-2 me-3`}
                        >
                            {selectedOrderDetails?.order_type ? selectedOrderDetails.order_type : "None"}
                        </span>
                    </ModalHeader>
                    <ModalBody style={{ maxHeight: "800px", overflowY: "auto" }}>
                        {selectedOrderDetails && (
                            <div>
                                <div className="d-flex flex-column flex-sm-row justify-content-between">
                                    <div>
                                        <h6 className="me-2 mb-3" style={{ fontSize: "14px" }}>
                                            CUSTOMER DETAILS
                                        </h6>

                                        <div className="d-flex align-items-center">
                                            <h6 style={{ fontSize: "12px" }}>Name :</h6>
                                            <p className="ms-2 mt-2">{selectedOrderDetails.customer_name}</p>
                                        </div>

                                        <div className="d-flex align-items-center">
                                            <h6 style={{ fontSize: "12px" }}>Email :</h6>
                                            <p className="ms-2 mt-2">{selectedOrderDetails.customer_email}</p>
                                        </div>

                                        <div className="d-flex align-items-center">
                                            <h6 style={{ fontSize: "12px" }}>Phone NO :</h6>
                                            <p className="ms-2 mt-2">{selectedOrderDetails.phone_number}</p>
                                        </div>

                                        {selectedOrderDetails.order_type === "Delivery" && (
                                            <div>
                                                <h6 style={{ fontSize: "12px" }}>Address :</h6>
                                                <p className="ms-2 mt-2">{selectedOrderDetails.address}</p>
                                            </div>
                                        )}

                                        {selectedOrderDetails.order_type === "IN-STORE" &&(
                                              <div className="d-flex align-items-center">
                                                <h6 style={{ fontSize: "12px" }}>Table Number :</h6>
                                                <p className="ms-2 mt-2 p-3">{selectedOrderDetails.table_name}</p>
                                                </div>
                                          )}

                                        {selectedOrderDetails.order_type === "Book Table" || selectedOrderDetails.order_type === "RESERVE" && (
                                            <div className="d-flex align-items-center">
                                                <h6 style={{ fontSize: "12px" }}>Table Number :</h6>
                                                <p className="ms-2 mt-2 p-3">{selectedOrderDetails.table_name}</p>
                                                <h6 style={{ fontSize: "12px" }}>Reservation Time :</h6>
                                                <p className="ms-2 mt-2 p-3">{selectedOrderDetails.time}</p>
                                                <h6 style={{ fontSize: "12px" }}>Number of Guests :</h6>
                                                <p className="ms-2 mt-2 p-3">{selectedOrderDetails.guests}</p>
                                            </div>
                                        )}

                                        {selectedOrderDetails.order_type === "Pickup" && (
                                            <div className="d-flex align-items-center">
                                                <h6 style={{ fontSize: "12px" }}>Pickup Time :</h6>
                                                <p className="ms-2 mt-2">{selectedOrderDetails.time}</p>
                                            </div>
                                        )}
                                    </div>

                                    <div className="mt-3 mt-sm-0">
                                        <h6 className="me-2 mb-3" style={{ fontSize: "14px" }}>
                                            FULFILLMENT DETAILS
                                        </h6>

                                        <div className="d-flex align-items-center">
                                            <h6 style={{ fontSize: "12px" }}>Order NO :</h6>
                                            <p className="ms-2 mt-2">{selectedOrderDetails.order_number}</p>
                                        </div>

                                        <div className="d-flex align-items-center">
                                            <h6 style={{ fontSize: "12px" }}>Placed On :</h6>
                                            <p className="ms-2 mt-2">
                                                {dateFormatter(selectedOrderDetails.created_at)}
                                            </p>
                                        </div>

                                        <div className="d-flex align-items-center">
                                            <h6 style={{ fontSize: "12px" }}>Branch Name :</h6>
                                            <p className="ms-2 mt-2">{selectedOrderDetails.branch}</p>
                                        </div>
                                    </div>
                                </div>
                                {/* Separation Line */}
                                <hr style={{ border: "1px solid #ddd", margin: "10px 0" }} />

                                {/* Order Details Section */}
                                <div>
                                    <h6>Order Details</h6>
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th>Image</th>
                                                <th>Name</th>
                                                <th>Quantity</th>
                                                <th>Price</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {selectedOrderDetails.order_items.map((item, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        <img
                                                            src={item.product_details.image}
                                                            alt={item.product_details.name}
                                                            style={{
                                                                width: "70px",
                                                                height: "50px",
                                                                objectFit: "cover",
                                                                borderRadius: 8,
                                                            }}
                                                        />
                                                    </td>
                                                    <td>{item.product_details.name}</td>
                                                    <td>{item.quantity}</td>
                                                    <td>{item.product_details.price * item.quantity} Ksh</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td colSpan="2"></td>
                                                <td>Total:</td>
                                                <td>
                                                    {selectedOrderDetails.order_items
                                                        .reduce((total, item) => {
                                                            return total + item.product_details.price * item.quantity;
                                                        }, 0)
                                                        .toFixed(2)}{" "}
                                                    Ksh
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={toggleModal}>
                            cancel
                        </Button>{" "}
                    </ModalFooter>
                </div>
            </Modal>
            <Modal isOpen={loading} centered>
                <ModalBody className="text-center">
                    <Spinner style={{ width: '3rem', height: '3rem' }} color="primary" />
                    <div>Loading...</div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
};

export default MerchantPendingOrders;
