import React, { useState, useEffect,useRef } from "react";
import { Container, Row, Col, Card, CardBody, Label, Button, Spinner, Alert,InputGroup, InputGroupAddon, InputGroupText,Modal,ModalHeader,ModalBody,ModalFooter } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
// import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { orderProductRequest, orderProductSuccess, orderProductFailure } from "../../Orders/thunks";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import BACKEND_DOMAIN from "../../../service";
import {
    fetchProductsOrdersAndBranches,
    fetchProductOffersData,
    fetchCashbackOffersData,
    fetchWalletBalanceData
} from '../../Dashboard/data_fetching/dashboardData';
import axios from "axios";


const SEND_OTP_URL = `${BACKEND_DOMAIN}/users/generate/pin`;
const VERIFY_OTP_URL = `${BACKEND_DOMAIN}/users/verify-otp`;


// React PhoneInput custom styles
const customInputStyle = {
    width: "100%",
    height: "40px",
    backgroundColor: "inherit",
    border: "1px solid #cacaca",
    marginTop: "50px",
};

const customDropdownStyle = {
    backgroundColor: "inherit",
    borderRadius: 0,
    boxShadow: "none",
    border: "1px solid transparent",
    color: "inherit",
    height: "fit-content",
    marginTop: 0,
    marginLeft: "-2px",
    
};

const Index = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const token = useSelector((state) => state.Login.token);
    const [userData, setUserData] = useState({});
    const [walletBalance, setWalletBalance] = useState(0);
    const [modal, setModal]=useState(false);
    const [inputValue, setInputValue] = useState('');
    const [amountLoading, setAmountLoading] = useState(true);
    const [noBranches, setNoBranches] = useState(false);
    const [branches, setBranches] = useState([]);
    const [loading, setLoading] = useState(false);
    const [branchWallet, setBranchWallet] = useState(0);

    const toggle = () => setModal(!modal);
    // fetch current ballance
    // f3erching global data
    const setLedger = async (token) => {
        try {
            setAmountLoading(true)
            const [
                walletBalanceData
            ] = await Promise.all([
                fetchWalletBalanceData(token)
            ]);
            setWalletBalance(walletBalanceData.ledger_balance); // Ensure correct access to ledger_balance
        }catch(e){
      console.log("failed to fetch data ",e);
        } finally{
            setAmountLoading(false)
        }
    };
    
    useEffect(() => {
        setLedger(token);
    }, [token]);
    // end fetch

        // Define the fetchUserData function
        const fetchUserData = async () => {
            try {
                const apiUrl = `${BACKEND_DOMAIN}/business/merchant/detail/`;
                const response = await axios.get(apiUrl, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
    
                // Set the fetched user data in the state
                setUserData(response.data);
    
                // userName = capitalizeFirstLetter(userData.name);
            } catch (error) {
                console.error("Error fetching user details:", error.message);
            }
        };
    
        // Use useEffect to fetch user details from the API when the component mounts
 
        const apiUrlBranch = `${BACKEND_DOMAIN}/business/merchant/branch/list`;

  
    const fetchBranchData = async (token, isInitial = true) => {
        try {
            if (isInitial) setLoading(true); 
            const response = await axios.get(apiUrlBranch, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            });
            if (response.data.data.length === 0) {
                setNoBranches(true);
            } else {
                setNoBranches(false);
            }
            setBranches(response.data.data);

          
        } catch (e) {
            console.log("Error fetching branches -> ", e);
        }
        finally {
            if (isInitial) setLoading(false); // Set loading to false once data is loaded
        }
    };


const fetchBranchWalletBalanceData = async (authToken, branchId) => {
    const branchWalletApi = `https://backoffice.route.money/api/v1/wallets/merchant/branch/balance/${branchId}`;
   
    try {
        setAmountLoading(true);
        const response = await axios.get(branchWalletApi, {
            headers: {
                Authorization: `Bearer ${authToken}`,
                "Content-Type": "application/json",
            },
        });
       // console.log('branch balance api ', response.data.data.wallet.ledger_balance);
       setBranchWallet(response.data.data.wallet.ledger_balance); // Ensure correct access to nested data
    } catch (error) {
        console.error("Failed to fetch wallet balance -> ", error);
        setAmountLoading(false);
        // Handle and display the error
        if (error.response) {

            
        } else if (error.request) {
            // The request was made but no response was received
            console.log('Request data:', error.request);

          
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error message:', error.message);

        }
    }finally{
        setAmountLoading(false);
    }

};

    useEffect(() => {
        fetchUserData();
        fetchBranchData(token, true);
    }, [token]); // The dependency array ensures that the effect runs only when authToken changes


    const [withdrawdata, setWithdrawData] = useState({
        mode: "",
        phone_number:userData.phone_number,
        bankName: "",
        walletId:"",
        provider_id:"",
        accountNumber:"",
        amount:"",
        otp:"",
        user_id:""
    });

    const [banks, setBanks] = useState([]);
    const [targetWallet, setTargetWallet]= useState([]);
    const [theSelectedWallet, setSelectedWallet]=useState("")
    const [products, setProducts]=useState([]);
    const [successMessage, setSuccessMessage] = useState("");

    // otp verification
    const [otp, setOtp] = useState("");
    const [otpSentMessage, setOtpSentMessage] = useState("");
    const [otpVerifiedMessage, setOtpVerifiedMessage] = useState("");
    const [otpVerificationSuccessful, setOtpVerificationSuccessful] = useState(false);
    const [isFieldsValid, setIsFieldsValid] = useState(true);
    // const [withdrawMode, setWithdrawMode] = useState('');
    const [formType, setFormType] = useState('main'); // 'main' or 'branch'
   

    
    // Loading spinners for said actions:
    const [isSendingOTP, setIsSendingOTP] = useState(false);
    const [isVerifyingOTP, setIsVerifyingOTP] = useState(false);

     // OTP sending and verifying error Message:
     const [otpNotSentError, setOtpNotSentError] = useState(null);
     const [otpVerificationError, setOtpVerificationError] = useState(null);
     const [resetTimer, setResetTimer] = useState(0);



    const [isLoading, setIsLoading] = useState(false);
    const [withdrawMode, setWithdrawMode] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [unsuccessfulMessage, setUnsucccessfulMessage] = useState('');

    //     const [showPin, setShowPin] = useState(false);

     // Sending OTP
     const sendOTP = async () => {
        let phone_number;
       // const { phone_number, email } = allFormData.pane1;

        if (phone_number === "") {
            setOtpNotSentError("Email and Phone number added are INVALID.");
            setTimeout(() => {
                setOtpNotSentError(null);
            }, 3000);

            return;
        }

        setIsSendingOTP(true);


            try {
                const res = await axios.post(SEND_OTP_URL, {
                    phone_number: token
                }, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization":`Bearer ${token}`,
                    },
                });
    

            setOtpSentMessage(res.data["message"]);

            setTimeout(() => {
                setOtpSentMessage(null);
            }, 3000);
        } catch (e) {
            setOtpNotSentError("An Error occurred.");
            setTimeout(() => {
                setOtpNotSentError(null);
            }, 3000);
        } finally {
            setIsSendingOTP(false);
        }
    };

    // Resend otp:
    const startTimer = () => {
        setResetTimer(30);

        const interval = setInterval(() => {
            setResetTimer((prev) => {
                if (prev === 1) {
                    clearInterval(interval); // Clear interval when timer reaches 0
                }
                return prev - 1;
            });
        }, 1000);
    };

    const resendOTPWithTimer = async () => {  
        await sendOTP();
        setModal(!modal);

        startTimer();
    };


    useEffect(() => {
        if (token) {
        const banksApi = `https://backoffice.route.money/api/v1/wallets/merchant/providers`;
            fetch(banksApi, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => response.json())
                .then((data) => setBanks(data.data.providers))
                .catch((error) => console.error("Error fetching banks:", error));
        }

    }, [token]);

    // setTargetWallet

    useEffect(() => {
        if (token) {
        const banksApi = `https://backoffice.route.money/api/v1/users/users/wallets`;
            fetch(banksApi, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => response.json())
                .then((data) => setTargetWallet(data.data))
                .catch((error) => console.error("Error fetching banks:", error));
        }

    }, [token]);

  

   const handleInputChange = (e) => {
    const { name, value } = e.target ? e.target : { name: 'phone_number', value: e };

    // Clear error message after 3 seconds if the input value is empty
    if (!value) {
        setTimeout(() => {
            setErrorMessage('');
        }, 3000);
    }

    if (name === 'bankName' && banks.length > 0) {
        const selectedBank = banks.find(bank => bank.providerName === value);
        if (selectedBank) {
            setWithdrawData((prevwithdrawdata) => ({
                ...prevwithdrawdata,
                [name]: value,
                provider_id: selectedBank.providerName // Set provider_id based on selected bank
            }));
        }
    } 

    // wallte select
    if (name === 'fundWallet' && targetWallet.length > 0) {
      //  console.log('selected walled id  ',value);
      
        const selectedWallet = targetWallet.find(tw => tw.id === value);
        setSelectedWallet(selectedWallet);
        if (selectedWallet) {
            setWithdrawData((prevwithdrawdata) => ({
                ...prevwithdrawdata,
                [name]: value,
                provider_id: selectedWallet.id // Set provider_id based on selected bank
            }));
        }
    } 
    // Branch Selection: Fetch amount when branch is selected
    else if (name === 'branchName' && branches.length > 0) {
        const selectedBranch = branches.find(branch => branch.business_id === value);
        if (selectedBranch) {
            // Fetch the branch amount (assuming `branchAmount` is a field in the branch object)
            const branchAmount = selectedBranch.amount || 0; // Use branch amount or 0 if undefined
            
            // Update the state with selected branch and amount
            setWithdrawData((prevwithdrawdata) => ({
                ...prevwithdrawdata,
                [name]: value, // Set the branch name
                user_id: selectedBranch.business_id, // Set the branch business ID
                amount: branchAmount // Automatically set the amount based on the branch
            }));

            // Fetch wallet balance when branch is selected (if necessary)
            fetchBranchWalletBalanceData(token, selectedBranch.business_id);
        }
    } 
    // Handle other inputs normally
    else {
        setWithdrawData((prevwithdrawdata) => ({
            ...prevwithdrawdata,
            [name]: value
        }));
    }

    //Validation for amount input
    if (name === 'amount') {
        const amount = parseFloat(value);

    const currentBalance = formType === "branch" ? branchWallet : walletBalance; // Use branch wallet if branch is selected

        if (amount > currentBalance) {
            setErrorMessage(`The wallet balance is lower than the entered amount. Your wallet balance is Ksh. ${walletBalance}`);
        } else if (amount < 10) {
            setErrorMessage('Cannot withdraw below Ksh. 10');
        } else if (currentBalance < 10) {
            setErrorMessage('Wallet Balance below Ksh. 10');
        } else {
            setErrorMessage('');
        }
    }
};

   
   const handleWithdrawModeChange = (event) => {
        setWithdrawMode(event.target.value);
        handleInputChange(event);
        setWithdrawData({
            ...withdrawdata,
            mode: withdrawMode,
             phone_number:userData.phone_number, // Reset phone number when mode changes
            bankName: '', // Reset bank name when mode changes
            provider_id: '', // Reset provider id when mode changes
            accountNumber: '', // Reset account number when mode changes
            amount: '', // Reset mpesa amount when mode changes,
            otp:'',
        });
    };

    const handleFormTypeChange = (e) => {
        setFormType(e.target.value);
      };

    const handleFormSubmit = async (event) => {
        event.preventDefault();
       // console.log('withraw entered');

        setIsLoading(true);

        try {
            dispatch(orderProductRequest());
     
 
          const apiUrl = `https://backoffice.route.money/api/v1/wallets/transactions`

            const response = await fetch(apiUrl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body:JSON.stringify({
                    amount: withdrawdata.amount,
                    beneficiary_account:theSelectedWallet.wallets[0].wallet_account,
                    pin:withdrawdata.otp,
                    narration: "transfer",
                    provider: "ROUTEWALLET",
                    beneficiary_reference: "013364321617653",
            
                }),
            });
         
    const data = await response.json();

   // console.log(' send fund response is ',data)

    if (!response.ok) {
        setModal(!modal);
        console.log('Transaction error response:', data);
        // setUnsucccessfulMessage('Transaction failed,',data.errors)
        const errorMessage = data.errors[0];
       setUnsucccessfulMessage(`Transaction failed: ${errorMessage}`);

        throw new Error(data.message || "Transaction unsuccessful, please try again");
    }else{
        setModal(!modal);
        if(data.status==="success"){
           setSuccessMessage(data.data.message);
        //setSuccessMessage(`ksh ${withdrawdata.amount} successfully transferred !`);
           setLedger(token);
        }else{
            console.log('unsuccess hit');
            setUnsucccessfulMessage('Failure ',data.message)
        }
     

        setWithdrawMode("");
    }
           // dispatch(orderProductSuccess(data));

            setTimeout(() => {
                setSuccessMessage("");
            }, 3000);
        } catch (error) {
            setModal(!modal);
            const errorMessage = error;
            //console.log('error ',errorMessage);
            setUnsucccessfulMessage(`${errorMessage}`);
            //setUnsucccessfulMessage('Transaction failed, catch ',error.message);
           // dispatch(orderProductFailure(error.message));

        setTimeout(() => {
            setUnsucccessfulMessage("");
            }, 4000);
        }

        setIsLoading(false);
    };


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        title="Send Funds"
                        breadcrumbItems={[
                            { title: "Back", link: "/route-money/withdraw" },
                            { title: "Send Funds", link: "#" },
                        ]}
                    />

                    <Row>
                        <Col xl="12">
                            <Card>
                                <CardBody>
                                    {/* <h4 className="card-title">Withdraw Cash</h4> */}
                                    {successMessage && <Alert color="success">{successMessage}</Alert>}
                                    {unsuccessfulMessage && <Alert color="danger">{unsuccessfulMessage}</Alert>}

                                      {/* Radio Buttons to toggle between "Main" and "Branches" */}
                               
                                    <AvForm onValidSubmit={handleFormSubmit} enctype="multipart/form-data">
                                        <Row>                                   
                                            <Col md="6">
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="product">
                                                        Send To
                                                    </Label>
                                                    <AvField
                                                    type="select"
                                                    name="fundWallet"
                                                    value={withdrawdata.walletId}
                                                    id="fundWallet"
                                                    onChange={handleInputChange}
                                                    validate={{ required: { value: true } }}
                                                >
                                                    <option value="">Select Wallet</option>
                                                    {targetWallet && targetWallet.map(tw => (
                                                        <option key={tw.id} value={tw.id}>
                                                            {tw.username}
                                                        </option>
                                                    ))}
                                                </AvField>
                                               {/* display the wallet holder info */}
                                               {theSelectedWallet && (
                                                <Alert color="info">
                                                    <ul>
                                                    <li><strong>Name:</strong> {theSelectedWallet.first_name} {theSelectedWallet.last_name}</li>
                                                    <li><strong>Email:</strong> {theSelectedWallet.email}</li>
                                                    <li><strong>Phone Number:</strong> {theSelectedWallet.phone_number}</li>
                                                       {/* Check if wallets exist and display wallet_account */}
                                                        {theSelectedWallet.wallets && theSelectedWallet.wallets.length > 0 && (
                                                            <li><strong>Wallet Account:</strong> {theSelectedWallet.wallets[0].wallet_account}</li>
                                                        )}

                                                    </ul>
                                                </Alert>
                                                )}
                                                </div>
                                            </Col>
       
                
                            <Col md="6">
                            <div className="mb-3">
                            

                                                {amountLoading && (
                        <Label className="form-label" htmlFor="mpesaAmount">
                            <Spinner size="sm" color="warning" />
                            <span className="ml-2 text-warning">Wait Amount loading...</span>
                        </Label>
                    )}
                    {!amountLoading && (
                        <Label className="form-label" htmlFor="mpesaAmount">
                            {formType === 'branch' ? (
                                // Display the branch wallet balance when a branch is selected
                                <span className="ml-2 text-warning">Branch Balance Ksh. {branchWallet}</span>
                            ) : (
                                // Display the default wallet balance if no branch is selected
                                <span className="ml-2 text-warning">Balance Ksh. {walletBalance}</span>
                            )}
                        </Label>
)}



                                <AvField
                                name="amount"
                                id="amount"
                                type="number"
                                placeholder="Amount"
                                value={withdrawdata.amount}
                                errorMessage="Enter Amount"
                                className="form-control"
                                onChange={handleInputChange}
                                validate={{ required: { value: true } }}
                                />

                                {/* Show error message if it exists */}
                                {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
                            </div>
                            </Col>

                           {/* set up modal */}
                                                         
                           <Modal isOpen={modal} toggle={toggle} className="custom-modal">
                                        <ModalHeader>Verify OTP</ModalHeader>
                                        <ModalBody>
                                            <div className="d-flex justify-content-center align-items-center">
                                            <AvField
                                                name="otp"
                                                onChange={handleInputChange}
                                                type="text"
                                                className="form-control"
                                                id="otp"
                                                value={withdrawdata.otp}
                                                placeholder="Enter OTP"
                                                style={{ width: '100%' }}
                                            />
                                            </div>
                                        </ModalBody>
                                        <ModalFooter>
                                            <Button color="secondary" onClick={toggle}>
                                            Close
                                            </Button>
                                            <Button color="success" onClick={handleFormSubmit}>
                                            {isLoading ? (
                                                <>
                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                <span className="ms-2">Processing...</span>
                                                </>
                                            ) : (
                                                "Withdraw"
                                            )}
                                            </Button>
                                        </ModalFooter>
                                        </Modal>

                                                            {/* end of modal */}
               
                        {isFieldsValid && (
                        <Col md="6">
                            <div className="mb-3">
                              
            
                              <div className="d-flex align-items-center mb-2">
                           
                                
                                </div>
                            {otpVerifiedMessage && (
                                <Alert color="success">
                                    {otpVerifiedMessage}
                                </Alert>
                            )}
                            </div>
                        </Col>
)}
                                            
                                        </Row>
                                   
                                   {/* put the otp verifier */}
                                   {!errorMessage &&( 
                                   <Button
                                    type="button"
                                    color="primary"
                                    onClick={resendOTPWithTimer}
                                    className="ms-2"
                                    disabled={isSendingOTP || resetTimer > 0}
                                >
                                    {isSendingOTP ? (
                                        <>
                                            <span
                                                className="spinner-border spinner-border-sm"
                                                role="status"
                                                aria-hidden="true"
                                            ></span>
                                            <span className="ms-2">
                                                Sending OTP...
                                            </span>
                                        </>
                                    ) : resetTimer > 0 ? (
                                        `Resend in: ${resetTimer}s`
                                    ) : (
                                        "Proceed"
                                    )}
                                </Button>
                                )}
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Index;
