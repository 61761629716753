import BACKEND_DOMAIN from "../../service";

const API_URL = `${BACKEND_DOMAIN}/business/product/add`;
export const uploadProductRequest = () => ({
    type: "UPLOAD_PRODUCT_REQUEST", // Replace with your actual action type
});

export const uploadProductSuccess = (data) => ({
    type: "UPLOAD_PRODUCT_SUCCESS", // Replace with your actual action type
    payload: data,
});

export const uploadProductFailure = (error) => ({
    type: "UPLOAD_PRODUCT_FAILURE", // Replace with your actual action type
    payload: error,
});

export const uploadProduct = (productData, token) => async (dispatch) => {
    try {
        dispatch(uploadProductRequest());
        const formData = new FormData();
        formData.append("name", productData.name);
        formData.append("price", productData.price);
        formData.append("category_id", productData.category_id);
        formData.append("subcategory_id", productData.subcategory_id);
        formData.append("image", productData.image);
        formData.append("description", productData.description);
        formData.append("merchant_id", productData.merchant_id);
        const response = await fetch(API_URL, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: formData,
        });

        if (!response.ok) {
            throw new Error("Error adding product");
        }

        const data = await response.json();
        dispatch(uploadProductSuccess(data));
    } catch (error) {
        dispatch(uploadProductFailure(error.message));
    }
};

const CATEGORY_API_URL = `${BACKEND_DOMAIN}/business/category/create`;
export const uploadCategoryRequest = () => ({
    type: "UPLOAD_CATEGORY_REQUEST", // Replace with your actual action type
});

export const uploadCategorySuccess = (data) => ({
    type: "UPLOAD_CATEGORY_SUCCESS", // Replace with your actual action type
    payload: data,
});

export const uploadCategoryFailure = (error) => ({
    type: "UPLOAD_CATEGORY_FAILURE", // Replace with your actual action type
    payload: error,
});

export const uploadCategory = (categoryData, token) => async (dispatch) => {
    try {
        dispatch(uploadCategoryRequest());
        const formData = new FormData();
        formData.append("name", categoryData.name);
        // formData.append("price", productData.price);
        // formData.append("category_id", productData.category_id);
        formData.append("image", categoryData.image);
        // formData.append("description", productData.description);
        //formData.append("merchant_id", productData.merchant_id);
        const response = await fetch(CATEGORY_API_URL, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: formData,
        });

        if (!response.ok) {
            throw new Error("Error adding category");
        }

        const data = await response.json();
        dispatch(uploadCategorySuccess(data));
    } catch (error) {
        dispatch(uploadCategoryFailure(error.message));
    }
};

const SUBCATEGORY_API_URL = `${BACKEND_DOMAIN}/business/subcategories/create/`;
export const uploadSubCategoryRequest = () => ({
    type: "UPLOAD_SUBCATEGORY_REQUEST", // Replace with your actual action type
});

export const uploadSubCategorySuccess = (data) => ({
    type: "UPLOAD_SUBCATEGORY_SUCCESS", // Replace with your actual action type
    payload: data,
});

export const uploadSubCategoryFailure = (error) => ({
    type: "UPLOAD_SUBCATEGORY_FAILURE", // Replace with your actual action type
    payload: error,
});

export const uploadSubCategory = (subcategoryData, token) => async (dispatch) => {
    try {
        dispatch(uploadSubCategoryRequest());
        const formData = new FormData();
        formData.append("name", subcategoryData.name);
        // formData.append("price", productData.price);
        formData.append("category", subcategoryData.category);
        //formData.append("image", categoryData.image);
        // formData.append("description", productData.description);
        //formData.append("merchant_id", productData.merchant_id);
        const response = await fetch(SUBCATEGORY_API_URL, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: formData,
        });

        if (!response.ok) {
            throw new Error("Error adding subcategory");
        }

        const data = await response.json();
        dispatch(uploadSubCategorySuccess(data));
    } catch (error) {
        dispatch(uploadSubCategoryFailure(error.message));
    }
};
