import axios from "axios";
import BACKEND_DOMAIN from "../../../../service";

// API Endpoints:
const dashboardDataAPI = `${BACKEND_DOMAIN}/business/dashboard`;
const productsAPI = `${BACKEND_DOMAIN}/business/merchant/products/list`;
const productCategoriesAPI = `${BACKEND_DOMAIN}/business/merchant/categories`;
const productOffersAPI = `${BACKEND_DOMAIN}/business/offers`;
const cashbackOffersAPI = `${BACKEND_DOMAIN}/business/merchant/cashback/list`;
const ordersAPI = `${BACKEND_DOMAIN}/business/orders/list`;
const walletBalanceAPI = `${BACKEND_DOMAIN}/wallets/merchant/balance`;

// https://backoffice.route.money/api/v1/wallets/merchant/branch//balance/-O4d0zEtXpH53tcnkPzf
export const fetchProductsOrdersAndBranches = async (token) => {
    try {
        const response = await axios.get(dashboardDataAPI, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });
        return response.data;
    } catch (e) {
        console.error("Failed to fetch Products, Orders and Branches data -> ", e);
    }
};

export const fetchWalletBalanceData = async (token) => {
    try {
        const response = await axios.get(walletBalanceAPI, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });
        return response.data.data.wallet; // Ensure correct access to nested data
    } catch (e) {
        console.error("Failed to fetch wallet balance -> ", e);
        throw e; // Throw error to indicate failure
    }
};

export const fetchProducts = async (token) => {
    try {
        const response = await axios.get(productsAPI, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });
        return response.data.results;
    } catch (e) {
        console.error("Failed to fetch products -> ", e);
    }
};

export const fetchProductOffersData = async (token) => {
    try {
        const response = await axios.get(productOffersAPI, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });
        const productOffers = response.data;
        return productOffers.length;
    } catch (e) {
        console.error("Failed to fetch product offers -> ", e);
    }
};

export const fetchCashbackOffersData = async (token) => {
    try {
        const response = await axios.get(cashbackOffersAPI, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });
        const cashbackOffers = response.data;
        return cashbackOffers.count;
    } catch (e) {
        console.error("Failed to fetch cashback offers -> ", e);
    }
};




export const fetchCategories = async (token) => {
    try {
        const response = await axios.get(productCategoriesAPI, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });

        const data = response.data;
        return data.categories;
    } catch (e) {
        console.error("Failed to fetch categories", e);
    }
};

export const recentOrders = async (token) => {
    try {
        const response = await axios.get(ordersAPI, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });

        return response.data;
    } catch (e) {
        console.error("Failed to fetch orders", e);
    }
};
