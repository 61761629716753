import React, { useEffect, useMemo, useState } from "react";
import TableContainer from "../../../components/Common/TableContainer";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Card, CardBody, Container,Spinner, Modal,ModalBody } from "reactstrap";
import { useSelector } from "react-redux";
import convertDate from "../../Orders/utilities/dateFormatter";
import axios from "axios";

const Merchants = () => {
    const [merchants, setMerchants] = useState([]);
    const authToken = useSelector((state) => state.Login.token);
    const [loading, setLoading] = useState(false);  // Added loading state

  const merchantApi=`https://backoffice.route.money/api/v1/admin/users/merchants`;

    useEffect(() => {
        if (authToken) {
          const fetchMerchant = async (isInitial = false) => {
            try {
                if (isInitial) setLoading(true); 
              const response = await axios.get(merchantApi, {
                headers: {
                  Authorization: `Bearer ${authToken}`,
                  'Content-Type': 'application/json',
                },
              });
              //console.log('merchnts are ',response.data.results);
              setMerchants(response.data.results);
            } catch (error) {
              console.error("Error fetching roles:", error);
            }
            finally {
                if (isInitial) setLoading(false); // Set loading to false once data is loaded
            }
          };
    
    
         // fetchMerchant();
         fetchMerchant(true);

         // Set up polling every 10 seconds for subsequent data fetches without loading spinner
         const intervalId = setInterval(() => {
             fetchMerchant();  // No `true` argument, so `isInitial` is `false`
         }, 30000); // Poll every 10 seconds

         // Cleanup interval on component unmount
         return () => clearInterval(intervalId);
     
      
        }
      }, [authToken]);
    
    // const fetchMerchants = async (token) => {
    //     const allUsers = await fetchStructuredUsers(token);

    //     setMerchants(allUsers.Merchants);
    // };

    // useEffect(() => {
    //     fetchMerchants(authToken);
    // }, [authToken]);

    const columns = useMemo(
        () => [
            {
                Header: "First Name",
                accessor: "first_name",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Route Tag",
                accessor: "username",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Email",
                accessor: "email",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Phone No.",
                accessor: "phone_number",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Date Created",
                accessor: "created_at",
                disableFilters: true,
                filterable: false,
                Cell: ({ cell: { value } }) => {
                    let modifiedDate = convertDate(value);
                    return <React.Fragment>{modifiedDate}</React.Fragment>;
                },
            },
            {
                Header: "Status",
                accessor: "is_active",
                disableFilters: true,
                filterable: false,
                Cell: ({ cell: { value } }) => {
                    let isActive;
                    if (value === true) {
                        isActive = "Active";
                    } else {
                        isActive = "Inactive";
                    }
                    return <>{isActive}</>;
                },
            },
        ],
        []
    );

    const breadcrumbItems = [
        { title: "Users", link: "/" },
        { title: "Merchants", link: "#" },
    ];

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Merchants" breadcrumbItems={breadcrumbItems} />
                    <Card>
                        <CardBody>
                            <TableContainer
                                columns={columns || []}
                                data={merchants || []}
                                isPagination={false}
                                iscustomPageSize={false}
                                isBordered={false}
                                isGlobalFilter={true}
                                customPageSize={10}
                            />
                        </CardBody>
                    </Card>
                </Container>
            </div>
               {/* Modal for Spinner */}
               <Modal isOpen={loading} centered>
                <ModalBody className="text-center">
                    <Spinner style={{ width: '3rem', height: '3rem' }} color="primary" />
                    <div>Loading...</div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
};

export default Merchants;
