import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Row, Col, Alert, Button, Container, Input, Label } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import axios from "axios";
import { forgetUser } from "../../store/actions";
import logodark from "../../assets/images/logo-dark.png";
import logolight from "../../assets/images/logo-light-new.jpg";
import withRouter from "../../components/Common/withRouter";
import { getEmailFromSession } from "./utilities/sessionWork";
import BACKEND_DOMAIN from "../../service";

const PasswordResetVerifyPage = () => {
    const [email, setEmail] = useState(null);
    const [verificationCode, setVerificationCode] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [verificationErrorMessage, setVerificationErrorMessage] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        setEmail(getEmailFromSession());
    }, []);

    const handleValidSubmit = async (event) => {
        event.preventDefault();

        setIsLoading(true);

        const apiUrl = `${BACKEND_DOMAIN}/admin/merchants/verify-otp`;

        try {
            const formData = new FormData();
            formData.append("email", email);
            formData.append("verification_code", verificationCode);

            await axios.post(apiUrl, formData);

            navigate("/route-money/merchant/reset-password");
        } catch (e) {
            console.error(e);
            setVerificationErrorMessage("Invalid code entered");
            setTimeout(() => {
                setVerificationErrorMessage(null);
            }, 3000);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div>
            <Container fluid className="p-0">
                <Row className="g-0">
                    <Col lg={4}>
                        <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                            <div className="w-100">
                                <Row className="justify-content-center">
                                    <Col lg={9}>
                                        <div>
                                            <div className="text-center">
                                                <div>
                                                    <Link to="/" className="">
                                                        <img
                                                            src={logodark}
                                                            alt=""
                                                            height="35"
                                                            className="auth-logo logo-light mx-auto"
                                                        />

                                                        <img
                                                            src={logolight}
                                                            alt=""
                                                            height="35"
                                                            className="auth-logo logo-dark mx-auto"
                                                        />
                                                    </Link>
                                                </div>

                                                <h4 className="font-size-18 mt-4">Reset Password</h4>
                                                <p className="text-muted">
                                                    Reset your password to access your account.
                                                </p>
                                            </div>

                                            <div className="p-2 mt-3">
                                                {verificationErrorMessage && (
                                                    <Alert color="danger" className="mb-4">
                                                        {verificationErrorMessage}
                                                    </Alert>
                                                )}
                                                <AvForm className="form-horizontal" onValidSubmit={handleValidSubmit}>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="email">
                                                            Verification Code <span className="text-danger">*</span>
                                                        </Label>
                                                        <AvField
                                                            name="verificationCode"
                                                            value={verificationCode}
                                                            onChange={(e) => setVerificationCode(e.target.value)}
                                                            type="text"
                                                            className="form-control"
                                                            id="verification_code"
                                                            placeholder="Enter Code"
                                                            validate={{ required: true }}
                                                        />
                                                    </div>

                                                    <div className="mt-4 text-center">
                                                        <Button
                                                            color="primary"
                                                            className="w-md waves-effect waves-light"
                                                            type="submit"
                                                            disabled={isLoading}
                                                        >
                                                            {isLoading ? (
                                                                <>
                                                                    <span
                                                                        className="spinner-border spinner-border-sm"
                                                                        role="status"
                                                                        aria-hidden="true"
                                                                    ></span>
                                                                    <span className="ms-2">Verifying..</span>
                                                                </>
                                                            ) : (
                                                                "Verify Code"
                                                            )}
                                                        </Button>
                                                    </div>
                                                </AvForm>
                                            </div>

                                            <div className="mt-3 text-center">
                                                <p>
                                                    Back to{" "}
                                                    <Link
                                                        to="/route-money/merchant/login"
                                                        className="fw-medium text-primary"
                                                    >
                                                        {" "}
                                                        Log in{" "}
                                                    </Link>{" "}
                                                </p>
                                                <p className="mt-5">© {new Date().getFullYear()} Route Money.</p>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                    <Col lg={8}>
                        <div className="authentication-bg"></div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

const mapStateToProps = (state) => ({
    message: state.Forget.message,
    forgetError: state.Forget.forgetError,
    loading: state.Forget.loading,
});

export default withRouter(connect(mapStateToProps, { forgetUser })(PasswordResetVerifyPage));
