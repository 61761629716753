// AppRoute.js
import React from "react";
import { Navigate } from "react-router-dom";

const AppRoute = ({ component: Component, layout: Layout, isAuthProtected, ...rest }) =>
    isAuthProtected && !localStorage.getItem("authUser") ? (
        <Navigate to="/route-money/merchant/login" replace state={{ from: rest.location }} />
    ) : (
        <Layout>
            <Component {...rest} />
        </Layout>
    );

export default AppRoute;
