// App.js
import React, { Component } from "react";
import { firebaseConfig } from "./firebase";
import "firebase/messaging";
import firebase from "firebase/compat/app";
import { Routes, Route } from "react-router-dom";
import { connect } from "react-redux";

import { authProtectedRoutes, publicRoutes } from "./routes/";
import AppRoute from "./routes/route";
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";
import Login from "./pages/Authentication/Login";

class App extends Component {
    constructor(props) {
        super(props);
        this.getLayout = this.getLayout.bind(this);
    }

    getLayout = () => {
        let layoutCls = VerticalLayout;

        switch (this.props.layout.layoutType) {
            case "horizontal":
                layoutCls = HorizontalLayout;
                break;
            default:
                layoutCls = VerticalLayout;
                break;
        }
        return layoutCls;
    };

    render() {
        const Layout = this.getLayout();

        return (
            <Routes>
                {/* Route for the login page */}
                <Route path="/route-money/merchant/login" element={<Login />} />

                {/* Public routes */}
                {publicRoutes.map((route, idx) => (
                    <Route
                        key={idx}
                        path={route.path}
                        element={
                            <AppRoute component={route.component} layout={NonAuthLayout} isAuthProtected={false} />
                        }
                    />
                ))}

                {/* Auth-protected routes */}
                {authProtectedRoutes.map((route, idx) => (
                    <Route
                        key={idx}
                        path={route.path}
                        element={<AppRoute component={route.component} layout={Layout} isAuthProtected={true} />}
                    />
                ))}
            </Routes>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        layout: state.Layout,
    };
};

if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

export default connect(mapStateToProps, null)(App);
