import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, CardBody, Label, Button, Alert } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { uploadSubCategoryRequest, uploadSubCategorySuccess, uploadSubCategoryFailure } from "../../thunks";
import BACKEND_DOMAIN from "../../../../service";

const MerchantSubCategoryEdit = () => {
    const { subcategoryId } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [subcategory, setSubCategory] = useState({
        name: "",
        image: null,
        category_id: "",
    });

    const [categories, setCategories] = useState([]);
    const [successMessage, setSuccessMessage] = useState("");
    const [selectedCategory, setSelectedCategory] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const token = useSelector((state) => state.Login.token);

    useEffect(() => {
        if (token && subcategoryId) {
            const apiUrl = `${BACKEND_DOMAIN}/business/merchant/subcategory/${subcategoryId}`;
            fetch(apiUrl, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then((response) => response.json())
                .then((data) => {
                    const { name, category_id } = data.data;
                    setSubCategory({
                        name: name,
                        image: null,
                        category_id: category_id,
                    });
                })
                .catch((error) => console.error("Error fetching subcategory details:", error));
        }
    }, [token, subcategoryId]);

    // Fetch categories on component mount
    useEffect(() => {
        if (token) {
            // Categories API URL
            const apiUrlCategories = `${BACKEND_DOMAIN}/business/merchant/categories`;

            // Fetch categories from the API
            fetch(apiUrlCategories, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then((response) => response.json())
                .then((data) => setCategories(data.categories))
                .catch((error) => console.error("Error fetching categories:", error));
        }
    }, [token]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        const categoryId = name === "category_id" ? value : subcategory.category_id;

        setSubCategory((prevSubCategory) => ({
            ...prevSubCategory,
            [name]: value,
            image: name === "image" ? e.target.files[0] : prevSubCategory.image,
        }));

        setSelectedCategory(categoryId);
    };

    const handleFormSubmit = async (event) => {
        event.preventDefault();

        setIsLoading(true);

        try {
            dispatch(uploadSubCategoryRequest());

            const formData = new FormData();
            formData.append("name", subcategory.name);
            formData.append("category_id", subcategory.category_id);
            formData.append("image", subcategory.image);

            const apiurl = `${BACKEND_DOMAIN}/business/merchant/subcategory/${subcategoryId}/update/`;
            const response = await fetch(apiurl, {
                method: "PUT",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: formData,
            });

            if (!response.ok) {
                throw new Error("Error editing subcategory");
            }

            const data = await response.json();
            dispatch(uploadSubCategorySuccess(data));

            setSuccessMessage("Sub-Category has been updated Successfully!");

            setTimeout(() => {
                navigate("/route-money/merchant/product/sub-categories");
            }, 2000);

            setTimeout(() => {
                setSuccessMessage("");
            }, 5000);
        } catch (error) {
            dispatch(uploadSubCategoryFailure(error.message));
        }

        setIsLoading(false);
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        title="Edit Sub-Category"
                        breadcrumbItems={[
                            { title: "Sub-Category", link: "/sub-category" },
                            { title: "Edit Sub-Category", link: "#" },
                        ]}
                    />

                    <Row>
                        <Col xl="12">
                            <Card>
                                <CardBody>
                                    <h4 className="card-title">Edit Sub-Category</h4>
                                    {successMessage && <Alert color="success">{successMessage}</Alert>}
                                    <AvForm onValidSubmit={handleFormSubmit} enctype="multipart/form-data">
                                        <Row>
                                            <Col md="6">
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="subcategoryName">
                                                        Sub-Category Name
                                                    </Label>
                                                    <AvField
                                                        name="name"
                                                        id="subcategoryName"
                                                        type="text"
                                                        placeholder="Enter SubCategory Name"
                                                        errorMessage="Enter SubCategory Name"
                                                        className="form-control"
                                                        onChange={handleInputChange}
                                                        validate={{ required: { value: true } }}
                                                        value={subcategory.name}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="6">
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="subcategoryImage">
                                                        Sub-Category Image
                                                    </Label>
                                                    <AvField
                                                        name="image"
                                                        id="subcategoryImage"
                                                        type="file"
                                                        errorMessage="Select Sub-Category Image"
                                                        className="form-control"
                                                        onChange={handleInputChange}
                                                        validate={{ required: { value: true } }}
                                                        //value={product.image}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md="6">
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="subcategoryCategory">
                                                        Category
                                                    </Label>
                                                    <AvField
                                                        type="select"
                                                        name="category_id"
                                                        id="subcategoryCategory"
                                                        onChange={handleInputChange}
                                                        validate={{ required: { value: true } }}
                                                        value={subcategory.category_id}
                                                    >
                                                        <option value="">Select Category</option>
                                                        {categories.map((category) => {
                                                            return (
                                                                <option key={category.id} value={category.id}>
                                                                    {category.name}
                                                                </option>
                                                            );
                                                        })}
                                                    </AvField>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Button color="primary" type="submit" disabled={isLoading}>
                                            {isLoading ? (
                                                <>
                                                    <span
                                                        className="spinner-border spinner-border-sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    ></span>
                                                    <span className="ms-2">Updating..</span>
                                                </>
                                            ) : (
                                                "Update Sub-Category"
                                            )}
                                        </Button>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default MerchantSubCategoryEdit;
