import axios from "axios";
import BACKEND_DOMAIN from "../../../service";

// API Endpoints:
const dashboardDataAPI = `${BACKEND_DOMAIN}/business/dashboard`;
const productsAPI = `${BACKEND_DOMAIN}/business/merchant/products/list`;
const productCategoriesAPI = `${BACKEND_DOMAIN}/business/merchant/categories`;
const productOffersAPI = `${BACKEND_DOMAIN}/business/offers`;
const cashbackOffersAPI = `${BACKEND_DOMAIN}/business/merchant/cashback/list`;
const ordersAPI = `${BACKEND_DOMAIN}/business/orders/list`;
const walletBalanceAPI = `${BACKEND_DOMAIN}/wallets/merchant/balance`;
const adminDashboardApi = `${BACKEND_DOMAIN}/business/admin/dashboard`

const updatedAdminApi=`https://backoffice.route.money/api/v1/business/admin/dashboard`;


// admin dashboard
 // Declare gvms outside the function

export const fetchUpdatedgvm = async (token) => {
    try {
      const response = await axios.get(updatedAdminApi, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      return response.data.cards_data;
    } catch (e) {
      console.error("Failed to fetch GMV -> ", e);
      throw e; // Re-throw error to handle it where called
    }
  };


export const fetchMonthlyRevenue = async (token) => {
    try {
        const response = await axios.get(adminDashboardApi, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });
        return response.data.monthly_revenue;
    } catch (e) {
        console.error("Failed to fetch Products, Orders and Branches data -> ", e);
    }
};

export const fetchTotalUsers = async (token) => {
    try {
        const response = await axios.get(adminDashboardApi, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });
        return response.data.total_users;
    } catch (e) {
        console.error("Failed to fetch Products, Orders and Branches data -> ", e);
    }
};

export const fetchTotalMerchants = async (token) => {
    try {
        const response = await axios.get(adminDashboardApi, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });
        return response.data.total_merchants;
    } catch (e) {
        console.error("Failed to fetch Products, Orders and Branches data -> ", e);
    }
};

export const fetchTotalARPU = async (token) => {
    try {
        const response = await axios.get(adminDashboardApi, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });
        return response.data.arpu;
    } catch (e) {
        console.error("Failed to fetch Products, Orders and Branches data -> ", e);
    }
};

export const fetchAllMerchants= async (token) => {
    try {
        const response = await axios.get(adminDashboardApi, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });
        return response.data.total_merchants;
    } catch (e) {
        console.error("Failed to fetch Products, Orders and Branches data -> ", e);
    }
};

export const fetchBranchCount= async (token) => {
    try {
        const response = await axios.get(adminDashboardApi, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });
        return response.data.branches_count;
    } catch (e) {
        console.error("Failed to fetch Products, Orders and Branches data -> ", e);
    }
};

export const fetchOrderCount= async (token) => {
    try {
        const response = await axios.get(adminDashboardApi, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });
        return response.data.order_count;
    } catch (e) {
        console.error("Failed to fetch Products, Orders and Branches data -> ", e);
    }
};
export const fetchChurnRate = async (token) => {
    try {
        const response = await axios.get(adminDashboardApi, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });
        return response.data.churn_rate;
    } catch (e) {
        console.error("Failed to fetch Products, Orders and Branches data -> ", e);
    }
};

export const fetchProductsOrdersAndBranches = async (token) => {
    try {
        const response = await axios.get(dashboardDataAPI, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });
        return response.data;
    } catch (e) {
        console.error("Failed to fetch Products, Orders and Branches data -> ", e);
    }
};

export const fetchWalletBalanceData = async (token) => {
    try {
        const response = await axios.get(walletBalanceAPI, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });
        return response.data.data.wallet; // Ensure correct access to nested data
    } catch (e) {
        console.error("Failed to fetch wallet balance -> ", e);
        throw e; // Throw error to indicate failure
    }
};

export const fetchProducts = async (token) => {
    try {
        const response = await axios.get(productsAPI, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });
        return response.data.results;
    } catch (e) {
        console.error("Failed to fetch products -> ", e);
    }
};

export const fetchProductOffersData = async (token) => {
    try {
        const response = await axios.get(productOffersAPI, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });
        const productOffers = response.data;
        return productOffers.length;
    } catch (e) {
        console.error("Failed to fetch product offers -> ", e);
    }
};

export const fetchCashbackOffersData = async (token) => {
    try {
        const response = await axios.get(cashbackOffersAPI, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });
        const cashbackOffers = response.data;
        return cashbackOffers.count;
    } catch (e) {
        console.error("Failed to fetch cashback offers -> ", e);
    }
};




export const fetchCategories = async (token) => {
    try {
        const response = await axios.get(productCategoriesAPI, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });

        const data = response.data;
        return data.categories;
    } catch (e) {
        console.error("Failed to fetch categories", e);
    }
};

export const recentOrders = async (token) => {
    try {
        const response = await axios.get(ordersAPI, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });

        return response.data;
    } catch (e) {
        console.error("Failed to fetch orders", e);
    }
};