import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, CardBody, Label, Button, Alert } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { orderProductRequest, orderProductSuccess, orderProductFailure } from "../../thunks";
import BACKEND_DOMAIN from "../../../../service";

const MerchantOrderAdd = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [order, setOrder] = useState({
        product_id: [],
        quantity: "",
        order_type: "",
    });

    const [products, setProducts] = useState([]);
    const [successMessage, setSuccessMessage] = useState("");

    const token = useSelector((state) => state.Login.token);

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (token) {
            const apiUrl = `${BACKEND_DOMAIN}/business/merchant/products/list`;
            fetch(apiUrl, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then((response) => response.json())
                .then((data) => setProducts(data.results))
                .catch((error) => console.error("Error fetching products:", error));
        }
    }, [token]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        const productId = e.target.options ? e.target.options[e.target.selectedIndex].value : null;

        setOrder({
            ...order,
            [name]: name === "product_id" ? [...order.product_id, productId] : value,
        });
    };

    const handleFormSubmit = async (event) => {
        event.preventDefault();

        setIsLoading(true);

        try {
            dispatch(orderProductRequest());

            const orderProducts = [];

            for (const productId of order.product_id) {
                const selectedProduct = products.find((product) => product.id === parseInt(productId, 10));

                if (!selectedProduct) {
                    throw new Error(`Product with ID ${productId} not found`);
                }

                orderProducts.push({
                    product: selectedProduct.id,
                    quantity: parseInt(order.quantity, 10),
                });
            }

            const total_amount = orderProducts.reduce((sum, product) => {
                const productPrice = parseFloat(products.find((p) => p.id === product.product).price);
                return sum + productPrice * product.quantity;
            }, 0);

            const orderData = {
                products: orderProducts,
                total_amount: total_amount,
                order_type: order.order_type,
            };

            const apiurl = `${BACKEND_DOMAIN}/business/order/create`;
            const response = await fetch(apiurl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(orderData),
            });

            if (!response.ok) {
                throw new Error("Error creating Order");
            }

            const data = await response.json();
            dispatch(orderProductSuccess(data));

            // Show a success message:
            setSuccessMessage("Order has been added successfully!");

            setTimeout(() => {
                navigate("/route-money/merchant/pending-orders");
            }, 2000);

            setTimeout(() => {
                setSuccessMessage("");
            }, 5000);
        } catch (error) {
            dispatch(orderProductFailure(error.message));
        }

        setIsLoading(false);
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        title="Create Order"
                        breadcrumbItems={[
                            { title: "Orders", link: "/orders" },
                            { title: "Create Order", link: "#" },
                        ]}
                    />

                    <Row>
                        <Col xl="12">
                            <Card>
                                <CardBody>
                                    <h4 className="card-title">Create Order</h4>
                                    {successMessage && <Alert color="success">{successMessage}</Alert>}
                                    <AvForm onValidSubmit={handleFormSubmit} enctype="multipart/form-data">
                                        <Row>
                                            <Col md="6">
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="product">
                                                        Select Product
                                                    </Label>
                                                    <AvField
                                                        type="select"
                                                        name="product_id"
                                                        id="product"
                                                        onChange={handleInputChange}
                                                        validate={{ required: { value: true } }}
                                                    >
                                                        <option value="">Select Product</option>
                                                        {products.map((product) => {
                                                            return (
                                                                <option key={product.id} value={product.id}>
                                                                    {product.name}
                                                                </option>
                                                            );
                                                        })}
                                                    </AvField>
                                                </div>
                                            </Col>
                                            <Col md="6">
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="productQuantity">
                                                        Quantity
                                                    </Label>
                                                    <AvField
                                                        name="quantity"
                                                        id="productQuantity"
                                                        type="text"
                                                        placeholder="Enter Quantity"
                                                        errorMessage="Enter Quantity"
                                                        className="form-control"
                                                        onChange={handleInputChange}
                                                        validate={{ required: { value: true } }}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="6">
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="order_type">
                                                        Order Type
                                                    </Label>
                                                    <AvField
                                                        type="select"
                                                        name="order_type"
                                                        id="order_type"
                                                        onChange={handleInputChange}
                                                        //validate={{ required: { value: true } }}
                                                    >
                                                        <option value="">Select Order Type</option>
                                                        <option value="Delivery">Delivery</option>
                                                        <option value="Dine In">Dine In</option>
                                                        <option value="Pickup">Pickup</option>
                                                        <option value="Book Table">Book Table</option>
                                                    </AvField>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Button color="primary" type="submit" disabled={isLoading}>
                                            {isLoading ? (
                                                <>
                                                    <span
                                                        className="spinner-border spinner-border-sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    ></span>
                                                    <span className="ms-2">Creating..</span>
                                                </>
                                            ) : (
                                                "Create Order"
                                            )}
                                        </Button>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default MerchantOrderAdd;
