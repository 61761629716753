import { normalizeOrderData } from './normalizeOrderData ';
import BACKEND_DOMAIN from "../../../../service";

export const fetchOrders = () => {
    const authData = JSON.parse(localStorage.getItem('authUser'));
    const role = authData?.role || '';
    const token = authData?.token || '';

    const apiUrl = 'https://backoffice.route.money/api/v1/business/merchant/orders';

    return fetch(apiUrl, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
    .then((response) => {
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
    })
    .then((data) => {
        return normalizeOrderData(data);
    })
    .catch((error) => {
        console.error("Error fetching orders:", error);
    });
};

export const fetchWaiterCards = () => {
    const authData = JSON.parse(localStorage.getItem('authUser'));
    const role = authData?.role || '';
    const token = authData?.token || '';

    const apiUrl = 'https://backoffice.route.money/api/v1/business/merchant/orders/card/stats';

    return fetch(apiUrl, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
    .then((response) => {
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
    }).catch((error) => {
        console.error("Error fetching orders:", error);
    });
};