import BACKEND_DOMAIN from "../../service";

const API_URL = `${BACKEND_DOMAIN}/business/order/create`;
export const orderProductRequest = () => ({
    type: "UPLOAD_PRODUCT_REQUEST", // Replace with your actual action type
});

export const orderProductSuccess = (data) => ({
    type: "UPLOAD_PRODUCT_SUCCESS", // Replace with your actual action type
    payload: data,
});

export const orderProductFailure = (error) => ({
    type: "UPLOAD_PRODUCT_FAILURE", // Replace with your actual action type
    payload: error,
});

export const orderProduct = (productData, token) => async (dispatch) => {
    try {
        dispatch(orderProductRequest());
        const formData = new FormData();
        formData.append("product_id", productData.product_id);
        formData.append("quantity", productData.quantity);
        formData.append("order_type", productData.order_type);
        const response = await fetch(API_URL, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: formData,
        });

        if (!response.ok) {
            throw new Error("Error creating Order");
        }

        const data = await response.json();
        dispatch(orderProductSuccess(data));
    } catch (error) {
        dispatch(orderProductSuccess(error.message));
    }
};
