import React, { useState } from "react";
import { Row, Col, Card, CardBody, TabContent, TabPane, Label, Input, Form, Container, Button,Alert } from "reactstrap";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";

// Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/Breadcrumb";

const Index = () => {
    const [breadcrumbItems] = useState([
        { title: "Tables", link: "#" },
        { title: "Add table", link: "#" },
    ]);
    const [activeTab, setActiveTab] = useState(1);
    const [progressValue, setProgressValue] = useState(25);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const token = useSelector((state) => state.Login.token);
    const [isSubmittingRegistration, setIsSubmittingRegistration] = useState(false);

    const [successMessage, setSuccessMessage] = useState("");
    const [unsuccessfulMessage, setUnsucccessfulMessage] = useState("");

    const [tabledata, setTableData] = useState({
        table_number: "",
        table_capacity: "",
        table_description: "",
        table_status:"available"
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setTableData((prevData) => ({
            ...prevData,
            [name]: name === "table_capacity" ? parseInt(value, 10) : value,
        }));
    };

    const handleFormSubmit = async (event) => {
        event.preventDefault();

        setIsSubmittingRegistration(true);

        try {
            const apiurl = `https://backoffice.route.money/api/v1/business/merchant/tables`;
            const response = await fetch(apiurl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({
                    name: tabledata.table_number,
                    description:tabledata.table_description,
                    capacity:tabledata.table_capacity,
                    status: "available"
                }),
            });

            const data = await response.json();

            if (!response.ok) {
               // console.log(tabledata.table_number,tabledata.table_capacity,tabledata.table_description,tabledata.status);
              //  console.log('Table not created:', data);
                setUnsucccessfulMessage('Transaction failed,', data.errors);
                throw new Error(data.message || "Transaction unsuccessful, please try again");
             
            } else {
                setSuccessMessage(`Table Added successfully!`);

                setIsSubmittingRegistration(true);

                setTimeout(() => {
                    setSuccessMessage("");
                    setTableData({
                        table_number: "",
                        table_capacity: "",
                        table_description: "",
                        table_status: "available"
                      });
                    }, 2000);
                
            }
            
            setTimeout(() => {
                setSuccessMessage("");

                setIsSubmittingRegistration(false);

                setTableData({
                    table_number: "",
                    table_capacity: "",
                    table_description: "",
                    table_status: "available"
                  });
                }, 2000);
        } catch (error) {
            setUnsucccessfulMessage('Error occured ',error.error);
            setIsSubmittingRegistration(false);
        }

        setIsLoading(false);
    };

    const toggleTab = (tab) => {
        if (activeTab !== tab) {
            if (tab >= 1 && tab <= 4) {
                setActiveTab(tab);
            }
        }
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Add table" breadcrumbItems={breadcrumbItems} />

                    <Row>
                    {successMessage && <Alert color="success">{successMessage}</Alert>}
                    {unsuccessfulMessage && <Alert color="danger">{unsuccessfulMessage}</Alert>}
                         
                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    <div id="basic-pills-wizard" className="twitter-bs-wizard">
                                        <TabContent
                                            activeTab={activeTab}
                                            className="twitter-bs-wizard-tab-content"
                                        >
                                            <TabPane tabId={1}>
                                                <Form onSubmit={handleFormSubmit}>
                                                    <Row>
                                                        <Col lg="6">
                                                            <div className="mb-3">
                                                                <Label
                                                                    className="form-label"
                                                                    htmlFor="basicpill-firstname-input1"
                                                                >
                                                                    Table Number
                                                                </Label>
                                                                <Input
                                                                    type="text"
                                                                    name="table_number"
                                                                    value={tabledata.table_number}
                                                                    onChange={handleInputChange}
                                                                    className="form-control"
                                                                    id="table_number"
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col lg="6">
                                                            <div className="mb-3">
                                                                <Label
                                                                    className="form-label"
                                                                    htmlFor="basicpill-lastname-input2"
                                                                >
                                                                    Table Capacity
                                                                </Label>
                                                                <Input
                                                                    type="text"
                                                                    name="table_capacity"
                                                                    value={tabledata.table_capacity}
                                                                    onChange={handleInputChange}
                                                                    className="form-control"
                                                                    id="table_capacity"
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col lg="12">
                                                            <div className="mb-3">
                                                                <Label
                                                                    className="form-label"
                                                                    htmlFor="basicpill-address-input1"
                                                                >
                                                                    Table Description
                                                                </Label>
                                                                <textarea
                                                                    id="table_description"
                                                                    name="table_description"
                                                                    value={tabledata.table_description}
                                                                    onChange={handleInputChange}
                                                                    className="form-control"
                                                                    rows="2"
                                                                ></textarea>
                                                            </div>
                                                        </Col>
                                                    </Row>

                                                    <div className="text-end">
                                                    <Button
                                                                type="submit"
                                                                style={{
                                                                    backgroundColor: "#5664d2",
                                                                }}
                                                                disabled={isSubmittingRegistration}
                                                            >
                                                                {isSubmittingRegistration ? (
                                                                    <>
                                                                        <span
                                                                            className="spinner-border spinner-border-sm"
                                                                            role="status"
                                                                            aria-hidden="true"
                                                                        ></span>
                                                                        <span className="ms-2">Creating Table..</span>
                                                                    </>
                                                                ) : (
                                                                    "Add table"
                                                                )}
                                                            </Button>
                                                        {/* <Button type="submit" color="primary" onClick={handleFormSubmit}>
                                                            Submit
                                                        </Button> */}
                                                    </div>
                                                </Form>
                                            </TabPane>
                                        </TabContent>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Index;
