import React, { useMemo, useState, useEffect } from "react";
import TableContainer from "../../../../components/Common/TableContainer";
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import {
    Dropdown,
    DropdownMenu,
    DropdownToggle,
    DropdownItem,
    Card,
    CardBody,
    Container,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Spinner
} from "reactstrap";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import dateFormatter from "../../utilities/dateFormatter";
import BACKEND_DOMAIN from "../../../../service";
import { ORDER_TYPE_COLORS } from "../../utilities/orderTypes";

const MerchantPendingOrders = () => {
    const token = useSelector((state) => state.Login.token);
    const [loading, setLoading] = useState(true)
    const [orders, setOrders] = useState([]);
    const [orderListIsEmpty, setOrderListIsEmpty] = useState(false);
    const [menuOpen, setMenuOpen] = useState({});
    const [modal, setModal] = useState(false);
    const [selectedOrderDetails, setSelectedOrderDetails] = useState(null);
    const [selectedOrderWaiters, setSelectedOrderWaiters] = useState(null)

    const [tooltipOpen, setTooltipOpen] = useState(false);

    // Order Badge color for the modal:
    const [orderBadgeColor, setOrderBadgeColor] = useState("success");

    const toggleTooltip = () => {
        setTooltipOpen(!tooltipOpen);
    };

    const toggleModal = () => {
        setModal(!modal);
    };
    const openModal = (orderId) => {
        const selectedOrder = orders.find((order) => order.id === orderId);

        setSelectedOrderDetails(selectedOrder);
        setSelectedOrderWaiters(selectedOrder);
        setOrderBadgeColor(ORDER_TYPE_COLORS[selectedOrder.order_type]);
        toggleModal();
    };

   
    const handleAcceptReject = async (orderId, newStatus) => {

        try {
            const apiUrl = `${BACKEND_DOMAIN}/business/order/update-status/${orderId}/`;
            const response = await fetch(apiUrl, {
                method: "PATCH",
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    status: newStatus,
                }),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            
            // Assuming your API response includes the updated order details, update the state accordingly
            const updatedOrder = await response.json();
            setOrders((prevOrders) =>
                prevOrders.map((order) =>
                    order.id === updatedOrder.id ? { ...order, status: updatedOrder.status } : order
                )
            );

            setMenuOpen((prevMenuOpen) => ({
                ...prevMenuOpen,
                [orderId]: false,
            }));

            // Fetch orders again to update the orders list
            fetchOrders();
        } catch (error) {
            console.error("Error updating order status:", error);
        } finally {
            if (modal) {
                setModal(false);
            }
        }
    };

    // const fetchOrders = (isInitial= true) => {
    //     const apiUrl = `${BACKEND_DOMAIN}/business/orders/merchant/list`;

    //     fetch(apiUrl, {
    //         headers: {
    //             Authorization: `Bearer ${token}`,
    //         },
    //     })
    //         .then((response) => {
    //             if (!response.ok) {
    //                 throw new Error(`HTTP error! Status: ${response.status}`);
    //             }
    //             return response.json();
    //         })
    //         .then((data) => {
    //             const pending = data.orders.filter((order) => order.status === "pending");
    //             if (pending.length === 0) {
    //                 setOrderListIsEmpty(true);
    //             }
    //             setOrders(pending.reverse());
    //         })
    //         .catch((error) => {
    //             console.error("Error fetching orders:", error);
    //         });
    // };

    // Call fetchOrders on initial render
    
    const fetchOrders = async (isInitial = true) => {
        const apiUrl = `${BACKEND_DOMAIN}/business/orders/merchant/list`;

        try {
            if (isInitial) setLoading(true); 
            const response = await fetch(apiUrl, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();
            const pending = data.orders.filter((order) => order.status === "pending");
            if (pending.length === 0) {
                setOrderListIsEmpty(true);
            } else {
                setOrderListIsEmpty(false);
            }
            setOrders(pending.reverse());
        } catch (error) {
            console.error("Error fetching orders:", error);
        } finally {
            if (isInitial) setLoading(false); 
        }
    };

    // useEffect(() => {
    //     fetchOrders();
    // }, [token]);

    useEffect(() => {
        if (token) {
            fetchOrders(true); // Initial fetch with loading indicator

            const intervalId = setInterval(() => {
                fetchOrders(false); // Subsequent fetches without loading indicator
            }, 30000); // Poll every 10 seconds

            // Cleanup interval on component unmount
            return () => clearInterval(intervalId);
        }
    }, [token]);


  // console.log("table waiters ",selectedOrderWaiters.table_users)

    const columns = useMemo(
        () => [
            {
                Header: "OrderID",
                accessor: "order_number",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Customer",
                accessor: "customer_name",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Amount",
                accessor: "total_order_amount",
                Cell: ({ cell: { value } }) => {
                    return `KES ${value}`;
                },
                disableFilters: true,
                filterable: false,
            },

            {
                Header: "Order Type",
                accessor: "order_type",
                Cell: ({ cell: { value, row } }) => {
                    let typeIcon;
                    let tooltipContent;

                    if (value === "DELIVERY" || "Delivery") {
                        typeIcon = <i className="mdi mdi-moped"></i>;
                        tooltipContent = row.original.order_type;
                    } else if (value === "RESERVE" || "Book Table") {
                        typeIcon = <i className="mdi mdi-calendar-clock-outline"></i>;
                        tooltipContent = row.original.order_type;
                    } else if (value === "PICK-UP" || "pickup") {
                        typeIcon = <i className="mdi mdi-car-back"></i>;
                        tooltipContent = row.original.order_type;
                    } else if (value === "IN-STORE" || "Dine In") {
                        typeIcon = <i className="mdi mdi-silverware-fork-knife"></i>;
                        tooltipContent = row.original.order_type;
                    }
                    return (
                        <React.Fragment>
                            <div>{tooltipContent}</div>
                        </React.Fragment>
                    );
                },
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Date",
                accessor: "created_at",
                Cell: ({ cell: { value } }) => {
                    const formattedDate = dateFormatter(value);
                    return formattedDate;
                },
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Status",
                accessor: "payment_status",
                disableFilters: true,
                filterable: false,
                Cell: ({ cell: { value } }) => {
                    let statusBadge;
                    if (value === "PAID") {
                        statusBadge = <span className="badge bg-success-subtle text-success text-uppercase">PAID</span>;
                    } else if (value === "UNPAID") {
                        statusBadge = <span className="badge bg-danger-subtle text-danger text-uppercase">UNPAID</span>;
                    }
                    return <React.Fragment>{statusBadge}</React.Fragment>;
                },
            },
            {
                Header: "Stage",
                accessor: "status",
                Cell: ({ cell: { value } }) => {
                    let statusBadge;
                    statusBadge = <span className="badge bg-warning-subtle text-warning text-uppercase">Pending</span>;

                    return <React.Fragment>{statusBadge}</React.Fragment>;
                },
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Action",
                accessor: (cellProps) => {
                    const orderId = cellProps.id ? cellProps.id : null;

                    return (
                        <React.Fragment>
                            <div className="d-flex align-items-center">
                                <Link to="#" className="me-3 text-primary" onClick={() => openModal(orderId)}>
                                    <i className="mdi mdi-eye font-size-18"></i>
                                </Link>
                                <Dropdown isOpen={menuOpen[orderId]} toggle={() => handleToggleDropdown(orderId)}>
                                    <DropdownToggle tag="i" className="arrow-none card-drop">
                                        <i className="mdi mdi-dots-vertical"></i>
                                    </DropdownToggle>
                                    <DropdownMenu style={{ zIndex: 1000 }} className="dropdown-menu-end">
                                        <DropdownItem
                                            onClick={() => handleAcceptReject(orderId, "complete")}
                                            disabled={
                                                cellProps.status === "complete" || cellProps.status === "rejected"
                                            }
                                        >
                                            Accept
                                        </DropdownItem>
                                        <DropdownItem
                                            onClick={() => handleAcceptReject(orderId, "rejected")}
                                            disabled={
                                                cellProps.status === "rejected" || cellProps.status === "complete"
                                            }
                                        >
                                            Reject
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </div>
                        </React.Fragment>
                    );
                },
                disableFilters: true,
                filterable: false,
            },
        ],
        [menuOpen, orders]
    );

    const handleToggleDropdown = (orderId) => {
        setMenuOpen((prevMenuOpen) => {
            return { ...prevMenuOpen, [orderId]: !prevMenuOpen[orderId] };
        });
    };

    const breadcrumbItems = [
        { title: "Incomplete Orders", link: "#" },
        { title: "Pending Orders", link: "#" },
    ];

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Pending Orders" breadcrumbItems={breadcrumbItems} />

                    <Card>
                        <CardBody>
                            <TableContainer
                                columns={columns || []}
                                data={orders || []}
                                isPagination={false}
                                iscustomPageSize={false}
                                isGlobalFilter={true}
                                isBordered={false}
                                customPageSize={10}
                                isEmpty={orderListIsEmpty}
                                isEmptyContent="There are currently no pending orders."
                            />
                        </CardBody>
                    </Card>
                </Container>
            </div>
            <Modal
                isOpen={modal}
                role="document"
                autoFocus={true}
                centered={true}
                size="lg"
                className="composemodal"
                tabIndex="-1"
                toggle={toggleModal}
            >
                <div className="modal-content">
                    <ModalHeader className="position-relative">
                        <span className="">ORDER SUMMARY</span>
                        <span
                            style={{ position: "absolute", right: 0 }}
                            className={`badge bg-${orderBadgeColor}-subtle text-${orderBadgeColor} text-uppercase p-2 me-3`}
                        >
                            {selectedOrderDetails?.order_type ? selectedOrderDetails.order_type : "None"}
                        </span>
                    </ModalHeader>

                    <ModalBody style={{ maxHeight: "800px", overflowY: "auto" }}>
    {selectedOrderDetails && (
        <div>
            <div className="d-flex flex-column flex-sm-row justify-content-between">
                <div>
                    <h6 className="me-2 mb-3" style={{ fontSize: "14px" }}>
                        CUSTOMER DETAILS
                    </h6>

                    <div className="d-flex align-items-center">
                        <h6 style={{ fontSize: "12px" }}>Name :</h6>
                        <p className="ms-2 mt-2">{selectedOrderDetails.customer_name}</p>
                    </div>

                    <div className="d-flex align-items-center">
                        <h6 style={{ fontSize: "12px" }}>Email :</h6>
                        <p className="ms-2 mt-2">{selectedOrderDetails.customer_email}</p>
                    </div>

                    <div className="d-flex align-items-center">
                        <h6 style={{ fontSize: "12px" }}>Phone NO :</h6>
                        <p className="ms-2 mt-2">{selectedOrderDetails.phone_number}</p>
                    </div>

                    {/* {selectedOrderDetails.order_type === "Delivery" || selectedOrderDetails.order_type === "DELIVERY" && (
                       <div className="d-flex align-items-center">
                            <h6 style={{ fontSize: "12px" }}>Address :</h6>
                            <p className="ms-2 mt-2">{selectedOrderDetails.address}</p>
                        </div>
                    )} */}

                    {(selectedOrderDetails.order_type === "Delivery" || selectedOrderDetails.order_type === "DELIVERY") && (
                        <div className="d-flex align-items-center">
                            <h6 style={{ fontSize: "12px" }}>Address :</h6>
                            <p className="ms-2 mt-2">{selectedOrderDetails.address}</p>
                        </div>
                    )}


                    {selectedOrderDetails.order_type === "IN-STORE" && (
                        <div className="d-flex align-items-center">
                            <h6 style={{ fontSize: "12px" }}>Table Number :</h6>
                            <p className="ms-2 mt-2 p-3">{selectedOrderDetails.table_name}</p>
                        </div>
                    )}

                    {(selectedOrderDetails.order_type === "Book Table" || selectedOrderDetails.order_type === "RESERVE") && (
                        <div className="d-flex align-items-center">
                            <h6 style={{ fontSize: "12px" }}>Table Number :</h6>
                            <p className="ms-2 mt-2 p-3">{selectedOrderDetails.table_name}</p>
                            <h6 style={{ fontSize: "12px" }}>Reservation Time :</h6>
                            <p className="ms-2 mt-2 p-3">{selectedOrderDetails.time}</p>
                            <h6 style={{ fontSize: "12px" }}>Number of Guests :</h6>
                            <p className="ms-2 mt-2 p-3">{selectedOrderDetails.number_of_guests}</p>
                        </div>
                    )}

                    {(selectedOrderDetails.order_type === "Pickup" || selectedOrderDetails.order_type === "PICK-UP") && (
                        <div className="d-flex align-items-center">
                            <h6 style={{ fontSize: "12px" }}>Pickup Time :</h6>
                            <p className="ms-2 mt-2">{selectedOrderDetails.time}</p>
                        </div>
                    )}
                </div>

                <div className="mt-3 mt-sm-0">
                    <h6 className="me-2 mb-3" style={{ fontSize: "14px" }}>
                        FULFILLMENT DETAILS
                    </h6>

                    <div className="d-flex align-items-center">
                        <h6 style={{ fontSize: "12px" }}>Order NO :</h6>
                        <p className="ms-2 mt-2">{selectedOrderDetails.order_number}</p>
                    </div>

                    <div className="d-flex align-items-center">
                        <h6 style={{ fontSize: "12px" }}>Placed On :</h6>
                        <p className="ms-2 mt-2">{dateFormatter(selectedOrderDetails.created_at)}</p>
                    </div>

                    <div className="d-flex align-items-center">
                        <h6 style={{ fontSize: "12px" }}>Branch Name :</h6>
                        <p className="ms-2 mt-2">{selectedOrderDetails.branch}</p>
                    </div>
                </div>
            </div>

            {/* Separation Line */}
            <hr style={{ border: "1px solid #ddd", margin: "10px 0" }} />

            {/* Order Details Section */}
            <div>
                <h6 className="me-2 mb-3" style={{ fontSize: "14px" }}>
                    PRODUCT DETAILS
                </h6>
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th>Image</th>
                            <th>Name</th>
                            <th>Quantity</th>
                            <th>Price</th>
                        </tr>
                    </thead>
                    <tbody>
                        {selectedOrderDetails.order_items.map((item, index) => (
                            <tr key={index}>
                                <td>
                                    <img
                                        src={item.product_details.image}
                                        alt={item.product_details.name}
                                        style={{
                                            width: "50px",
                                            height: "50px",
                                            borderRadius: 8,
                                            objectFit: "cover",
                                        }}
                                    />
                                </td>
                                <td>{item.product_details.name}</td>
                                <td>{item.quantity}</td>
                                {/* <td>Ksh {(item.product_details.offer_price * item.quantity).toFixed(2)}{" "}</td> */}
                                <td>
                                Ksh {(
                                    (item.product_details.offer_price > 0 
                                    ? item.product_details.offer_price 
                                    : item.product_details.price) 
                                    * item.quantity
                                ).toFixed(2)}
                                {" "}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan="2"></td>
                            <td>Total:</td>
                            {/* <td>
                                Ksh
                                {selectedOrderDetails.order_items
                                    .reduce((total, item) => {
                                        return total + item.product_details.price * item.quantity;
                                    }, 0)
                                    .toFixed(2)}{" "}
                            </td> */}
                            <td>
                            Ksh
                            {selectedOrderDetails.order_items
                                .reduce((total, item) => {
                                const priceToUse = item.product_details.offer_price > 0 
                                    ? item.product_details.offer_price 
                                    : item.product_details.price;
                                return total + priceToUse * item.quantity;
                                }, 0)
                                .toFixed(2)}{" "}
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>

            {/* New Section: Table Users (Waiters) */}
            <div className="mt-3">
                <h6 className="me-2 mb-3" style={{ fontSize: "14px" }}>
                    SERVED BY
                </h6>
              
                 
        <ul style={{ listStyleType: "none", padding: 0 }}>
        {selectedOrderDetails.table_users.map((user, index) => (
            <li key={index} style={{ marginBottom: "8px" }}>
                {user.first_name} - {user.phone_number}
            </li>
        ))}
      </ul>
            </div>
        </div>
    )}
</ModalBody>
                    <ModalFooter>
                     
                        <Button color="secondary" onClick={toggleModal}>
                            cancel
                        </Button>{" "}
                        <Button color="success" onClick={() => handleAcceptReject(selectedOrderDetails.id, "complete")}>
                            Accept
                        </Button>{" "}
                        <Button color="danger" onClick={() => handleAcceptReject(selectedOrderDetails.id, "rejected")}>
                            Reject
                        </Button>
                    </ModalFooter>
                </div>
            </Modal>

            <Modal isOpen={loading} centered>
                <ModalBody className="text-center">
                    <Spinner style={{ width: '3rem', height: '3rem' }} color="primary" />
                    <div>Loading...</div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
};

export default MerchantPendingOrders;
