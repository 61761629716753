export const normalizeOrderData = (data) => {
    return data.map(order => ({
        id: order.id,
        order_number: order.order_number,
        customer_name: order.customer_name,
        total_order_amount: order.total_order_amount,
        order_type: order.order_type,
        created_at: order.created_at,
        payment_status: order.payment_status,
        status: order.status,
        merchant: order.merchant || null,  // Include merchant if available
    }));
};
