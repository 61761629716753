import React, { Component } from "react";
import { FormGroup, Label, Input } from "reactstrap";

import { connect } from "react-redux";
import {
    hideRightSidebar,
    changeLayout,
    changeLayoutWidth,
    changeSidebarTheme,
    changeSidebarType,
    changePreloader,
    changeTopbarTheme,
    changeLayoutTheme,
} from "../../store/actions";

//SimpleBar
import SimpleBar from "simplebar-react";

import { Link } from "react-router-dom";

import "./rightbar.scss";

class RightSidebar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            layoutType: this.props.layoutType,
            sidebarType: this.props.leftSideBarType,
            layoutWidth: this.props.layoutWidth,
            sidebarTheme: this.props.leftSideBarTheme,
            topbarTheme: this.props.topbarTheme,
            theme: this.props.theme,
        };
        this.hideRightbar = this.hideRightbar.bind(this);
        this.changeLeftSidebarTheme = this.changeLeftSidebarTheme.bind(this);
        this.changeLayoutTheme = this.changeLayoutTheme.bind(this);
        this.changeThemePreloader = this.changeThemePreloader.bind(this);
    }

    /**
     * Hides the right sidebar
     */
    hideRightbar(e) {
        e.preventDefault();
        this.props.hideRightSidebar();
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.setState({
                layoutType: this.props.layoutType,
                sidebarType: this.props.leftSideBarType,
                layoutWidth: this.props.layoutWidth,
                sidebarTheme: this.props.leftSideBarTheme,
                topbarTheme: this.props.topbarTheme,
                theme: this.props.theme,
            });
        }
    }

    changeThemePreloader = () => {
        this.props.changePreloader(!this.props.isPreloader);
    };
    /**
     * Change the layout
     * @param {*} e
     */

    // change left sidebar theme
    changeLeftSidebarTheme(e) {
        if (e.target.checked) {
            this.props.changeSidebarTheme(e.target.value);
        }
    }

    // change theme Mode
    changeLayoutTheme(e) {
        if (e.target.checked) {
            this.props.changeLayoutTheme(e.target.value);
            this.props.changeSidebarTheme(e.target.value);
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="right-bar">
                    <SimpleBar style={{ height: "900px" }}>
                        <div data-simplebar className="h-100">
                            <div className="rightbar-title px-3 py-4">
                                <Link to="#" onClick={this.hideRightbar} className="right-bar-toggle float-end">
                                    <i className="mdi mdi-close noti-icon"></i>
                                </Link>
                                <h5 className="m-0">Settings</h5>
                            </div>

                            <hr className="my-0" />

                            <div className="p-4">
                                <div className="radio-toolbar">
                                    <span className="mb-2 d-block" id="radio-title">
                                        Theme
                                    </span>
                                    <Input
                                        type="radio"
                                        id="radioThemeLightMode"
                                        name="radioThemeMode"
                                        value="light"
                                        checked={this.state.theme === "light"}
                                        onClick={this.changeLayoutTheme}
                                        onChange={this.changeLayoutTheme}
                                    />
                                    <Label htmlFor="radioThemeLightMode">Light</Label>
                                    {"   "}
                                    <Input
                                        type="radio"
                                        id="radioThemeDarkMode"
                                        name="radioThemeMode"
                                        value="dark"
                                        checked={this.state.theme === "dark"}
                                        onClick={this.changeLayoutTheme}
                                        onChange={this.changeLayoutTheme}
                                    />
                                    <Label htmlFor="radioThemeDarkMode">Dark</Label>
                                    {"   "}
                                </div>
                            </div>
                        </div>
                    </SimpleBar>
                </div>
                <div className="rightbar-overlay"></div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = (state) => {
    return { ...state.Layout };
};

export default connect(mapStatetoProps, {
    hideRightSidebar,
    changeLayout,
    changeSidebarTheme,
    changeSidebarType,
    changeLayoutWidth,
    changeTopbarTheme,
    changeLayoutTheme,
    changePreloader,
})(RightSidebar);
