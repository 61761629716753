import React, { useMemo, useState, useEffect } from "react";
import TableContainer from "../../../../components/Common/TableContainer";

//Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import { Alert, Button, Card, CardBody, Container, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
//import { Breadcrumb, BreadcrumbItem, Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, FormGroup, Label, Input } from 'reactstrap';

import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import BACKEND_DOMAIN from "../../../../service";

const MerchantCashbackOffers = () => {
    const [products, setProducts] = useState([]);
    const [offers, setOffers] = useState([]);
    const [isCashbackOfferListEmpty, setIsCashbackOfferListEmpty] = useState(false);

    const authToken = useSelector((state) => state.Login.token); // Retrieve token from Redux store

    const [deleteModal, setDeleteModal] = useState(false);
    const [cashbackOfferIdToDelete, setCashbackOfferIdToDelete] = useState(null);
    const [deleteSuccessMessage, setDeleteSuccessMessage] = useState("");

    const toggleDeleteModal = () => setDeleteModal(!deleteModal);

    const deleteCashbackOffer = (id) => {
        setCashbackOfferIdToDelete(id);
        toggleDeleteModal();
    };

    const confirmDelete = () => {
        if (cashbackOfferIdToDelete) {
            const cashbackOfferDeletionAPIUrl = `${BACKEND_DOMAIN}/business/merchant/cashback/delete/${cashbackOfferIdToDelete}`;
            fetch(cashbackOfferDeletionAPIUrl, {
                method: "DELETE",
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    "Content-Type": "application/json",
                },
            })
                .then((res) => {
                    if (res.ok) {
                        const updatedCashbackOffers = offers.filter((offer) => offer.id !== cashbackOfferIdToDelete);
                        setOffers(updatedCashbackOffers);
                        setDeleteSuccessMessage("Cashback offer deleted successfully!");
                        setTimeout(() => {
                            setDeleteSuccessMessage("");
                        }, 3000);
                    } else {
                        console.error("Failed to Delete cashback offer: ", res.status);
                    }
                })
                .catch((error) => console.error("Error deleteing cashback offer: ", error))
                .finally(() => {
                    setCashbackOfferIdToDelete(null);
                    toggleDeleteModal();
                });
        }
    };

    useEffect(() => {
        const apiUrlProducts = `${BACKEND_DOMAIN}/business/merchant/products/list`;
        const apiUrlOffers = `${BACKEND_DOMAIN}/business/merchant/cashback/list`;
        const fetchData = () => {
            fetch(apiUrlProducts, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${authToken}`, // Include the token in the headers
                    "Content-Type": "application/json",
                    // Add other headers if needed
                },
            })
                .then((response) => response.json())
                .then((data) => setProducts(data.results))
                .catch((error) => console.error("Error fetching products:", error));

            // Fetch categories
            fetch(apiUrlOffers, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${authToken}`, // Include the token in the headers
                    "Content-Type": "application/json",
                    // Add other headers if needed
                },
            })
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    if (data.results.length === 0) {
                        setIsCashbackOfferListEmpty(true);
                    }
                    setOffers(data.results);
                })
                .catch((error) => console.error("Error fetching offers:", error));
        };

        fetchData();
    }, [authToken]);

    const columns = useMemo(
        () => [
            {
                Header: "Percentage (%)",
                accessor: "percentage",
                disableFilters: true,
                filterable: false,
            },

            {
                Header: "Description",
                accessor: "description", // Use the correct key
                disableFilters: true,
                filterable: false,
                // Cell: ({ value }) => categoryMapping[value] || value,
            },
            {
                Header: "Start Date",
                accessor: "start_date",
                Cell: ({ cell: { value } }) => {
                    const datePart = value.substring(0, 10);
                    return datePart;
                },
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "End Date",
                accessor: "end_date",
                Cell: ({ cell: { value } }) => {
                    const datePart = value.substring(0, 10);
                    return datePart;
                },
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Time",
                accessor: "end_time",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Action",
                accessor: (cellProps) => {
                    const cashbackOfferId = cellProps.id;

                    return (
                        <React.Fragment>
                            <Link
                                to={`/route-money/merchant/cashback-offer/edit/${cashbackOfferId}`}
                                className="me-3 text-secondary"
                            >
                                <i className="mdi mdi-pencil font-size-18"></i>
                            </Link>
                            <Link
                                to="#"
                                className="me-3 text-danger"
                                onClick={() => deleteCashbackOffer(cashbackOfferId)}
                            >
                                <i className="mdi mdi-delete font-size-18"></i>
                            </Link>
                        </React.Fragment>
                    );
                },
                disableFilters: true,
                filterable: false,
            },
        ]
        //[categoryMapping]
    );

    const breadcrumbItems = [
        { title: "Offers", link: "/merchant/offers" },
        { title: "Offers List", link: "#" },
    ];

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Offers List" breadcrumbItems={breadcrumbItems} />
                    <Link to="/route-money/merchant/cashback-offer/add" className="btn btn-primary mb-3">
                        Create Cashback Offer
                    </Link>

                    {deleteSuccessMessage && <Alert color="success">{deleteSuccessMessage}</Alert>}

                    <Card>
                        <CardBody>
                            <TableContainer
                                columns={columns || []}
                                data={offers || []}
                                isPagination={false}
                                iscustomPageSize={false}
                                isBordered={false}
                                customPageSize={10}
                                isEmpty={isCashbackOfferListEmpty}
                                isEmptyContent="There are no cashback offers available currently."
                            />
                        </CardBody>
                    </Card>
                </Container>
            </div>
            <Modal isOpen={deleteModal} toggle={toggleDeleteModal}>
                <ModalHeader toggle={toggleDeleteModal}>Confirm Delete</ModalHeader>
                <ModalBody>Are you sure you want to delete this Offer?</ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggleDeleteModal}>
                        Cancel
                    </Button>
                    <Button color="danger" onClick={confirmDelete}>
                        Confirm
                    </Button>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
};

export default MerchantCashbackOffers;
