import React from "react";
import { Navigate } from "react-router-dom";
// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import ResetPasswordPage from "../pages/Authentication/ResetPassword";
import ResetPasswordOTPVerify from "../pages/Authentication/ResetPasswordOTPVerify";
import AuthLockScreen from "../pages/Authentication/AuthLockScreen";

// Dashboard
import Dashboard from "../pages/Dashboard/index";
import BranchDashboard from "../pages/Dashboard/Branch/index";

// Pages Calendar
import Calendar from "../pages/Calendar/Calendar";

// Pages Component
import Chat from "../pages/Chat/Chat";

//Ecommerce Pages
import EcommerceProducts from "../pages/Ecommerce/EcommerceProducts/index";
import EcommerceProductDetail from "../pages/Ecommerce/EcommerceProducts/EcommerceProductDetail";
import EcommerceOrders from "../pages/Ecommerce/EcommerceOrders/index";
import EcommerceCustomers from "../pages/Ecommerce/EcommerceCustomers/index";
import EcommerceCart from "../pages/Ecommerce/EcommerceCart";
import EcommerceCheckout from "../pages/Ecommerce/EcommerceCheckout";
import EcommerceShops from "../pages/Ecommerce/EcommerceShops/index";
import EcommerceAddProduct from "../pages/Ecommerce/EcommerceAddProduct";

// Profile Page
import ProfilePage from "../pages/Profile/Merchant/index";

//Users Pages
import PendingPOS from "../pages/Users/PendingPOS/index";
import Customers from "../pages/Users/Customers/index";
import Merchants from "../pages/Users/Merchants/index";
import AdminStaffs from "../pages/Users/AdminStaffs/index";
import InactiveCustomers from "../pages/Users/InactiveCustomers/index";

//Merchant Payments Pages
import IntrawalletTransactions from "../pages/MerchantPayments/IntrawalletPayments/index";
import CollectionTransactions from "../pages/MerchantPayments/Collections/index";
import DisbursementHistory from "../pages/MerchantPayments/DisbursementHistory/index";

//All Orders Pages
import PendingOrders from "../pages/Orders/PendingOrders/index";
import OrderHistory from "../pages/Orders/OrderHistory/index";
import Transactions from "../pages/Orders/Transactions/index";

// branch order pages
import BranchPendingOrders from "../pages/Orders/Branch/PendingOrders/index";
import BranchOrderCreate from "../pages/Orders/Branch/Add/index";
import BranchOrderHistory from "../pages/Orders/Branch/OrderHistory/index";
import BranchTransactions from "../pages/Orders/Branch/Transactions/index";


//Merchant Orders Pages
import MerchantPendingOrders from "../pages/Orders/Merchant/PendingOrders/index";
import WaiterPendingOrders from "../pages/Orders/Waiter/index";
import MerchantOrderCreate from "../pages/Orders/Merchant/Add/index";
import MerchantOrderHistory from "../pages/Orders/Merchant/OrderHistory/index";
import WaiterOrderHistory from "../pages/Orders/OrderHistory/waiterOrderHistory";
import MerchantTransactions from "../pages/Orders/Merchant/Transactions/index";

//Branch Products Pages
import BranchProducts from "../pages/Products/Branch/Products/index";
import BranchCategories from "../pages/Products/Branch/Categories/index";
import BranchSubCategories from "../pages/Products/Branch/SubCategories/index";
import BranchAdvertise from "../pages/Products/Branch/Advertise/index";
import BranchProductAdd from "../pages/Products/Branch/Add/index";
import BranchProductEdit from "../pages/Products/Branch/Edit/index";
import BranchCategoryAdd from "../pages/Products/Branch/Add/category";
import BranchCategoryEdit from "../pages/Products/Branch/Edit/editCategory";
import BranchSubCategoryAdd from "../pages/Products/Branch/Add/subcategory";
import BranchSubCategoryEdit from "../pages/Products/Branch/Edit/editSubCategory";

//Merchant Products Pages
import MerchantProducts from "../pages/Products/Merchant/Products/index";
import MerchantCategories from "../pages/Products/Merchant/Categories/index";
import MerchantSubCategories from "../pages/Products/Merchant/SubCategories/index";
import MerchantAdvertise from "../pages/Products/Merchant/Advertise/index";
import MerchantProductAdd from "../pages/Products/Merchant/Add/index";
import MerchantProductEdit from "../pages/Products/Merchant/Edit/index";
import MerchantCategoryAdd from "../pages/Products/Merchant/Add/category";
import MerchantCategoryEdit from "../pages/Products/Merchant/Edit/editCategory";
import MerchantSubCategoryAdd from "../pages/Products/Merchant/Add/subcategory";
import MerchantSubCategoryEdit from "../pages/Products/Merchant/Edit/editSubCategory";

//Merchant Offers Pages
import MerchantProductOffers from "../pages/Offers/Merchant/Offers/index";
import MerchantProductOfferAdd from "../pages/Offers/Merchant/Add/index";
import MerchantProductOfferEdit from "../pages/Offers/Merchant/Edit/index";
import MerchantCashbackOffers from "../pages/Offers/Merchant/Cashback/index";
import MerchantCashbackOfferAdd from "../pages/Offers/Merchant/Add/cashback";
import MerchantCashbackOfferEdit from "../pages/Offers/Merchant/Edit/editCashback";

// Brach table 
import BranchAddTable from "../pages/Reservation/Branch/Add/index";
import BranchViewTables from "../pages/Reservation/Branch/View/index";

// merchant table 
import AddTable from "../pages/Reservation/Add/index";
import ViewTables from "../pages/Reservation/View/index";

//Merchant Branch
import MerchantBranchAdd from "../pages/Branch/Merchant/Add/index";
import MerchantBranchList from "../pages/Branch/Merchant/Branch/index";
import MerchantBranchEdit from "../pages/Branch/Merchant/Edit/index";

//All Reports Pages
import RevenueReport from "../pages/Reports/RevenueReport/index";
import TransactionFees from "../pages/Reports/TransactionFees/index";
import TransactionStatements from "../pages/Reports/TransactionStatements/index";

//Merchant Reports Pages
import MerchantRevenueReport from "../pages/Reports/Merchant/RevenueReport/index";
import MerchantTransactionFees from "../pages/Reports/Merchant/TransactionFees/index";

//User Management Pages
import CreateUser from "../pages/UserManagement/CreateUser/userDemo";
import User from "../pages/UserManagement/User/index";

// Branch user mant
import BranchCreateUser from "../pages/UserManagement/Branch/CreateUser/userDemo";
import BranchUser from "../pages/UserManagement/Branch/User/index";

//Coupon Management
import CouponHistory from "../pages/CouponManagement/CouponHistory/index";
import CustomerCouponBalances from "../pages/CouponManagement/CustomerCouponBalances/index";

//Activity Logs Pages
import Activities from "../pages/ActivityLogs/Activities/index";
import MerchantActivities from "../pages/ActivityLogs/Activities/merchantlogs"

//Email
import EmailInbox from "../pages/Email/email-inbox";
import EmailRead from "../pages/Email/email-read";

// Charts
import ChartApex from "../pages/Charts/Apexcharts";
import ChartjsChart from "../pages/Charts/ChartjsChart";
import SparklineChart from "../pages/Charts/SparklineChart";
import ChartsKnob from "../pages/Charts/jquery-knob";

// Maps
import MapsGoogle from "../pages/Maps/MapsGoogle";
import MapsVector from "../pages/Maps/MapsVector";

//Icons
import RemixIcons from "../pages/Icons/RemixIcons";
import MaterialDesign from "../pages/Icons/MaterialDesign";
import DripiIcons from "../pages/Icons/DripiIcons";
import FontAwesome from "../pages/Icons/FontAwesome";

//Utility
import StarterPage from "../pages/Utility/StarterPage";
import Maintenance from "../pages/Utility/Maintenance";
import CommingSoon from "../pages/Utility/CommingSoon";
import Timeline from "../pages/Utility/Timeline";
import FAQs from "../pages/Utility/FAQs";
import Pricing from "../pages/Utility/Pricing";
import Error404 from "../pages/Utility/Error404";
import Error500 from "../pages/Utility/Error500";

// Forms
import FormElements from "../pages/Forms/FormElements";
import FormAdvanced from "../pages/Forms/FormAdvanced";
import FormEditors from "../pages/Forms/FormEditors";
import FormValidations from "../pages/Forms/FormValidations";
import FormMask from "../pages/Forms/FormMask";
import FormUpload from "../pages/Forms/FormUpload";
import FormWizard from "../pages/Forms/FormWizard";
import FormXeditable from "../pages/Forms/FormXeditable";

//Ui
import UiAlert from "../pages/Ui/UiAlert";
import UiButtons from "../pages/Ui/UiButtons";
import UiCards from "../pages/Ui/UiCards";
import UiCarousel from "../pages/Ui/UiCarousel";
import UiDropdown from "../pages/Ui/UiDropdown";
import UiGeneral from "../pages/Ui/UiGeneral";
import UiGrid from "../pages/Ui/UiGrid";
import UiImages from "../pages/Ui/UiImages";
import UiLightbox from "../pages/Ui/UiLightbox";
import UiModal from "../pages/Ui/UiModal";
import UiProgressbar from "../pages/Ui/UiProgressbar";
import UiTabsAccordions from "../pages/Ui/UiTabsAccordions";
import UiTypography from "../pages/Ui/UiTypography";
import UiVideo from "../pages/Ui/UiVideo";
import UiSessionTimeout from "../pages/Ui/UiSessionTimeout";
import UiRating from "../pages/Ui/UiRating";
import UiRangeSlider from "../pages/Ui/UiRangeSlider";
import UiNotifications from "../pages/Ui/ui-notifications";
import UIRoundSlider from "../pages/Ui/UIRoundSlider";

//Tables
import BasicTables from "../pages/Tables/BasicTables";
import DatatableTables from "../pages/Tables/DatatableTables";
import ResponsiveTables from "../pages/Tables/ResponsiveTables";
import EditableTables from "../pages/Tables/EditableTables";

// Inner Authentication
import Login1 from "../pages/AuthenticationInner/Login";
import Register1 from "../pages/AuthenticationInner/Register";
import ForgetPwd1 from "../pages/AuthenticationInner/ForgetPassword";

// Branch withdraw 
import BranchWithdraw from "../pages/Withdrawals/Branch/Withdraw";
import BranchWithdrawForm from "../pages/Withdrawals/Branch/innitiator/Index";

// withdraw 
import Withdraw from "../pages/Withdrawals/Withdraw";
import WithdrawForm from "../pages/Withdrawals/innitiator/Index";
import SendFunds from "../pages/Withdrawals/SendFunds/Index";

const authProtectedRoutes = [
    //Merchant Profile Page
    { path: "/route-money/merchant/profile", component: ProfilePage },

 
    //Users
    { path: "/route-money/pending-pos", component: PendingPOS },
    { path: "/route-money/inactive-customers", component: InactiveCustomers },
    { path: "/route-money/merchants", component: Merchants },
    { path: "/route-money/admin_staff", component: AdminStaffs },
    { path: "/route-money/customers", component: Customers },

    //Merchant Payments
    { path: "/route-money/disbursement-history", component: DisbursementHistory },
    { path: "/route-money/collection-transactions", component: CollectionTransactions },
    { path: "/route-money/intrawallet-transactions", component: IntrawalletTransactions },

    // withdrawals 
    { path: "/route-money/withdraw", component: Withdraw },
    { path: "/route-money/withdraw-checkout", component: WithdrawForm },
    { path: "/route-money/send-funds", component: SendFunds },

    //All Orders
    { path: "/route-money/pending-orders", component: PendingOrders },
    { path: "/route-money/waiter/pending-orders", component: WaiterPendingOrders },
    { path: "/route-money/order-history", component: OrderHistory },
    { path: "/route-money/transactions", component: Transactions },

    //Merchant Orders
    { path: "/route-money/merchant/pending-orders", component: MerchantPendingOrders },
    { path: "/route-money/merchant/order/create", component: MerchantOrderCreate },
    { path: "/route-money/merchant/order-history", component: MerchantOrderHistory },
    { path: "/route-money/waiter/order-history", component: WaiterOrderHistory },
    { path: "/route-money/merchant/transactions", component: MerchantTransactions },

    // withdraw page

    //Merchant Products
    { path: "/route-money/merchant/products", component: MerchantProducts },
    { path: "/route-money/merchant/product/categories", component: MerchantCategories },
    { path: "/route-money/merchant/product/sub-categories", component: MerchantSubCategories },
    { path: "/route-money/merchant/product/advertise", component: MerchantAdvertise },
    { path: "/route-money/merchant/product/add", component: MerchantProductAdd },
    { path: "/route-money/merchant/product/edit/:productId", component: MerchantProductEdit },
    { path: "/route-money/merchant/category/add", component: MerchantCategoryAdd },
    { path: "/route-money/merchant/category/edit/:categoryId", component: MerchantCategoryEdit },
    { path: "/route-money/merchant/subcategory/add", component: MerchantSubCategoryAdd },
    { path: "/route-money/merchant/subcategory/edit/:subcategoryId", component: MerchantSubCategoryEdit },

    //Merchant Offers
    { path: "/route-money/merchant/product-offers", component: MerchantProductOffers },
    { path: "/route-money/merchant/product-offer/add", component: MerchantProductOfferAdd },
    { path: "/route-money/merchant/product-offer/edit/:offerId", component: MerchantProductOfferEdit },
    { path: "/route-money/merchant/cashback-offers", component: MerchantCashbackOffers },
    { path: "/route-money/merchant/cashback-offer/add", component: MerchantCashbackOfferAdd },
    { path: "/route-money/merchant/cashback-offer/edit/:cashbackOfferId", component: MerchantCashbackOfferEdit },

      //merchant tables
      { path: "/route-money/merchant/table/add", component: AddTable },
      { path: "/route-money/merchant/table/all", component: ViewTables },
  
   
    //Merchant Branch
    { path: "/route-money/merchant/branch/add", component: MerchantBranchAdd },
    { path: "/route-money/merchant/branch/list", component: MerchantBranchList },
    { path: "/route-money/merchant/branch/edit/:branchId", component: MerchantBranchEdit },
      // Branches

    // branch Dashboard
    { path: "/route-money/branch/dashboard", component: BranchDashboard },

    // branch orders
    { path: "/route-money/branch/pending-orders", component: BranchPendingOrders },
    { path: "/route-money/branch/order-history", component: BranchOrderHistory },
    { path: "/route-money/branch/transactions", component: BranchTransactions },

    //branch Products
    { path: "/route-money/branch/products", component: BranchProducts },
    { path: "/route-money/branch/product/categories", component: BranchCategories },
    { path: "/route-money/branch/product/sub-categories", component: BranchSubCategories },
    { path: "/route-money/branch/product/advertise", component: BranchAdvertise },
    { path: "/route-money/branch/product/add", component: BranchProductAdd },
    { path: "/route-money/branch/product/edit/:productId", component: BranchProductEdit },
    { path: "/route-money/branch/category/add", component: BranchCategoryAdd },
    { path: "/route-money/branch/category/edit/:categoryId", component: BranchCategoryEdit },
    { path: "/route-money/branch/subcategory/add", component: BranchSubCategoryAdd },
    { path: "/route-money/branch/subcategory/edit/:subcategoryId", component: BranchSubCategoryEdit },

     //branch tables
     { path: "/route-money/branch/table/add", component: BranchAddTable },
     { path: "/route-money/branch/table/all", component: BranchViewTables },

    //  branch user mgt
    // /route-money/branch/user
    { path: "/route-money/branch/create-user", component: BranchCreateUser },
    { path: "/route-money/branch/user", component: BranchUser },

         //branch withdrawals 
    { path: "/route-money/branch/withdraw", component: BranchWithdraw },
    { path: "/route-money/branch/withdraw-checkout", component: BranchWithdrawForm },
    // { path: "/route-money/branch/", component: BranchWithdrawForm },

 
    //Coupon Mgt
    { path: "/route-money/coupon-balance", component: CustomerCouponBalances },
    { path: "/route-money/coupon-history", component: CouponHistory },

    //User Mgt
    { path: "/route-money/create-user", component: CreateUser },
    { path: "/route-money/user", component: User },

    //Activity Logs
    { path: "/route-money/activities", component: Activities },
    { path: "/route-money/merchant/activities", component: MerchantActivities },

    //Reports
    { path: "/route-money/revenue-report", component: RevenueReport },
    { path: "/route-money/transaction-statements", component: TransactionStatements },
    { path: "/route-money/transaction-fees", component: TransactionFees },

    //Merchant Reports
    { path: "/route-money/merchant/revenue-report", component: MerchantRevenueReport },
    { path: "/route-money/merchant/transaction-fees", component: MerchantTransactionFees },

    // Tables
    { path: "/basic-tables", component: BasicTables },
    { path: "/datatable-table", component: DatatableTables },
    { path: "/responsive-table", component: ResponsiveTables },
    { path: "/editable-table", component: EditableTables },

    // Ui
    { path: "/ui-alerts", component: UiAlert },
    { path: "/ui-buttons", component: UiButtons },
    { path: "/ui-cards", component: UiCards },
    { path: "/ui-carousel", component: UiCarousel },
    { path: "/ui-dropdowns", component: UiDropdown },
    { path: "/ui-general", component: UiGeneral },
    { path: "/ui-grid", component: UiGrid },
    { path: "/ui-images", component: UiImages },
    { path: "/ui-lightbox", component: UiLightbox },
    { path: "/ui-modals", component: UiModal },
    { path: "/ui-progressbars", component: UiProgressbar },
    { path: "/ui-tabs-accordions", component: UiTabsAccordions },
    { path: "/ui-typography", component: UiTypography },
    { path: "/ui-video", component: UiVideo },
    { path: "/ui-session-timeout", component: UiSessionTimeout },
    { path: "/ui-rating", component: UiRating },
    { path: "/ui-rangeslider", component: UiRangeSlider },
    { path: "/ui-notifications", component: UiNotifications },
    { path: "/ui-roundslider", component: UIRoundSlider },

    // Forms
    { path: "/form-elements", component: FormElements },
    { path: "/form-advanced", component: FormAdvanced },
    { path: "/form-editors", component: FormEditors },
    { path: "/form-mask", component: FormMask },
    { path: "/form-file-upload", component: FormUpload },
    { path: "/form-wizard", component: FormWizard },
    { path: "/form-validation", component: FormValidations },
    { path: "/form-xeditable", component: FormXeditable },

    //Utility
    { path: "/starter", component: StarterPage },
    { path: "/timeline", component: Timeline },
    { path: "/faqs", component: FAQs },
    { path: "/pricing", component: Pricing },

    //Icons
    { path: "/icons-remix", component: RemixIcons },
    { path: "/material-design", component: MaterialDesign },
    { path: "/dripicons", component: DripiIcons },
    { path: "/font-awesome-5", component: FontAwesome },

    // Maps
    { path: "/google-maps", component: MapsGoogle },
    { path: "/vector-maps", component: MapsVector },

    //Charts
    { path: "/apex-charts", component: ChartApex },
    { path: "/chartjs", component: ChartjsChart },
    { path: "/charts-sparkline", component: SparklineChart },
    { path: "/charts-knob", component: ChartsKnob },

    //Email
    { path: "/email-inbox", component: EmailInbox },
    { path: "/email-read", component: EmailRead },

    //Ecommerce

    { path: "/ecommerce-products", component: EcommerceProducts },
    { path: "/ecommerce-product-detail/:id", component: EcommerceProductDetail },
    { path: "/ecommerce-orders", component: EcommerceOrders },
    { path: "/ecommerce-customers", component: EcommerceCustomers },
    { path: "/ecommerce-cart", component: EcommerceCart },
    { path: "/ecommerce-checkout", component: EcommerceCheckout },
    { path: "/ecommerce-shops", component: EcommerceShops },
    { path: "/ecommerce-add-product", component: EcommerceAddProduct },

    //chat
    { path: "/chat", component: Chat },

    //calendar
    { path: "/calendar", component: Calendar },

    { path: "/route-money/dashboard", component: Dashboard },

    // this route should be at the end of all other routes
    { path: "/", exact: true, component: () => <Navigate to="/route-money/dashboard" /> },

  
];

const publicRoutes = [
    { path: "/route-money/merchant/logout", component: Logout },
    { path: "/route-money/merchant/login", component: Login },
    { path: "/route-money/merchant/forgot-password", component: ForgetPwd },
    { path: "/route-money/merchant/reset-password", component: ResetPasswordPage },
    { path: "/route-money/merchant/reset-password-otp-verify", component: ResetPasswordOTPVerify },
    { path: "/route-money/merchant/register", component: Register },
    { path: "/route-money/lock-screen", component: AuthLockScreen },

    // Authentication Inner
    { path: "/route-money/auth-login", component: Login1 },
    { path: "/route-money/auth-register", component: Register1 },
    { path: "/route-money/auth-recoverpw", component: ForgetPwd1 },

    { path: "/route-money/maintenance", component: Maintenance },
    { path: "/route-money/comingsoon", component: CommingSoon },
    { path: "/404", component: Error404 },
    { path: "/500", component: Error500 },
];

export { authProtectedRoutes, publicRoutes };
