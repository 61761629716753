import React, { useState } from "react";
import { connect } from "react-redux";
import { Row, Col, Alert, Button, Container, Label } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import axios from "axios";
import { forgetUser } from "../../store/actions";
import logodark from "../../assets/images/logo-dark.png";
import logolight from "../../assets/images/logo-light-new.jpg";
import withRouter from "../../components/Common/withRouter";
import { storeEmailInSession } from "./utilities/sessionWork";
import BACKEND_DOMAIN from "../../service";

const EMAIL_SENT_MESSAGE = "A verification code has been sent to your email. Check your email to proceed.";

const ForgetPasswordPage = () => {
    const [email, setEmail] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [passwordResetErrorMessage, setPasswordResetErrorMessage] = useState(null);
    const [passwordResetEmailSent, setPasswordResetEmailSent] = useState(null);

    const navigate = useNavigate();

    const handleValidSubmit = async (event) => {
        event.preventDefault();

        setIsLoading(true);

        const apiUrl = `${BACKEND_DOMAIN}/admin/merchants/reset-password/`;

        try {
            await axios.post(apiUrl, { email });

            setPasswordResetEmailSent(EMAIL_SENT_MESSAGE);

            // Store the correct email in the current session:
            storeEmailInSession(email);

            setTimeout(() => {
                navigate("/route-money/merchant/reset-password-otp-verify");
            }, 2000);
        } catch (e) {
            // To display a better error message later!
            setPasswordResetErrorMessage("An Error occurred.");
            setTimeout(() => {
                setPasswordResetErrorMessage(null);
            }, 3000);
        } finally {
            setIsLoading(false);
            setEmail("");
        }
    };

    return (
        <div>
            <Container fluid className="p-0">
                <Row className="g-0">
                    <Col lg={4}>
                        <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                            <div className="w-100">
                                <Row className="justify-content-center">
                                    <Col lg={9}>
                                        <div>
                                            <div className="text-center">
                                                <div>
                                                    <Link to="/" className="">
                                                        <img
                                                            src={logodark}
                                                            alt=""
                                                            height="35"
                                                            className="auth-logo logo-light mx-auto"
                                                        />

                                                        <img
                                                            src={logolight}
                                                            alt=""
                                                            height="35"
                                                            className="auth-logo logo-dark mx-auto"
                                                        />
                                                    </Link>
                                                </div>

                                                <h4 className="font-size-18 mt-4">Reset Password</h4>
                                                <p className="text-muted">
                                                    Reset your password to access your account.
                                                </p>
                                            </div>

                                            {!passwordResetEmailSent ? (
                                                <div className="p-2 mt-3">
                                                    {passwordResetErrorMessage && (
                                                        <Alert color="danger" className="mb-4">
                                                            {passwordResetErrorMessage}
                                                        </Alert>
                                                    )}
                                                    <AvForm
                                                        className="form-horizontal"
                                                        onValidSubmit={handleValidSubmit}
                                                    >
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="email">
                                                                Email <span className="text-danger">*</span>
                                                            </Label>
                                                            <AvField
                                                                name="email"
                                                                value={email}
                                                                type="text"
                                                                className="form-control"
                                                                id="email"
                                                                validate={{ email: true, required: true }}
                                                                placeholder="Enter email"
                                                                onChange={(e) => setEmail(e.target.value)}
                                                            />
                                                        </div>

                                                        <div className="mt-4 text-center">
                                                            <Button
                                                                color="primary"
                                                                className="w-md waves-effect waves-light"
                                                                type="submit"
                                                                disabled={isLoading}
                                                            >
                                                                {isLoading ? (
                                                                    <>
                                                                        <span
                                                                            className="spinner-border spinner-border-sm"
                                                                            role="status"
                                                                            aria-hidden="true"
                                                                        ></span>
                                                                        <span className="ms-2">Sending Email..</span>
                                                                    </>
                                                                ) : (
                                                                    "Reset"
                                                                )}
                                                            </Button>
                                                        </div>
                                                    </AvForm>
                                                </div>
                                            ) : (
                                                <div className="d-flex justify-content-center py-5 px-3 text-center">
                                                    {EMAIL_SENT_MESSAGE}
                                                </div>
                                            )}

                                            <div className="mt-3 text-center">
                                                <p>
                                                    Back to{" "}
                                                    <Link
                                                        to="/route-money/merchant/login"
                                                        className="fw-medium text-primary"
                                                    >
                                                        {" "}
                                                        Log in{" "}
                                                    </Link>{" "}
                                                </p>
                                                <p className="mt-5">© {new Date().getFullYear()} Route Money.</p>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                    <Col lg={8}>
                        <div className="authentication-bg"></div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

const mapStateToProps = (state) => ({
    message: state.Forget.message,
    forgetError: state.Forget.forgetError,
    loading: state.Forget.loading,
});

export default withRouter(connect(mapStateToProps, { forgetUser })(ForgetPasswordPage));
