// ImageCell.js

import React from "react";

const ImageCell = ({ value }) => {
    return (
        <img
            src={value}
            alt="Product"
            style={{ width: "70px", height: "50px", objectFit: "cover", borderRadius: "5px" }}
        />
    );
};

export default ImageCell;
