import React, { useState, useEffect } from "react";
import "firebase/compat/auth";

import { Row, Col, Button, Alert, Container, Label,Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Slider,MenuItem, FormControl, InputLabel } from '@mui/material';
import { Card, CardBody, TabContent, TabPane, NavItem, NavLink, Input, Form } from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import classnames from "classnames";
import { useSelector } from 'react-redux';
import logodark from "../../../../assets/images/logo-dark.png";
import logolight from "../../../../assets/images/logo-light-new.jpg";
import "../../../../assets/css/Error.css";
import { registerUserStart, registerUserSuccessful, registerUserFailed } from "../../../../store/actions";
//import validateRequiredFields from "../../../../Authentication/utilities/registrationFormValidation";
import Select from "react-select";
import businessTypes from "../../../Authentication/utilities/businessTypes";
// import { specifiedBusinessTypes } from "../../../Authentication/utilities/businessTypes";
import { specifiedBusinessTypes } from "../../../Authentication/utilities/businessTypes";
import UserRoles from './UserRoles';
import { fetchTable } from "../../../Reservation/View/utilities/tabledata";
import BACKEND_DOMAIN from "../../../../service";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

// React PhoneInput custom styles
const customInputStyle = {
    width: "100%",
    height: "40px",
    backgroundColor: "inherit",
    border: "1px solid #cacaca",
    marginTop: "50px",
};

const customDropdownStyle = {
    backgroundColor: "inherit",
    borderRadius: 0,
    boxShadow: "none",
    border: "1px solid transparent",
    color: "inherit",
    height: "fit-content",
    marginTop: 0,
    marginLeft: "-2px",
};

// React Select input field Custom styles
const colourStyles = {
    control: (styles) => ({
        ...styles,
        backgroundColor: "inherit",
        borderColor: "transparent",
        color: "inherit",
        margin: "-7.5px -12px",
        ":hover": {
            borderColor: "transparent",
        },
    }),
    placeholder: (styles) => ({
        ...styles,
        color: "inherit",
    }),
    input: (styles) => ({
        ...styles,
        color: "inherit",
    }),
    menu: (styles) => ({
        ...styles,
        backgroundColor: "inherit",
        margin: "8px -14px",
        width: "101%",
    }),
    option: (styles, { isDisabled, isFocused, isSelected }) => {
        return {
            ...styles,
            backgroundColor: isDisabled ? null : isSelected ? "inherit" : isFocused ? "#5664d2" : null,
            color: isDisabled ? null : isSelected ? "#5664d2" : "inherit",
            ":active": {
                ...styles[":active"],
                backgroundColor: !isDisabled ? (isSelected ? "#5664d2" : "#5664d2") : null,
            },
        };
    },
};

const UserDemo = (props) => {
    const token = useSelector((state) => state.Login.token);
    const [otpVerificationSuccessful, setOtpVerificationSuccessful] = useState(false);
    const [registrationError, setRegistrationError] = useState(null);
    const [userRegistrationSuccessful, setUserRegistrationSuccessful] = useState(false);
    const [userRegistrationfailure, setUserRegistrationFailure] = useState('');
    const [registrationFailedErrorMessage, setRegistrationFailedErrorMessage] = useState(null);

    // Loading spinners for said actions:
    const [isSubmittingRegistration, setIsSubmittingRegistration] = useState(false);
    const [errors, setErrors] = useState({});
    const [modalOpen, setModalOpen] = useState(false);

    const [allFormData, setAllFormData] = useState({
        pane1: {
            surname:"",
            first_name: "",
            last_name: "",
            username: "",
            phone_number: "",
            email: "",
            password: "",
            confirmPassword:"",
  
        },

        pane2: {
            role: "",
            permissions:[],
            tables:[],
        },
    });

    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState(1);
    const [activeTabProgress, setActiveTabProgress] = useState(1);
    // eslint-disable-next-line
    const [progressValue, setProgressValue] = useState(25);
    // const formRef = useRef(null);
    const [passwordMatch, setPasswordMatch] = useState(true);
    const [passwordVisible, setPasswordVisible] = useState(false);

    // Temp value holder for the business type:
    const [selectedBusinessType, setSelectedBusinessType] = useState(null);
    const [selectedSpecBusinessType, setSelectedSpecBusinessType] = useState(null);
    const [specBusinessTypeOptions, setSpecBusinessTypeOptions] = useState([]);

    const { roles } = UserRoles(); // Call the UserRoles function
    const [selectedRole, setSelectedRole] = useState([]);
    const [rolePermissions, setRolePermissions] = useState([]);
    const [selectedPermissions, setSelectedPermissions] = useState([]);

    // Tables
    const [allTableList, setAllTableList] = useState([]);
    const [tableListEmpty, setTableListEmpty] = useState(false);
    const [selectedTables, setSelectedTables] = useState([]);
    const [fieldErrors, setFieldErrors] = useState({});

    
    // fetch table
    const tableapi = `https://backoffice.route.money/api/v1/business/merchant/tables`;

    const fetchAllTables = async () => {
        const authData = JSON.parse(localStorage.getItem("authUser"));
    
        if (!authData || !authData.token) {
          console.error("No auth data found in local storage.");
          return;
        } else {
          //console.log("Auth data: ", authData);
        }
    
        try {
          const results = await fetchTable(authData.token, tableapi);
          let processedUsers = [];
         // console.log('results ', results);
    
          if (results.length === 0) {
            setTableListEmpty(true);
          } else {
            processedUsers = results;
            setAllTableList(processedUsers);
          }
        } catch (error) {
          console.error("Error fetching tables: ", error);
        }
      };
    
      useEffect(() => {
        fetchAllTables();
      }, []);

    const handleRoleChange = (selectedOption) => {
        const selectedRole = roles.find((role) => role.id === selectedOption.value);
        setSelectedRole(selectedRole);
        setModalOpen(true);
        setSelectedPermissions(selectedRole ? selectedRole.permissions.map((perm) => perm.id) : []);
    setAllFormData((prevState) => ({
        ...prevState,
        pane2: {
          ...prevState.pane2,
          role: selectedRole ? selectedRole.name : '',
          permissions: selectedRole ? selectedRole.permissions.map((perm) => perm.name) : [],
        },
      }));
      };

      const toggleModal = () => setModalOpen(!modalOpen);

const handlePermissionChange = (permissionId) => {
    setSelectedPermissions((prevPermissions) => {
const updatedPermissions = prevPermissions.includes(permissionId)
? prevPermissions.filter((id) => id !== permissionId)
: [...prevPermissions, permissionId];

const updatedPermissionNames = selectedRole.permissions
.filter((perm) => updatedPermissions.includes(perm.id))
.map((perm) => perm.name);

// Update form data
setAllFormData((prevState) => ({
...prevState,
pane2: {
  ...prevState.pane2,
  permissions: updatedPermissionNames,
},
}));

return updatedPermissions;
});
};

const handleTableChange = (tableId) => {
    setSelectedTables((prevTables) => {
      const updatedTables = prevTables.includes(tableId)
        ? prevTables.filter((id) => id !== tableId)
        : [...prevTables, tableId];

      const updatedTableNames = allTableList
        .filter((table) => updatedTables.includes(table.id))
        .map((table) => table.name);

      // Update form data
      setAllFormData((prevState) => ({
        ...prevState,
        pane2: {
          ...prevState.pane2,
          tables: updatedTableNames,
        },
      }));
      return updatedTables;
    });
  };

    // input checkter per tab
//confirmPassword
    const validateForm = (tab) => {
        let isValid = true;
        let errors = {};

        if (tab === 1) {
             if (!allFormData.pane1.surname) {
                errors.surname = "Surname is required";
                isValid = false;
            }
            if (!allFormData.pane1.first_name) {
                errors.first_name = "First name is required";
                isValid = false;
            }
            if (!allFormData.pane1.last_name) {
                errors.last_name = "Last Name is required";
                isValid = false;
            }
            if (!allFormData.pane1.username) {
                errors.username = "Username is required";
                isValid = false;
            }
            if (!allFormData.pane1.phone_number) {
                errors.phone_number = "Phone Number is required";
                isValid = false;
            }
            if (!allFormData.pane1.email) {
                errors.email = "Email is required";
                isValid = false;
            }
            if (!allFormData.pane1.password) {
                errors.password = "Password is required";
                isValid = false;
            }
            if (!allFormData.pane1.confirmPassword) {
                errors.confirmPassword = "Password confirm is required";
                isValid = false;
            }
          
        }
        if(tab === 2){
            if (!allFormData.pane2.role) {
                errors.role = "Role is required";
                isValid = false;
            }
          
        }

        setErrors(errors);
        return isValid;
    };

    const toggleTab = (tab) => {
        if (validateForm(activeTab)) {
            if (activeTab !== tab) {
                if (tab >= 1 && tab <= 2) {
                    setActiveTab(tab);
                    toggleTabProgress(tab);
                }
            }
        } else {
            console.log("one or multiple field(s) empty.");
        }
    };

    const toggleTabProgress = (tab) => {
        if (activeTabProgress !== tab) {
            if (tab >= 1 && tab <= 2) {
                setActiveTabProgress(tab);

                if (tab === 1) {
                    setProgressValue(50);
                }
                if (tab === 2) {
                    setProgressValue(100);
                }
              
            }
        }
    };


    const handlePasswordChange = (e) => {
        setAllFormData((prevState) => ({
            ...prevState,
            pane1: {
                ...prevState.pane1,
                password: e.target.value,
            },
        }));
    };

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const handleConfirmPasswordChange = (e) => {
        setAllFormData((prevState) => ({
            ...prevState,
            pane1: {
                ...prevState.pane1,
                confirmPassword: e.target.value,
            },
        }));
    };

    const handleBlur = () => {
        const { password, confirmPassword } = allFormData.pane1;
      //  console.log("pass ",password,' confirm ',confirmPassword);
        setPasswordMatch(password === confirmPassword);
    };

const handleValidSubmit = async (event) => {
    event.preventDefault();
    setIsSubmittingRegistration(true);

    try {
        const apiUrl = `https://backoffice.route.money/api/v1/admin/user/create`;

        // Extract necessary data from allFormData
        const formDataToSend = {
            email: allFormData.pane1.email,
            username: allFormData.pane1.username,
            password: allFormData.pane1.password,
            surname:allFormData.pane1.surname,
            phone_number: allFormData.pane1.phone_number,
            role: allFormData.pane2.role,
            first_name: allFormData.pane1.first_name,
            last_name: allFormData.pane1.last_name,
            permissions:allFormData.pane2.permissions,
            tables:allFormData.pane2.tables
        };

        // Stringify the extracted data
        const jsonData = JSON.stringify(formDataToSend);

        //console.log('token ',token)
        // Log the JSON data being sent
       // console.log("JSON Data being sent:", jsonData);

        const response = await fetch(apiUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`, // Assuming you have the token available
            },
            body: jsonData,
        });


        const responseData = await response.json();
        // Check if the response is ok
        if (!response.ok) {
          //  console.log('error log, ',responseData);
            setUserRegistrationFailure('Failed ',responseData.error);
            throw new Error('Network response was not ok');
        }else{
            setUserRegistrationSuccessful(true);
            setIsSubmittingRegistration(false);

            setTimeout(() => {
                setUserRegistrationSuccessful(false);
                setActiveTab(1);
                setActiveTabProgress(1);
                setProgressValue(50);
            //   reset the form datat
            setAllFormData({
                pane1: {
                  surname: "",
                  first_name: "",
                  last_name: "",
                  username: "",
                  phone_number: "",
                  email: "",
                  password: "",
                  confirmPassword: "",
                },
                pane2: {
                  role: "",
                  permissions: [],
                  tables: [],
                },
              });

              }, 2000);
        }


        // Assuming the response contains the user data
       // console.log('Registration successful:', responseData);


        setIsSubmittingRegistration(false);
      
      // userRegistrationSuccessful()
  

     setTimeout(() => {
        setUserRegistrationSuccessful(false);
                setActiveTab(1);
                setActiveTabProgress(1);
                setProgressValue(50);
            //   reset the form datat
            setAllFormData({
                pane1: {
                  surname: "",
                  first_name: "",
                  last_name: "",
                  username: "",
                  phone_number: "",
                  email: "",
                  password: "",
                  confirmPassword: "",
                },
                pane2: {
                  role: "",
                  permissions: [],
                  tables: [],
                },
              });
              
              }, 2000);


    } catch (error) {

        console.error('Registration failed:', error);
        setUserRegistrationFailure('Failed, ensure to use data previously not registered!');

        setTimeout(() => {
            setUserRegistrationFailure("");
            setActiveTab(1);
            setActiveTabProgress(1);
            setProgressValue(50);
        //   reset the form datat
        setAllFormData({
            pane1: {
              surname: "",
              first_name: "",
              last_name: "",
              username: "",
              phone_number: "",
              email: "",
              password: "",
              confirmPassword: "",
            },
            pane2: {
              role: "",
              permissions: [],
              tables: [],
            },
          });
          
          }, 2000);

        let errorToDisplay = 'An unknown error occurred. Please try again.';
        let fieldErrors = {};

        if (error.response) {
            try {
                const errorResponse = await error.response.json();
                fieldErrors = errorResponse.errors || {};
                errorToDisplay = fieldErrors.email ? fieldErrors.email[0] : error.message;
            } catch (parseError) {
                console.error('Error parsing error response:', parseError);
            }
        } else {
            errorToDisplay = error.message;
        }

        setFieldErrors(fieldErrors);

        setTimeout(() => {
            setRegistrationFailedErrorMessage(null);
        }, 3000);
        console.error('Registration failed:', error.message);
    } finally {
        setIsSubmittingRegistration(false);
    }
};


    useEffect(() => {
        props.dispatch(registerUserFailed(""));
        document.body.classList.add("auth-body-bg");
    }, [props]);

    return (
        <React.Fragment> 
                    {/* <div className="pt-3 pb-3">
                        <Container fluid={true} className="vh-100 d-flex justify-content-center align-items-center"> */}
                        <div className="page-content">
                        <Container fluid={true}>
                            <Row className="d-flex justify-content-center">
                            
                                {userRegistrationSuccessful && <Alert color="success">Registration Done Successfully.</Alert>}
                                {userRegistrationfailure && <Alert color="danger">{userRegistrationfailure}</Alert>}
                         
                        <Col xs="12" sm="8" md="10" lg="12">
                            <Card>
                                <CardBody>
                                    <div id="basic-pills-wizard" className="twitter-bs-wizard">
                                        <ul className="twitter-bs-wizard-nav nav nav-pills nav-justified">
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({
                                                        active: activeTab === 1,
                                                    })}
                                                    onClick={() => {
                                                        toggleTab(1);
                                                    }}
                                                >
                                                    <span className="step-number">01</span>
                                                    <span className="step-title">User Details</span>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({
                                                        active: activeTab === 2,
                                                    })}
                                                    onClick={() => {
                                                        toggleTab(2);
                                                    }}
                                                >
                                                    <span className="step-number">02</span>
                                                    <span className="step-title">Rank and Roles</span>
                                                </NavLink>
                                            </NavItem>
                             
                                        </ul>
                                        <TabContent activeTab={activeTab} className="twitter-bs-wizard-tab-content">
                                            <TabPane tabId={1}>
                                                <Form>
                                                    <Row>

                                                            <Col lg="6">
                                                            <div className="mb-3">
                                                                <Label className="form-label" htmlFor="first_name">
                                                                    Surname{" "}
                                                                    <span className="text-danger">*</span>
                                                                </Label>
                                                                <Input
                                                                    name="surname"
                                                                    value={allFormData.pane1.surname}
                                                                    onChange={(e) => {
                                                                        setAllFormData((prevState) => ({
                                                                            ...prevState,
                                                                            pane1: {
                                                                                ...prevState.pane1,
                                                                                surname: e.target.value,
                                                                            },
                                                                        }));
                                                                    }}
                                                                    type="text"
                                                                    className={`form-control ${errors.surname ? "is-invalid" : ""}`}
                                                                    id="surname"
                                                                    placeholder="Enter First Name"
                                                                />
                                                                  {errors.surname && <div className="invalid-feedback">{errors.surname}</div>}
                                                            </div>
                                                        </Col>
                                                    
                                                        <Col lg="6">
                                                            <div className="mb-3">
                                                                <Label className="form-label" htmlFor="first_name">
                                                                    First Name{" "}
                                                                    <span className="text-danger">*</span>
                                                                </Label>
                                                                <Input
                                                                    name="first_name"
                                                                    value={allFormData.pane1.first_name}
                                                                    onChange={(e) => {
                                                                        setAllFormData((prevState) => ({
                                                                            ...prevState,
                                                                            pane1: {
                                                                                ...prevState.pane1,
                                                                                first_name: e.target.value,
                                                                            },
                                                                        }));
                                                                    }}
                                                                    type="text"
                                                                    className={`form-control ${errors.first_name ? "is-invalid" : ""}`}
                                                                    id="first_name"
                                                                    placeholder="Enter First Name"
                                                                />
                                                                  {errors.first_name && <div className="invalid-feedback">{errors.first_name}</div>}
                                                            </div>
                                                        </Col>

                                                        <Col lg="6">
                                                            <div className="mb-3">
                                                                <Label className="form-label" htmlFor="last_name">
                                                                    Last name{" "}
                                                                    <span className="text-danger">*</span>
                                                                </Label>
                                                                <Input
                                                                    name="last_name"
                                                                    value={allFormData.pane1.last_name}
                                                                    onChange={(e) => {
                                                                        setAllFormData((prevState) => ({
                                                                            ...prevState,
                                                                            pane1: {
                                                                                ...prevState.pane1,
                                                                                last_name: e.target.value,
                                                                            },
                                                                        }));
                                                                    }}
                                                                    type="text"
                                                                    className={`form-control ${errors.last_name ? "is-invalid" : ""}`}
                                                                    id="last_name"
                                                                    placeholder="Enter Last Name"
                                                                />
                                                                  {errors.last_name && <div className="invalid-feedback">{errors.last_name}</div>}
                                                            </div>
                                                        </Col>

                                                        <Col lg="6">

                                                

                                                            <div className="mb-3">
                                                                <Label className="form-label" htmlFor="username">
                                                                    Preffered Username{" "}
                                                                    <span className="text-danger">*</span>
                                                                </Label>
                                                                <Input
                                                                    name="username"
                                                                    value={allFormData.pane1.username}
                                                                    onChange={(e) => {
                                                                        setAllFormData((prevState) => ({
                                                                            ...prevState,
                                                                            pane1: {
                                                                                ...prevState.pane1,
                                                                                username: e.target.value,
                                                                            },
                                                                        }));
                                                                    }}
                                                                    type="text"
                                                                    className={`form-control ${errors.username ? "is-invalid" : ""}`}
                                                                    id="username"
                                                                    placeholder="Enter Last Name"
                                                                />
                                                                  {errors.username && <div className="invalid-feedback">{errors.username}</div>}
                                                            </div>
                                                        </Col>

                                                        </Row>
                                                        <Row>

                                                        <Col lg="6">
                                                            <div className="mb-3">
                                                                <Label className="form-label" htmlFor="email">
                                                                    Email <span className="text-danger">*</span>
                                                                </Label>
                                                                <Input
                                                                    name="email"
                                                                    value={allFormData.pane1.email}
                                                                    onChange={(e) => {
                                                                        setAllFormData((prevState) => ({
                                                                            ...prevState,
                                                                            pane1: {
                                                                                ...prevState.pane1,
                                                                                email: e.target.value,
                                                                            },
                                                                        }));
                                                                    }}
                                                                    type="email"
                                                                   // className="form-control"
                                                                    className={`form-control ${errors.email ? "is-invalid" : ""}`}
                                                                    id="email"
                                                                    placeholder="Enter email"
                                                                />
                                                                 {errors.email && <div className="invalid-feedback">{errors.email}</div>}
                                                            </div>
                                                        </Col>
                                                  
                                                        <Col lg="6">
                                                            <div className="mb-3">
                                                                <Label className="form-label" htmlFor="phone_number">
                                                                    Phone Number{" "}
                                                                    <span className="text-danger">*</span>
                                                                </Label>
                                                            
                                                                <PhoneInput
                                                                    name="phone_number"
                                                                    inputStyle={customInputStyle}
                                                                    dropdownStyle={customDropdownStyle}
                                                                    country={"ke"}
                                                                    onlyCountries={["ke", "ug", "tz", "rw"]}
                                                                    value={allFormData.pane1.phone_number}
                                                                    onChange={(value) => {
                                                                        setAllFormData((prevState) => ({
                                                                            ...prevState,
                                                                            pane1: {
                                                                                ...prevState.pane1,
                                                                                phone_number: "+" + value,
                                                                            },
                                                                        }));
                                                                    }}
                                                                    inputClass={`form-control ${errors.phone_number ? "is-invalid" : ""}`}
                                                                    className={`${errors.phone_number ? "is-invalid" : ""}`}
                                                                    dropdownClass="form-control"
                                                                />
                                                                  {errors.phone_number && <div className="invalid-feedback">{errors.phone_number}</div>}
                                                            </div>
                                                        </Col>

                                                
                                                        
                                                        
                                                    </Row>

                                                    <Row>
                                                            <Col lg="6">
                                                                <div className="mb-1">
                                                                    <Label className="form-label" htmlFor="password">
                                                                        Password <span className="text-danger">*</span>
                                                                    </Label>
                                                                    <Input
                                                                        name="password"
                                                                        value={allFormData.pane1.password}
                                                                        onChange={handlePasswordChange}
                                                                        onBlur={handleBlur}
                                                                        validate={{
                                                                            required: true,
                                                                        }}
                                                                        type={passwordVisible ? "text" : "password"}
                                                                        className={`form-control ${errors.password ? "is-invalid" : ""}`}
                                                                        id="password"
                                                                        placeholder="Enter password"
                                                                    />
                                                                      {errors.password && <div className="invalid-feedback">{errors.password}</div>}
                                                                    <div className="d-flex justify-content-end mt-1">
                                                                        <small
                                                                            className="text-muted cursor-pointer"
                                                                            onClick={togglePasswordVisibility}
                                                                        >
                                                                            {passwordVisible ? "Hide" : "Show"}
                                                                        </small>
                                                                    </div>
                                                                </div>
                                                            </Col>

                                            
                                                            <Col lg="6">
                                                                <div className="mb-3 d-flex flex-column">
                                                                    <Label
                                                                        className="form-label"
                                                                        htmlFor="confirmPassword"
                                                                    >
                                                                        Confirm Password{" "}
                                                                        <span className="text-danger">*</span>
                                                                    </Label>
                                                                    <Input
                                                                        name="confirmPassword"
                                                                        value={allFormData.pane1.confirmPassword}
                                                                        onChange={handleConfirmPasswordChange}
                                                                        onBlur={handleBlur}
                                                                        type={passwordVisible ? "text" : "password"}
                                                                        className={`form-control ${
                                                                            passwordMatch ? "" : "is-invalid"
                                                                        } ${errors.confirmPassword ? "is-invalid" : ""}`}
                                            
                                                                        id="confirmPassword"
                                                                        placeholder="Confirm password"
                                                                    />
                                                                {errors.confirmPassword && <div className="invalid-feedback">{errors.confirmPassword}</div>}
                                                                    {!passwordMatch && (
                                                                        <div className="invalid-feedback">
                                                                            Passwords do not match
                                                                        </div>
                                                                    )}
                                                                    <small
                                                                        style={{
                                                                            marginTop: !passwordMatch ? "-18px" : 3,
                                                                        }}
                                                                        className="text-muted cursor-pointer align-self-end"
                                                                        onClick={togglePasswordVisibility}
                                                                    >
                                                                        {passwordVisible ? "Hide" : "Show"}
                                                                    </small>
                                                                </div>
                                                            </Col>
                                                     
                                                        </Row>
                                                </Form>
                                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                    <Button
                                                        type="button"
                                                        style={{
                                                            backgroundColor: "#5664d2",

                                                        }}
                                                        onClick={() => {
                                                            toggleTab(activeTab + 1);
                                                            toggleTabProgress(activeTab + 1);
                                                        }}
                                                    >
                                                        Next
                                                    </Button>
                                                </div>
                                            </TabPane>
                                            
                                    
                                            <TabPane tabId={2}>
                                                <div>
                                                    <AvForm
                                                        onValidSubmit={handleValidSubmit}
                                                        encType="multipart/form-data"
                                                    >
                                                    

                                                        <Row>
                                                        <Col lg="6">
                                                                <div className="mb-3">
                                                                    <Label>
                                                                        User Rank{" "}
                                                                        <span className="text-danger">*</span>
                                                                    </Label>

                                                                
                                                <Select
                                                        placeholder="Select or Type to filter..."
                                                        options={roles.map(r => ({
                                                        value: r.id,
                                                        label: r.name,
                                                        permissions: r.permissions
                                                        }))}
                                                        value={selectedRole ? { value: selectedRole.id, label: selectedRole.name } : null}
                                                        onChange={handleRoleChange}
                                                        className={`form-control ${errors && errors.role ? "is-invalid" : ""}`}
                                                        styles={colourStyles}
                                                    />
                                                                     
                                                                </div>
                                                            </Col>
                                                          
                                                        </Row>
                                                     <Modal isOpen={modalOpen} toggle={toggleModal} size="lg">
                                                        <ModalHeader toggle={toggleModal}>Role Details</ModalHeader>
                                                        <ModalBody> 
                                                        <Row>
                                                        {selectedRole && selectedRole.permissions && (
                                                            
                                                            <Col lg="6">
                                                                <div className="mb-3">
                                                                <Label>Permissions</Label>
                                                                {selectedRole.permissions.map((permission) => (
                                                                    <div key={permission.id} className="form-check">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="form-check-input"
                                                                        id={`permission-${permission.id}`}
                                                                        checked={selectedPermissions.includes(permission.id)}
                                                                        onChange={() => handlePermissionChange(permission.id)}
                                                                    />
                                                                    <Label className="form-check-label" htmlFor={`permission-${permission.id}`}>
                                                                        {permission.name}
                                                                    </Label>
                                                                    </div>
                                                                ))}
                                                                </div>
                                                            </Col>
                                                        
                                                        )}

                                                        {/* permissions */}

                                               {selectedRole && selectedRole.name === "Waiter" && allTableList.length > 0 && (
                                                
                                                        <Col lg="6">
                                                            <div className="mb-3">
                                                            <Label>Tables</Label>
                                                            {allTableList.map((table) => (
                                                                <div key={table.id} className="form-check">
                                                                <input
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    id={`table-${table.id}`}
                                                                    checked={selectedTables.includes(table.id)}
                                                                    onChange={() => handleTableChange(table.id)}
                                                                />
                                                                <Label className="form-check-label" htmlFor={`table-${table.id}`}>
                                                                    {table.name}
                                                                </Label>
                                                                </div>
                                                            ))}
                                                            </div>
                                                        </Col>
                                                        
                                                    )}
                                                                                                                    </Row>
                                                                                                                    </ModalBody>
                                                    <ModalFooter>
                                                        <Button color="success" onClick={toggleModal}>Save</Button>
                                                    </ModalFooter>
                                                </Modal>
                                                   {/* table view */}

                                                        {registrationError && (
                                                            <Alert color="danger">{registrationError}</Alert>
                                                        )}
                                                        {registrationFailedErrorMessage && (
                                                            <Alert color="danger">
                                                                {registrationFailedErrorMessage}
                                                            </Alert>
                                                        )}
                                                        <div className="d-flex justify-content-between">
                                                            <Button
                                                                type="button"
                                                                style={{
                                                                    backgroundColor: "#5664d2",
                                                                }}
                                
                                                                onClick={() => {
                                                                    toggleTab(activeTab - 1);
                                                                    toggleTabProgress(activeTab - 1);
                                                                }}
                                                            >
                                                                Previous
                                                            </Button>

                                                       {/* <Button
                                                        type="button"
                                                        style={{
                                                            backgroundColor: "#5664d2",
                                                        }}
                                                        onClick={() => {
                                                            toggleTab(activeTab - 1);
                                                            toggleTabProgress(activeTab - 1);
                                                        }}
                                                    >
                                                        Previous
                                                    </Button> */}

                                                            <Button
                                                                type="submit"
                                                                style={{
                                                                    backgroundColor: "#5664d2",
                                                                }}
                                                                disabled={isSubmittingRegistration}
                                                            >
                                                                {isSubmittingRegistration ? (
                                                                    <>
                                                                        <span
                                                                            className="spinner-border spinner-border-sm"
                                                                            role="status"
                                                                            aria-hidden="true"
                                                                        ></span>
                                                                        <span className="ms-2">Creating User..</span>
                                                                    </>
                                                                ) : (
                                                                    "Register"
                                                                )}
                                                            </Button>
                                                        </div>
                                                    </AvForm>
                                                </div>
                                            </TabPane>
                                        </TabContent>
                                    </div>
                                </CardBody>
                            </Card>
                         
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    const { user, registrationError, loading } = state.Account;
    return { user, registrationError, loading };
};

export default connect(mapStateToProps)(UserDemo);
