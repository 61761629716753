function dateFormatter(dateString) {
    const date = new Date(dateString);
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    const year = date.getFullYear();
    const monthIndex = date.getMonth();
    const monthName = monthNames[monthIndex];
    const day = date.getDate().toString().padStart(2, "0");

    const hours = date.getHours() % 12 || 12; // Convert to 12-hour format
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const meridian = date.getHours() >= 12 ? "PM" : "AM";

    return `${monthName} ${day}, ${year}  ${hours}:${minutes} ${meridian}`;
}

export default dateFormatter;
