import React, { useState,useEffect,useRef } from "react";
import { Container, Row, Col, Card, CardBody, Label, Button, Alert, Modal, ModalHeader,ModalBody,ModalFooter, Spinner } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
//import { uploadBranchRequest, uploadBranchSuccess, uploadBranchFailure } from "../../thunks";
import Select from "react-select";
import walletTypes from "../../utilities/walletTypes";
import businessTypes from "../../../Authentication/utilities/businessTypes";
import { specifiedBusinessTypes } from "../../../Authentication/utilities/businessTypes";
 // Ensure you have this utility function
import { canvasPreview } from "../../../Profile/Utilities/canvasPreview"
import { useDebounceEffect } from "../../../Profile/Utilities/useDebounceEffect"; // Ensure you have this custom hook
import imageCompression from 'browser-image-compression';
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import BACKEND_DOMAIN from "../../../../service";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import './ModalStyles.css'; 

const MerchantBranchAdd = () => {
    const token = useSelector((state) => state.Login.token);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [branch, setBranch] = useState({
        sub_name: "",
        sub_contact_person: "",
        sub_phone_number: "",
        sub_alt_phone_number: "",
        sub_location: "",
        sub_email: "",
        sub_profile_image: null,
        sub_cover_image: null,
        sub_password: "",
        sub_wallet_balance: "",
        type:"",
        specific_type:"",
        sub_password_confirm:"",
    
    });

    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [selectedWalletType, setSelectedWalletType] = useState(null);
    const [selectedBusinessType, setSelectedBusinessType] = useState(null);
    const [selectedSpecBusinessType, setSelectedSpecBusinessType] = useState(null);
    const [specBusinessTypeOptions, setSpecBusinessTypeOptions] = useState([]);
    const [profileImage, setProfileImage] = useState(null);
    const [coverImage, setCoverImage] = useState(null);
    const [passwordMatch, setPasswordMatch] = useState(true);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [products, setProducts] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [selectedOption, setSelectedOption] = useState("none");
    const [loading, setLoading] = useState(false);
    const [isProductListEmpty, setIsProductListEmpty]=useState(false);
    const [selectAll, setSelectAll] = useState(false);
    const [cropping, setCropping] = useState(false);
    const [productLoading, setProductLoading]= useState(false)
  

    const [isModalOpen, setIsModalOpen] = useState(false);

    const [crop, setCrop] = useState(null);
    const [completedCrop, setCompletedCrop] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedImageType, setSelectedImageType] = useState(null);
    const [imgSrc, setImgSrc] = useState(null);
    const [scale, setScale] = useState(1);

    const imgRef = useRef(null);
    const previewCanvasRef = useRef(null);

    const aspect = selectedImageType === "sub_profile_image" ? 1 : 16 / 9;

    const handleFileChange = (e) => {
        const { name, files } = e.target;
        const file = files[0];

        if (file) {
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                setImgSrc(reader.result.toString() || "");
                setSelectedImageType(name);
                setModalOpen(true); // Open the crop modal
            });
            reader.readAsDataURL(file);
        }
    };

            // Convert canvas to blob
        //     const handleSaveCrop = async () => {
        //         const canvas = previewCanvasRef.current;
            
        //         // Convert canvas to blob
        //         canvas.toBlob(async (blob) => {
        //             if (blob) {
        //                 try {
        //                     // Compress the image blob
        //                     const compressedBlob = await imageCompression(blob, {
        //                         maxSizeMB: 0.3, // Maximum file size in MB
        //                         maxWidthOrHeight: 900, // Maximum dimensions
        //                         useWebWorker: true,
        //                     });

        //                                 // Determine the MIME type (e.g., 'image/jpeg' or 'image/png')
        //                         const mimeType = compressedBlob.type || blob.type;
        //                         const fileExtension = mimeType.split('/')[1]; // Extract the extension from the MIME type
 
                            

        //                         // Create a new File object with the correct extension
        //                         const croppedFile = new File([compressedBlob], `cropped_image.${fileExtension}`, {
        //                             type: mimeType,
        //                         });
                                
        // // Log the image size
        // const currentSizeKB = croppedFile.size / 1024; // File size in KB
        // console.log(`Image size is ${currentSizeKB.toFixed(2)} KB`);
        //                     // Update the state with the compressed image blob
        //                     setBranch((prevBranch) => ({
        //                         ...prevBranch,
        //                         [selectedImageType]: croppedFile
        //                     }));
            
        //                     setModalOpen(false); // Close the modal after saving
        //                     setCompletedCrop(null); // Reset the crop after saving
        //                 } catch (error) {
        //                     console.error("Error during image compression:", error);
        //                 }
        //             }
        //         });
        //     };

        const handleSaveCrop = async () => {
            const canvas = previewCanvasRef.current;
            setCropping(true)
            // Convert canvas to blob
            canvas.toBlob(async (blob) => {
              if (blob) {
                try {
                  // Compress the image blob
                  const compressedBlob = await imageCompression(blob, {
                    maxSizeMB: 0.3, // Maximum file size in MB
                    maxWidthOrHeight: 900, // Maximum dimensions
                    useWebWorker: true,
                  });
          
                  // Determine the MIME type (e.g., 'image/jpeg' or 'image/png')
                  const mimeType = compressedBlob.type || blob.type;
                  const fileExtension = mimeType.split('/')[1]; // Extract the extension from the MIME type
          
                  // Create a new File object with the correct extension
                  const croppedFile = new File([compressedBlob], `cropped_image.${fileExtension}`, {
                    type: mimeType,
                  });
          
                  // Log the image size
                  const currentSizeKB = croppedFile.size / 1024; // File size in KB
                  //console.log(`Image size is ${currentSizeKB.toFixed(2)} KB`);
          
                  // Create a URL for the compressed image to load it and get dimensions
                  const img = new Image();
                  const url = URL.createObjectURL(compressedBlob);
          
                  img.onload = function () {
                    const width = img.width;
                    const height = img.height;
          
                  //  console.log(`Image width: ${width}px, height: ${height}px`);
          
                    // Release the object URL after the image is loaded
                    URL.revokeObjectURL(url);
          
                    // Update the state with the compressed image blob and dimensions
                    setBranch((prevBranch) => ({
                      ...prevBranch,
                      [selectedImageType]: croppedFile,
                      width, // Store width in state if needed
                      height, // Store height in state if needed
                    }));
          
                    // Close the modal and reset the crop
                    setModalOpen(false); 
                    setCompletedCrop(null);
                  };
          
                  // Load the image from the compressed blob
                  img.src = url;
          
                } catch (error) {
                  console.error("Error during image compression:", error);
                } finally{
                    setCropping(false);
                }
              }
            });
          };
          

        useDebounceEffect(
            async () => {
                if (
                    completedCrop?.width &&
                    completedCrop?.height &&
                    imgRef.current &&
                    previewCanvasRef.current
                ) {
                    await canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop, scale);
                }
            },
            100,
            [completedCrop, scale]
        );

    // select this merchant products
        useEffect(() => {
            const apiUrlProducts = `${BACKEND_DOMAIN}/business/merchant/products/list?limit=50&offset=`;
        const fetchProducts = async (offset = 0, allProducts = [],isInitial = true) => {
            try {
                setProductLoading(true);
             
                const response = await fetch(`${apiUrlProducts}${offset}`, {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`, // Include the token in the headers
                        "Content-Type": "application/json",
                        // Add other headers if needed
                    },
                });
                const data = await response.json();

                if (data.results.length === 0 && allProducts.length === 0) {
                    setIsProductListEmpty(true);
                }
            
               
                const updatedProducts = [...allProducts, ...data.results];
                setProducts(updatedProducts);

                if (data.next) {
                // console.log('next data ',data.next);
                    // Extract the offset from the next URL if present
                    const nextOffset = new URL(data.next).searchParams.get("offset");
                    fetchProducts(nextOffset, updatedProducts);
                }else{
                  //  console.log('no next data');
                    setProductLoading(false);
                }
            } catch (error) {
                console.error("Error fetching products:", error);
            }
           
        };

        fetchProducts(true);

        const intervalId = setInterval(() => {
            fetchProducts(false); // Subsequent fetches without loading indicator
        }, 30000); // Poll every 10 seconds

        // Cleanup interval on component unmount
        return () => clearInterval(intervalId);
        }, [token]);

        const handleCheckboxChange = (productId) => {
           // console.log('selected product id ',productId);

        setSelectedProducts((prevSelected) => {
        if (prevSelected.includes(productId)) {
        return prevSelected.filter((id) => id !== productId);
        } else {
        return [...prevSelected, productId];
        }
        });
        };

        const handleSelectAllChange = () => {
            const newSelectAll = !selectAll;
            setSelectAll(newSelectAll);
          
            if (newSelectAll) {
              // Select all products
              const allProductIds = products.map((product) => product.id);
              setSelectedProducts(allProductIds);
            } else {
              // Deselect all products
              setSelectedProducts([]);
            }
          };

          // useEffect to log the updated selectedProducts
            useEffect(() => {
                //console.log('Updated selected products:', selectedProducts);
            }, [selectedProducts]);

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const [creatingError, setCreatingError] = useState(null);

  

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
      };

    const handleRadioChange = (e) => {
        const value = e.target.value;
        setSelectedOption(value);
        if (value === "selectProducts") {
          toggleModal(); // Open the modal
        }
      };

    const onWalletTypeChange = (selectedWalletType) => {
        setSelectedWalletType(selectedWalletType);

        setBranch((prevBranch) => ({
            ...prevBranch,
            sub_wallet_balance: selectedWalletType.value,
        }));
    };

    const onBusinessTypeChange = (selectedBusinessType) => {
        setSelectedBusinessType(selectedBusinessType);
    
        // Find index of selected category
        const businessTypeIndex = specifiedBusinessTypes.findIndex(
            (bType) => Object.keys(bType)[0] === `${selectedBusinessType.label}`
        );
        setSpecBusinessTypeOptions(specifiedBusinessTypes[businessTypeIndex][selectedBusinessType.label]);
    
        setSelectedSpecBusinessType(null);
    
        // Update allFormData directly
        setBranch((prevBranch) => ({
            ...prevBranch,
            specific_type: "", // Assuming you want to clear specific_type
            type: selectedBusinessType.value,
        }));
    };

    const onSpecBusinessTypeChange = (selectedSpecBusinessType) => {
        setSelectedSpecBusinessType(selectedSpecBusinessType);

        setBranch((prevBranch) => ({
            ...prevBranch,
             specific_type: selectedSpecBusinessType.value,
     }));
 };

    // const handleInputChange = (e) => {
    //     const { name, value, type } = e.target;

    //     // If the input is a file, get the file object
    //     const file = type === "file" ? e.target.files[0] : null;

    //     setBranch((prevBranch) => ({
    //         ...prevBranch,
    //         [name]: type === "file" ? file : value,
    //     }));
    // };

    const handleInputChange = (e, name = null) => {
        if (e && e.target) {
            // Handle standard inputs
            const { name, value, type } = e.target;
            const file = type === "file" ? e.target.files[0] : null;
    
            setBranch((prevBranch) => ({
                ...prevBranch,
                [name]: type === "file" ? file : value,
            }));
        } else {
            // Handle PhoneInput, where `e` is the value and `name` is the field name
            setBranch((prevBranch) => ({
                ...prevBranch,
                [name]: e,
            }));
        }
    };
    
 

    const handleFormSubmit = async (event) => {
        event.preventDefault();
       // if (!formValidator()) return;
      // console.log('to submit product', selectedProducts);

        setIsLoading(true);

        const formData = new FormData();
        formData.append("name", branch.sub_name);
        formData.append("contact_person", branch.sub_contact_person);
        formData.append("phone_number",branch.sub_phone_number);
        formData.append("alt_phone_number",branch.sub_alt_phone_number);
        formData.append("email", branch.sub_email);
        formData.append("location", branch.sub_location);
        formData.append("type", branch.type);
        formData.append("specific_type", branch.specific_type);
        formData.append("password", branch.sub_password);
        formData.append("confirmPassword", branch.sub_password_confirm);
        formData.append("product_ids", selectedProducts);
        if (branch.sub_profile_image) {
            formData.append("profile_image", branch.sub_profile_image);
        }
        if (branch.sub_cover_image) {
            formData.append("cover_image", branch.sub_cover_image);
        }
        let errorMessages;
        try {
            //const apiurl = `${BACKEND_DOMAIN}/business/merchant/branch/create/`;
            const apiUrl=`https://backoffice.route.money/api/v1/business/merchant/branch/create/`;
            const response = await fetch(apiUrl, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: formData,
            });

                 const data = await response.json()

            
                
            if (!response.ok) {
               // const errorMessages = Array.isArray(data.errors) ? data.errors : [data.errors];

               errorMessages=data.errors;

                setErrorMessage(errorMessages);
                setTimeout(() => {
                    setErrorMessage("");
                }, 3000);

                console.log('error occured ',errorMessages);
                setIsLoading(false);
                throw new Error("Error adding a branch",errorMessages);
              //  setErrorMessage("Error adding a branch!",response.errors);
            }

       
            setSuccessMessage("Branch Created successfully!");
            setIsLoading(false);

            setTimeout(() => {
                setSuccessMessage("");
                navigate("/route-money/merchant/branch/list");
            }, 3000);

        } catch (errors) {
            console.error('Error creating branch:',errors);
          
            console.error("Error adding a branch:", errorMessages);

            // Collect all error messages into an array
            const errorArray = Object.keys(errorMessages).map((field) => {
                return `${field}: ${errorMessages[field].join(", ")}`;
            });

            // Join the array into a single string with line breaks or commas
            setErrorMessage(errorArray.join(" , "));
          //  setErrorMessage("Failed, try again!");
            setIsLoading(false);

            setTimeout(() => {
                setErrorMessage("");
            }, 3000);
        }
    };

    // React PhoneInput custom styles
const customInputStyle = {
    width: "100%",
    height: "40px",
    backgroundColor: "inherit",
    border: "1px solid #cacaca",
    marginTop: "50px",
};

const customDropdownStyle = {
    backgroundColor: "inherit",
    borderRadius: 0,
    boxShadow: "none",
    border: "1px solid transparent",
    color: "inherit",
    height: "fit-content",
    marginTop: 0,
    marginLeft: "-2px",
};


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        title="Add Branch"
                        breadcrumbItems={[
                            { title: "Branches", link: "/branch" },
                            { title: "Add Branch", link: "#" },
                        ]}
                    />

                    <Row>
                        <Col xl="12">
                            <Card>
                                <CardBody>
                                    <h4 className="card-title">Add Branch</h4>
                                    {successMessage && <Alert color="success">{successMessage}</Alert>}
                                    
                                   {errorMessage && <Alert color="danger">{errorMessage}</Alert>}

                                    <AvForm onValidSubmit={handleFormSubmit} enctype="multipart/form-data">
                                        <Row>
                                            <Col md="6">
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="subName">
                                                        Branch Name
                                                    </Label>
                                                    <AvField
                                                        name="sub_name"
                                                        id="subName"
                                                        type="text"
                                                        placeholder="Enter Branch Name"
                                                        errorMessage="Enter Branch Name"
                                                        className="form-control"
                                                        onChange={handleInputChange}
                                                        validate={{ required: { value: true } }}
                                                    />
                                                </div>
                                            </Col>

                                            <Col md="6">
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="subLocation">
                                                        Location
                                                    </Label>
                                                    <AvField
                                                        name="sub_location"
                                                        id="subLocation"
                                                        type="text"
                                                        placeholder="Enter Location"
                                                        errorMessage="Enter Location"
                                                        className="form-control"
                                                        onChange={handleInputChange}
                                                        validate={{ required: { value: true } }}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="6">
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="subContactPerson">
                                                        Contact Person
                                                    </Label>
                                                    <AvField
                                                        name="sub_contact_person"
                                                        id="subContactPerson"
                                                        type="text"
                                                        placeholder="Enter Contact Person"
                                                        errorMessage="Enter Contact person"
                                                        className="form-control"
                                                        onChange={handleInputChange}
                                                        validate={{ required: { value: true } }}
                                                    />
                                                </div>
                                            </Col>

                                            <Col md="6">
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="subEmail">
                                                        Email
                                                    </Label>
                                                    <AvField
                                                        name="sub_email" // Updated to match the API field name
                                                        id="subEmail"
                                                        type="email"
                                                        placeholder="Enter Email"
                                                        errorMessage="Enter Email"
                                                        className="form-control"
                                                        onChange={handleInputChange}
                                                        validate={{ required: { value: true } }}
                                                    />
                                                </div>
                                            </Col>
                                          
                                        </Row>
                                
                                        <Row>
                                        <Col lg="6">
                                            <div className="mb-3">
                                                <Label>
                                                    General Business Type{" "}
                                                    <span className="text-danger">*</span>
                                                </Label>

                                                <Select
                                                    placeholder="Select or Type to filter..."
                                                    options={businessTypes}
                                                    value={selectedBusinessType}
                                                    onChange={onBusinessTypeChange}
                                                    className={`form-control`}
                                                 
                                                />
                                                  
                                            </div>
                                        </Col>

                                        <Col lg="6">
                                        <div className="mb-3">
                                            <Label>
                                                Specific Business Type{" "}
                                                <span className="text-danger">*</span>
                                            </Label>

                                            <Select
                                                placeholder="Select or Type to filter..."
                                                options={
                                                    !selectedBusinessType
                                                        ? [
                                                                {
                                                                    value: "",
                                                                    label: "Select General Type First",
                                                                },
                                                            ]
                                                        : specBusinessTypeOptions
                                                }
                                                value={selectedSpecBusinessType}
                                                onChange={onSpecBusinessTypeChange}
                                                className={`form-control`}
                                               
                                            />
                                               
                                        </div>
                                    </Col>
                                        </Row>
                                        <Row>
                                            <Col md="6">
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="subProfileImage">
                                                        Branch Profile Image
                                                    </Label>
                                                    <AvField
                                                        name="sub_profile_image"
                                                        id="sub_profile_image"
                                                        type="file"
                                                        errorMessage="Select Profile Image"
                                                        className="form-control"
                                                        onChange={handleInputChange}
                                                        validate={{ required: { value: true } }}
                                                    />

                        
                                                </div>
                                            </Col>

                                                                                        <Col md="6">
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="subProfileImage">
                                                        Branch Cover Page
                                                    </Label>
                                                    <AvField
                                                        name="sub_cover_image"
                                                        id="sub_cover_image"
                                                        type="file"
                                                        errorMessage="Select Cover Image"
                                                        className="form-control"
                                                        onChange={handleFileChange}
                                                        validate={{ required: { value: true } }}
                                                    />
                                                </div>
                                            </Col>

                                           
                                        </Row>
                                        <Row>
                                            <Col md="6">
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="phoneNumber">
                                                        Phone Number
                                                    </Label>
                                                    {/* <AvField
                                                        name="sub_phone_number"
                                                        id="phoneNumber"
                                                        type="text"
                                                        placeholder="Enter Phone Number"
                                                        errorMessage="Enter Phone Number"
                                                        className="form-control"
                                                        onChange={handleInputChange}
                                                        validate={{ required: { value: true } }}
                                                    /> */}

                                                           {/* <PhoneInput
                                                                    name="sub_phone_number"
                                                                    id="phoneNumber"
                                                                    inputStyle={customInputStyle}
                                                                    dropdownStyle={customDropdownStyle}
                                                                    country={"ke"}
                                                                    onlyCountries={["ke", "ug", "tz", "rw"]}
                                                                    // validate={{ required: { value: true } }}
                                                                    value={'sub_phone_number'}
                                                                    onChange={(value) => handleInputChange(value, "sub_phone_number")}
                                                                    inputClass={`form-control`}
                                                                    dropdownClass="form-control"
                                                                /> */}

                                                <PhoneInput
                                                            name="sub_phone_number"
                                                            id="phoneNumber"
                                                            inputStyle={customInputStyle}
                                                            dropdownStyle={customDropdownStyle}
                                                            country={"ke"}
                                                            onlyCountries={["ke", "ug", "tz", "rw"]}
                                                            value={branch.sub_phone_number.startsWith('+') ? branch.sub_phone_number : `+${branch.sub_phone_number}`}
                                                            onChange={(value) => handleInputChange(value.startsWith('+') ? value : `+${value}`, "sub_phone_number")}
                                                            inputClass={`form-control`}
                                                            dropdownClass="form-control"
                                                        />


                                                </div> 
                                            </Col>
                                            <Col md="6">
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="altPhoneNumber">
                                                        Alternative Phone Number
                                                    </Label>
                                                    {/* <AvField
                                                        name="sub_alt_phone_number"
                                                        id="altPhoneNumber"
                                                        type="text"
                                                        placeholder="Enter Alternative Phone Number"
                                                        errorMessage="Enter Alternative Phone Number"
                                                        className="form-control"
                                                        onChange={handleInputChange}
                                                        validate={{ required: { value: true } }}
                                                    /> */}


                                    <PhoneInput
                                                name="sub_alt_phone_number"
                                                id="AltphoneNumber"
                                                inputStyle={customInputStyle}
                                                dropdownStyle={customDropdownStyle}
                                                country={"ke"}
                                                onlyCountries={["ke", "ug", "tz", "rw"]}
                                                value={branch.sub_alt_phone_number.startsWith('+') ? branch.sub_alt_phone_number : `+${branch.sub_alt_phone_number}`}
                                                onChange={(value) => handleInputChange(value.startsWith('+') ? value : `+${value}`, "sub_alt_phone_number")}
                                                inputClass={`form-control`}
                                                dropdownClass="form-control"
                                            />


                                                </div>
                                            </Col>
                                        </Row>

                                        <Row>
                                        <Col md="6">
                                                <Label className="form-label" htmlFor="productSelection">
                                                    Product Selection
                                                </Label>
                                                <div>
                                                    <input
                                                    type="radio"
                                                    id="none"
                                                    name="productSelection"
                                                    value="none"
                                                    onChange={handleRadioChange}
                                                    />
                                                    <Label htmlFor="none" className="ml-2">None</Label>
                                                </div>
                                             
                                                <div>
                                                    <input
                                                    type="radio"
                                                    id="selectProducts"
                                                    name="productSelection"
                                                    value="selectProducts"
                                                    onChange={handleRadioChange}
                                                    />
                                                    <Label htmlFor="selectProducts">Select Products</Label>
                                                </div>
                                                </Col>
                                        </Row>

                                        <Row>

                                            <Col md="6">
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="subPassword">
                                                        Password
                                                    </Label>
                                                    <AvField
                                                        name="sub_password"
                                                        id="subPassword"
                                                        type={passwordVisible ? "text" : "password"}
                                                        placeholder="Enter Password"
                                                        errorMessage="Enter Password"
                                                        className="form-control"
                                                        onChange={handleInputChange}
                                                        validate={{ required: { value: true } }}
                                                    />
                                                <div className="d-flex justify-content-end mt-1">
                                                    <small
                                                        className="text-muted cursor-pointer"
                                                        onClick={togglePasswordVisibility}
                                                    >
                                                        {passwordVisible ? "Hide" : "Show"}
                                                    </small>
                                                </div>
                                                </div>
                                            </Col>

                                            <Col md="6">
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="subEmail">
                                                        Confirm Password
                                                    </Label>
                                                    <AvField
                                                        name="sub_password_confirm" // Updated to match the API field name
                                                        id="subPasswordConfirm"
                                                        type={passwordVisible ? "text" : "password"}
                                                        placeholder="Confirm Password"
                                                        errorMessage="Confirm password"
                                                        className="form-control"
                                                        onChange={handleInputChange}
                                                        validate={{ required: { value: true } }}
                                                    />

                                                <div className="d-flex justify-content-end mt-1">
                                                    <small
                                                        className="text-muted cursor-pointer"
                                                        onClick={togglePasswordVisibility}
                                                    >
                                                        {passwordVisible ? "Hide" : "Show"}
                                                    </small>
                                                </div>
                                                </div>
                                            </Col>

                                        </Row>
                                        {creatingError && <Alert color="danger">{creatingError}</Alert>}
                                        <Button color="primary" type="submit" disabled={isLoading}>
                                            {isLoading ? (
                                                <>
                                                    <span
                                                        className="spinner-border spinner-border-sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    ></span>
                                                    <span className="ms-2">Adding..</span>
                                                </>
                                            ) : (
                                                "Add Branch"
                                            )}
                                        </Button>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                {/* product selection modal */}

                <Modal isOpen={isModalOpen} toggle={toggleModal}>
                <ModalHeader toggle={toggleModal}>Select Products</ModalHeader>
                <ModalBody>
                    {/* Select All Checkbox */}
                    <div className="ml-3 mb-3">
                    <input
                        type="checkbox"
                        checked={selectAll}
                        onChange={handleSelectAllChange}
                    />
                    <Label>Select All</Label>
                    </div>

                    {/* Custom scrollable container */}
                    <div className="scroll-container">
                    <div className="product-grid">
                        {products.map((product) => (
                        <div className="product-item" key={product.id}>
                            <input
                            type="checkbox"
                            checked={selectedProducts.includes(product.id)}
                            onChange={() => handleCheckboxChange(product.id)}
                            />
                            <Label>{product.name}</Label>
                        </div>
                        ))}
                    </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    {productLoading?(
                        <>
                          <Spinner size="sm" color="warning" />
                          <span className="text-warning">products loading ...</span>
                        </>
              
                    ):(
                        <Button color="primary" onClick={toggleModal}>
                        Save
                        </Button>
                    )}
                 
                </ModalFooter>
                </Modal>


                <Modal isOpen={modalOpen} toggle={() => setModalOpen(!modalOpen)}>
                <ModalHeader toggle={() => setModalOpen(!modalOpen)}>
                    Crop {selectedImageType === "sub_profile_image" ? "Profile" : "Cover"} Image
                </ModalHeader>
                <ModalBody>
                    {imgSrc && (
                        <Row>
                            <Col md="6">
                                <ReactCrop
                                    crop={crop}
                                    onChange={(newCrop) => setCrop(newCrop)}
                                    onComplete={(newCrop) => setCompletedCrop(newCrop)}
                                    aspect={aspect}
                                >
                                    <img
                                        ref={imgRef}
                                        alt="Crop me"
                                        src={imgSrc}
                                        onLoad={() => {
                                            setCrop({ unit: "%", width: 50, aspect });
                                        }}
                                        style={{ maxWidth: "100%", maxHeight: "400px" }}
                                    />
                                </ReactCrop>
                            </Col>
                            <Col md="6">
                                {completedCrop && (
                                    <canvas
                                        ref={previewCanvasRef}
                                        style={{
                                            border: "1px solid black",
                                            width: "100%",
                                            maxHeight: "400px",
                                        }}
                                    />
                                )}
                            </Col>
                        </Row>
                    )}
                </ModalBody>
                <div className="modal-footer">
                    <Button color="secondary" onClick={() => setModalOpen(false)}>
                        Cancel
                    </Button>
                    {!cropping?(
                    <Button color={isLoading ? `secondary`:`primary`} onClick={handleSaveCrop} disabled={isLoading}>
                    {isLoading ? 'Saving ...' : 'Save'}
                    </Button>
                    ):(
                        <Button color={`secondary`} disabled={cropping}>
                        Saving ...
                        </Button>
                    )}
                   
                </div>
            </Modal>

            </div>
        </React.Fragment>
    );
};

export default MerchantBranchAdd;