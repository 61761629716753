import react,{useState, useeffect} from 'react';
import axios from "axios";



export const fetchTable = async (token, apiUrl) => {

    try {
        const response = await axios.get(apiUrl, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });

        // Handle the different response formats
          if (response.data.data) {
            return response.data.data;
        } else {
            throw new Error("Unexpected response format");
        }
    } catch (error) {
        console.error("Error fetching users: ", error);
        throw error;
    }
};
