export const formatNumber = (num) => {
    if (num >= 1000000) {
        return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M'; // Convert to millions
    }
    if (num >= 1000) {
        return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'k'; // Convert to thousands
    }
    // if (num < 100) {
    //     return num.toFixed(2); // Format numbers less than 100 with two decimal places
    // }
    return num.toString(); // If between 100 and 999, return as is
};
