import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, CardBody, Label, Button, Input, Alert } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { uploadCashbackRequest, uploadCashbackSuccess, uploadCashbackFailure } from "../../thunks";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import BACKEND_DOMAIN from "../../../../service";

const MerchantCashbackOfferAdd = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [startTime, setStartTime] = useState(new Date());
    const [endTime, setEndTime] = useState(new Date());

    const [offer, setOffer] = useState({
        name: "",
        description: "",
        percentage: "",
        instructions: [],
        start_date: "",
        end_date: "",
        start_time: "",
        end_time: "",
    });

    const [productsList, setProductsList] = useState([]);
    const [successMessage, setSuccessMessage] = useState("");

    const token = useSelector((state) => state.Login.token);

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (token) {
            const apiUrl = `${BACKEND_DOMAIN}/business/merchant/products/list`;
            fetch(apiUrl, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then((response) => response.json())
                .then((data) => setProductsList(data.results))
                .catch((error) => console.error("Error fetching products:", error));
        }
    }, [token]);

    /* const handleInputChange = (e) => {
      const { name, value } = e.target;

      // If the input is the discount type dropdown, store the value
      const discountType = name === "discountType" ? value : offer.discountType;

      setOffer({
        ...offer,
        [name]: value,
        discountType,
      });
    }; */
    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name === "instructions") {
            // Split instructions into an array by line
            const instructionsArray = value.split("\n");

            setOffer({
                ...offer,
                instructions: instructionsArray,
            });
        } else {
            setOffer({
                ...offer,
                [name]: value,
            });
        }
    };

    const handleMultiSelectChange = (products) => {
        setOffer({
            ...offer,
            products,
        });
    };
    const handleStartDateChange = (date) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
    };

    const handleStartTimeChange = (time) => {
        setStartTime(time);
        setOffer({
            ...offer,
            start_time: time.toLocaleTimeString(),
        });
    };

    const handleEndTimeChange = (time) => {
        setEndTime(time);
        setOffer({
            ...offer,
            end_time: time.toLocaleTimeString(),
        });
    };

    const handleFormSubmit = async (event) => {
        event.preventDefault();

        setIsLoading(true);

        try {
            dispatch(uploadCashbackRequest());

            const formData = new FormData();
            formData.append("name", offer.name);
            formData.append("instructions", offer.instructions.join("\n"));
            formData.append("percentage", offer.percentage);
            formData.append("description", offer.description);
            formData.append("start_date", startDate.toISOString().slice(0, 10));
            formData.append("end_date", endDate.toISOString().slice(0, 10));
            formData.append("start_time", offer.start_time);
            formData.append("end_time", offer.end_time);

            //const productIds = offer.products.map(product => product.value);
            //formData.append("products", JSON.stringify(productIds));

            const apiurl = `${BACKEND_DOMAIN}/business/merchant/cashback/add`;
            const response = await fetch(apiurl, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: formData,
            });

            if (!response.ok) {
                throw new Error("Error adding product");
            }

            const data = await response.json();
            dispatch(uploadCashbackSuccess(data));

            // Show a success message:
            setSuccessMessage("Cashback offer has been created successfully!");

            setOffer({
                name: "",
                instructions: [],
                // image: "",
                percentage: "",
                description: "",
                //offer_value_type: "",
                //products: [],
                start_date: "",
                end_date: "",
                start_time: "",
                end_time: "",
            });

            setTimeout(() => {
                navigate("/route-money/merchant/cashback-offers");
            }, 2000);

            setTimeout(() => {
                setSuccessMessage("");
            }, 5000);
        } catch (error) {
            dispatch(uploadCashbackFailure(error.message));
        }

        setIsLoading(false);
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        title="Create Cashback Offer"
                        breadcrumbItems={[
                            { title: "Offers", link: "/offers" },
                            { title: "Create Offer", link: "#" },
                        ]}
                    />
                    <Row>
                        <Col xl="12">
                            <Card>
                                <CardBody>
                                    <h4 className="card-title">Create Offer</h4>
                                    {successMessage && <Alert color="success">{successMessage}</Alert>}
                                    <AvForm onValidSubmit={handleFormSubmit}>
                                        <Row>
                                            <Col md="6">
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="offerName">
                                                        Offer Name
                                                    </Label>
                                                    <AvField
                                                        name="name"
                                                        id="offerName"
                                                        type="text"
                                                        placeholder="Enter Offer Name"
                                                        errorMessage="Enter Offer Name"
                                                        className="form-control"
                                                        onChange={handleInputChange}
                                                        validate={{ required: { value: true } }}
                                                    />
                                                </div>
                                            </Col>

                                            <Col md="6">
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="offerDescription">
                                                        Description
                                                    </Label>
                                                    <AvField
                                                        name="description"
                                                        id="offerDescription"
                                                        type="text"
                                                        placeholder="Enter Offer Description"
                                                        errorMessage="Enter Offer Description"
                                                        className="form-control"
                                                        onChange={handleInputChange}
                                                        validate={{ required: { value: true } }}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="6">
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="productDiscount">
                                                        Percentage
                                                    </Label>
                                                    <AvField
                                                        name="percentage"
                                                        id="productDiscount"
                                                        type="number"
                                                        step="0.01"
                                                        placeholder="Enter Discount"
                                                        errorMessage="Enter Discount"
                                                        className="form-control"
                                                        onChange={handleInputChange}
                                                        validate={{ required: { value: true } }}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="6">
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="instructions">
                                                        Instructions
                                                    </Label>
                                                    <textarea
                                                        id="instructions"
                                                        name="instructions"
                                                        className="form-control"
                                                        rows="3"
                                                        placeholder="Enter instructions (one per line)"
                                                        onChange={handleInputChange}
                                                        value={offer.instructions.join("\n")} // Join array for displaying
                                                    ></textarea>
                                                </div>
                                            </Col>

                                            <Col md="6">
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="startOfferTime">
                                                        Start Time
                                                    </Label>
                                                    <Input
                                                        type="time"
                                                        id="startOfferTime"
                                                        name="start_time"
                                                        className="form-control"
                                                        onChange={(e) =>
                                                            handleStartTimeChange(
                                                                new Date(`2000-01-01T${e.target.value}:00`)
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="6">
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="endOfferTime">
                                                        End Time
                                                    </Label>
                                                    <Input
                                                        type="time"
                                                        id="endOfferTime"
                                                        name="end_time"
                                                        className="form-control"
                                                        onChange={(e) =>
                                                            handleEndTimeChange(
                                                                new Date(`2000-01-01T${e.target.value}:00`)
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="6">
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="startDate">
                                                        Start Date
                                                    </Label>
                                                    <DatePicker
                                                        selected={startDate}
                                                        onChange={handleStartDateChange}
                                                        className="form-control"
                                                        id="startDate"
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="6">
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="endDate">
                                                        End Date
                                                    </Label>
                                                    <DatePicker
                                                        selected={endDate}
                                                        onChange={handleEndDateChange}
                                                        className="form-control"
                                                        id="endDate"
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Button color="primary" type="submit" disabled={isLoading}>
                                            {isLoading ? (
                                                <>
                                                    <span
                                                        className="spinner-border spinner-border-sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    ></span>
                                                    <span className="ms-2">Creating..</span>
                                                </>
                                            ) : (
                                                "Create Offer"
                                            )}
                                        </Button>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default MerchantCashbackOfferAdd;
