export const firebaseConfig = {
    apiKey: "AIzaSyCly808ziD0Ks4BElAJjsXbz2A3GvQB0co",
    authDomain: "route-976d6.firebaseapp.com",
    databaseURL: "https://route-976d6.firebaseio.com",
    projectId: "route-976d6",
    storageBucket: "route-976d6.appspot.com",
    messagingSenderId: "592250773138",
    appId: "1:592250773138:web:b8c8edfa9578461444504b",
    measurementId: "G-ZR29CN474E",
};
