import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, CardBody, Label, Button, Alert } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { uploadCategoryRequest, uploadCategorySuccess, uploadCategoryFailure } from "../../thunks";
import BACKEND_DOMAIN from "../../../../service";

const MerchantCategoryEdit = () => {
    const { categoryId } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [category, setCategory] = useState({
        name: "",
        image: null,
    });

    const [successMessage, setSuccessMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const token = useSelector((state) => state.Login.token);

    useEffect(() => {
        if (token && categoryId) {
            const apiUrl = `${BACKEND_DOMAIN}/business/merchant/category/${categoryId}`;
            fetch(apiUrl, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then((response) => response.json())
                .then((data) => {
                    const { name } = data;
                    // Update the state once the data is fetched
                    setCategory({
                        name: name,
                        image: null, // Set to null or an appropriate default value
                    });
                })
                .catch((error) => console.error("Error fetching category details:", error));
        }
    }, [token, categoryId]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setCategory((prevCategory) => ({
            ...prevCategory,
            [name]: value,
            image: name === "image" ? e.target.files[0] : prevCategory.image,
        }));
    };

    const handleFormSubmit = async (event) => {
        event.preventDefault();

        setIsLoading(true);

        try {
            dispatch(uploadCategoryRequest());

            const formData = new FormData();
            formData.append("name", category.name);

            formData.append("image", category.image);

            const apiurl = `${BACKEND_DOMAIN}/business/merchant/category/${categoryId}/update/`;
            const response = await fetch(apiurl, {
                method: "PUT",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: formData,
            });

            if (!response.ok) {
                throw new Error("Error editing category");
            }

            const data = await response.json();
            dispatch(uploadCategorySuccess(data));

            setSuccessMessage("Category has been updated Successfully!");

            setTimeout(() => {
                navigate("/route-money/merchant/product/categories");
            }, 2000);

            setTimeout(() => {
                setSuccessMessage("");
            }, 5000);
        } catch (error) {
            dispatch(uploadCategoryFailure(error.message));
        }

        setIsLoading(false);
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        title="Edit Category"
                        breadcrumbItems={[
                            { title: "Category", link: "/categories" },
                            { title: "Edit Category", link: "#" },
                        ]}
                    />

                    <Row>
                        <Col xl="12">
                            <Card>
                                <CardBody>
                                    <h4 className="card-title">Edit Category</h4>
                                    {successMessage && <Alert color="success">{successMessage}</Alert>}
                                    <AvForm onValidSubmit={handleFormSubmit} enctype="multipart/form-data">
                                        <Row>
                                            <Col md="6">
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="categoryName">
                                                        Category Name
                                                    </Label>
                                                    <AvField
                                                        name="name"
                                                        id="categoryName"
                                                        type="text"
                                                        placeholder="Enter Category Name"
                                                        errorMessage="Enter Category Name"
                                                        className="form-control"
                                                        onChange={handleInputChange}
                                                        validate={{ required: { value: true } }}
                                                        value={category.name}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="6">
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="categoryImage">
                                                        Category Image
                                                    </Label>
                                                    <AvField
                                                        name="image"
                                                        id="categoryImage"
                                                        type="file"
                                                        errorMessage="Select Category Image"
                                                        className="form-control"
                                                        onChange={handleInputChange}
                                                        validate={{ required: { value: true } }}
                                                        //value={product.image}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>

                                        <Button color="primary" type="submit" disabled={isLoading}>
                                            {isLoading ? (
                                                <>
                                                    <span
                                                        className="spinner-border spinner-border-sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    ></span>
                                                    <span className="ms-2">Updating..</span>
                                                </>
                                            ) : (
                                                "Update Category"
                                            )}
                                        </Button>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default MerchantCategoryEdit;
