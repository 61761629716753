export const storeEmailInSession = (email) => {
    sessionStorage.setItem("resetEmail", email);
};

export const getEmailFromSession = () => {
    return sessionStorage.getItem("resetEmail");
};

export const clearEmailFromSession = () => {
    sessionStorage.removeItem("resetEmail");
};
