import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, CardBody, Label, Button, Input, Alert } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { uploadCashbackRequest, uploadCashbackSuccess, uploadCashbackFailure } from "../../thunks";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import BACKEND_DOMAIN from "../../../../service";

const MerchantCashbackOfferEdit = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { cashbackOfferId } = useParams();
    const token = useSelector((state) => state.Login.token);
    const [isLoading, setIsLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [offer, setOffer] = useState({
        name: "",
        description: "",
        percentage: "",
        instructions: [],
        start_date: "",
        end_date: "",
        start_time: "",
        end_time: "",
    });

    useEffect(() => {
        if (token && cashbackOfferId) {
            const fetchCashbackOfferDetails = async () => {
                try {
                    const response = await axios.get(
                        `${BACKEND_DOMAIN}/business/merchant/cashback/view/${cashbackOfferId}/`,
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        }
                    );
                    const cashbackOfferDetails = response.data;
                    setOffer(cashbackOfferDetails);
                } catch (error) {
                    console.error("Failed to fetch cashback offer details:", error);
                }
            };
            fetchCashbackOfferDetails();
        }
    }, [token, cashbackOfferId]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setOffer((prevOffer) => ({
            ...prevOffer,
            [name]: value,
        }));
    };

    const handleStartTimeChange = (time) => {
        setOffer((prevOffer) => ({
            ...prevOffer,
            start_time: time.toLocaleTimeString(),
        }));
    };

    const handleEndTimeChange = (time) => {
        setOffer((prevOffer) => ({
            ...prevOffer,
            end_time: time.toLocaleTimeString(),
        }));
    };

    const handleStartDateChange = (date) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
    };

    const handleFormSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);

        try {
            dispatch(uploadCashbackRequest());
            const formData = new FormData();
            formData.append("name", offer.name);
            formData.append("instructions", offer.instructions);
            formData.append("percentage", offer.percentage);
            formData.append("description", offer.description);
            formData.append("start_date", startDate.toISOString().slice(0, 10));
            formData.append("end_date", endDate.toISOString().slice(0, 10));
            formData.append("start_time", offer.start_time);
            formData.append("end_time", offer.end_time);

            const response = await axios.put(
                `${BACKEND_DOMAIN}/business/merchant/cashback/update/${cashbackOfferId}/`,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            dispatch(uploadCashbackSuccess(response.data));
            setSuccessMessage("Cashback offer has been updated successfully!");

            setTimeout(() => {
                navigate("/route-money/merchant/cashback-offers");
            }, 2000);

            setTimeout(() => {
                setSuccessMessage("");
            }, 5000);
        } catch (error) {
            console.error("Error updating cashback offer:", error);
            dispatch(uploadCashbackFailure(error.message));
        }

        setIsLoading(false);
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        title="Edit Cashback Offer"
                        breadcrumbItems={[
                            { title: "Offers", link: "/offers" },
                            { title: "Edit Offer", link: "#" },
                        ]}
                    />
                    <Row>
                        <Col xl="12">
                            <Card>
                                <CardBody>
                                    <h4 className="card-title">Edit Offer</h4>
                                    {successMessage && <Alert color="success">{successMessage}</Alert>}
                                    <AvForm onValidSubmit={handleFormSubmit}>
                                        <Row>
                                            <Col md="6">
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="offerName">
                                                        Offer Name
                                                    </Label>
                                                    <AvField
                                                        name="name"
                                                        id="offerName"
                                                        value={offer.name}
                                                        type="text"
                                                        placeholder="Enter Offer Name"
                                                        errorMessage="Enter Offer Name"
                                                        className="form-control"
                                                        onChange={handleInputChange}
                                                        validate={{ required: { value: true } }}
                                                    />
                                                </div>
                                            </Col>

                                            <Col md="6">
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="offerDescription">
                                                        Description
                                                    </Label>
                                                    <AvField
                                                        name="description"
                                                        id="offerDescription"
                                                        value={offer.description}
                                                        type="text"
                                                        placeholder="Enter Offer Description"
                                                        errorMessage="Enter Offer Description"
                                                        className="form-control"
                                                        onChange={handleInputChange}
                                                        validate={{ required: { value: true } }}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="6">
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="productDiscount">
                                                        Percentage
                                                    </Label>
                                                    <AvField
                                                        name="percentage"
                                                        id="productDiscount"
                                                        type="number"
                                                        step="0.01"
                                                        placeholder="Enter Discount"
                                                        errorMessage="Enter Discount"
                                                        className="form-control"
                                                        value={offer.percentage}
                                                        onChange={handleInputChange}
                                                        validate={{ required: { value: true } }}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="6">
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="instructions">
                                                        Instructions
                                                    </Label>
                                                    <textarea
                                                        id="instructions"
                                                        name="instructions"
                                                        className="form-control"
                                                        rows="3"
                                                        placeholder="Enter instructions (one per line)"
                                                        value={
                                                            Array.isArray(offer.instructions)
                                                                ? offer.instructions.join("\n")
                                                                : offer.instructions
                                                        }
                                                        onChange={handleInputChange}
                                                    ></textarea>
                                                </div>
                                            </Col>

                                            <Col md="6">
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="startOfferTime">
                                                        Start Time
                                                    </Label>
                                                    <Input
                                                        type="time"
                                                        id="startOfferTime"
                                                        name="start_time"
                                                        className="form-control"
                                                        onChange={(e) =>
                                                            handleStartTimeChange(
                                                                new Date(`2000-01-01T${e.target.value}:00`)
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="6">
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="endOfferTime">
                                                        End Time
                                                    </Label>
                                                    <Input
                                                        type="time"
                                                        id="endOfferTime"
                                                        name="end_time"
                                                        className="form-control"
                                                        onChange={(e) =>
                                                            handleEndTimeChange(
                                                                new Date(`2000-01-01T${e.target.value}:00`)
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="6">
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="startDate">
                                                        Start Date
                                                    </Label>
                                                    <DatePicker
                                                        selected={startDate}
                                                        onChange={handleStartDateChange}
                                                        className="form-control"
                                                        id="startDate"
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="6">
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="endDate">
                                                        End Date
                                                    </Label>
                                                    <DatePicker
                                                        selected={endDate}
                                                        onChange={handleEndDateChange}
                                                        className="form-control"
                                                        id="endDate"
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Button color="primary" type="submit" disabled={isLoading}>
                                            {isLoading ? (
                                                <>
                                                    <span
                                                        className="spinner-border spinner-border-sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    ></span>
                                                    <span className="ms-2">Updating..</span>
                                                </>
                                            ) : (
                                                "Update Offer"
                                            )}
                                        </Button>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default MerchantCashbackOfferEdit;
