export const businessTypes = [
    { value: "vacation_rental", label: "Airbnb and Vacation Homes" },
    { value: "property_rental_agency", label: "Apartments & Houses" },
    { value: "bakery", label: "Bakery" },
    { value: "barbershop", label: "Barbershop" },
    { value: "bed_and_breakfast", label: "Bed & Breakfast Establishment" },
    { value: "clothing_store", label: "Boutiques & Apparel Chains" },
    { value: "cafe", label: "Cafe" },
    { value: "car_rental_service", label: "Car Rental Service" },
    { value: "catering_services", label: "Catering Services" },
    { value: "cinema", label: "Cinema" },
    { value: "concert_venue", label: "Concert Venue" },
    { value: "equipment_rental", label: "Construction Equipment & Party Supplies" },
    { value: "convenient_store", label: "Convenient Store" },
    { value: "cosmetics_and_beauty_store", label: "Cosmetics & Beauty Store (all beauty items)" },
    { value: "electronics_store", label: "Electronics Store" },
    { value: "event_planning", label: "Event Planning" },
    { value: "event_space_rental", label: "Event Space Rental" },
    { value: "fitness_center", label: "Fitness Center" },
    { value: "furniture_rental", label: "Furniture Rental" },
    { value: "gaming_arcade", label: "Gaming Arcade" },
    { value: "guesthouse", label: "Guesthouse" },
    { value: "home_goods_store", label: "Home Goods Store" },
    { value: "hostel", label: "Hostel" },
    { value: "hotel", label: "Hotel" },
    { value: "juice_bar", label: "Juice Bar" },
    { value: "lodge", label: "Lodge" },
    { value: "medical_clinic", label: "Medical Clinic" },
    { value: "minimart", label: "Minimart" },
    { value: "office_space_rental", label: "Office Space Rental" },
    { value: "pharmacy", label: "Pharmacy" },
    { value: "restaurant", label: "Restaurant" },
    { value: "spa_and_salon", label: "Spa & Salon" },
    { value: "specialty_store", label: "Sports Equipment & Books" },
    { value: "sports_club", label: "Sports Club" },
    { value: "street_food_vendor", label: "Street Food Vendor" },
    { value: "supermarket", label: "Supermarket" },
    { value: "ticketing", label: "Ticketing" },
    { value: "tour_operator", label: "Tour Operator" },
    { value: "travel_agency", label: "Travel Agency" },
    { value: "wellness_center", label: "Wellness Center" },
    { value: "wines_and_spirits", label: "Wines & Spirits" },
];

const generalBusinessTypes = [
    { value: "food_and_breakfast", label: "Food & Beverage" },
    { value: "health_and_beauty", label: "Health & Beauty" },
    { value: "retail", label: "Retail" },
    { value: "entertainment_and_recreation", label: "Entertainment & Recreation" },
    { value: "rent", label: "Rent" },
    { value: "travel_and_hospitality", label: "Travel & Hospitality" },
];

export const specifiedBusinessTypes = [
    {
        "Food & Beverage": [
            { value: "restaurant_fine_dining", label: "Restaurant (fine dining, casual dining, fast food)" },
            { value: "cafe", label: "Cafe" },
            { value: "bakery", label: "Bakery" },
            { value: "wines_spirits_juice_bar", label: "Wines & Spirits/Juice bar" },
            { value: "catering_services", label: "Catering services" },
            { value: "street_food_vendor", label: "Street food vendor" },
        ],
    },
    {
        Retail: [
            { value: "clothing_store_boutiques_apparel_chains", label: "Clothing store (boutiques, apparel chains)" },
            { value: "minimart_convenient_stores_supermarkets", label: "Minimart/Convenient Stores/Supermarkets" },
            { value: "electronics_store", label: "Electronics store" },
            { value: "home_goods_store", label: "Home goods store" },
            { value: "cosmetics_beauty_store", label: "Cosmetics and beauty store" },
            {
                value: "specialty_store_sports_equipment_books_etc",
                label: "Specialty store (sports equipment, books, etc.)",
            },
        ],
    },
    {
        "Health & Beauty": [
            { value: "spa_salon", label: "Spa & salon" },
            { value: "barbershop", label: "Barbershop" },
            { value: "pharmacy", label: "Pharmacy" },
            { value: "wellness_center", label: "Wellness center" },
            { value: "beauty_product_retailer", label: "Beauty product retailer" },
            { value: "medical_clinic", label: "Medical clinic" },
            { value: "fitness_center", label: "Fitness center" },
        ],
    },
    {
        "Entertainment & Recreation": [
            { value: "ticketing", label: "Ticketing" },
            { value: "cinemas", label: "Cinemas" },
            { value: "gaming_arcade", label: "Gaming arcade" },
            { value: "amusement_park", label: "Amusement park" },
            { value: "sports_club", label: "Sports club" },
            { value: "concert_venue", label: "Concert venue" },
            { value: "event_planning", label: "Event planning" },
        ],
    },
    {
        Rent: [
            { value: "car_rental_service", label: "Car rental service" },
            { value: "property_rental_agency_apartments_houses", label: "Property rental agency (apartments, houses)" },
            {
                value: "equipment_rental_construction_equipment_party_supplies",
                label: "Equipment rental (construction equipment, party supplies)",
            },
            { value: "furniture_rental", label: "Furniture rental" },
            { value: "office_space_rental", label: "Office space rental" },
            { value: "event_space_rental", label: "Event space rental" },
            {
                value: "vacation_rental_properties_airbnb_vacation_homes",
                label: "Vacation rental properties (Airbnb, vacation homes)",
            },
        ],
    },
    {
        "Travel & Hospitality": [
            { value: "hotel", label: "Hotel" },
            { value: "guest_house", label: "Guest House" },
            { value: "hostel", label: "Hostel" },
            { value: "lodge", label: "Lodge" },
            { value: "tour_operator", label: "Tour operator" },
            { value: "travel_agency", label: "Travel agency" },
            { value: "bed_and_breakfast_establishment", label: "Bed and breakfast establishment" },
        ],
    },
];

export default generalBusinessTypes;
