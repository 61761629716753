import BACKEND_DOMAIN from "../../service";

const API_URL = `${BACKEND_DOMAIN}/business/offer/create`;
export const uploadOfferRequest = () => ({
    type: "UPLOAD_OFFER_REQUEST",
});

export const uploadOfferSuccess = (data) => ({
    type: "UPLOAD_OFFER_SUCCESS",
    payload: data,
});

export const uploadOfferFailure = (error) => ({
    type: "UPLOAD_OFFER_FAILURE",
    payload: error,
});

export const uploadOffer = (offerData, token) => async (dispatch) => {
    try {
        dispatch(uploadOfferRequest());
        const formData = new FormData();
        formData.append("name", offerData.name);
        formData.append("code", offerData.offer_code);
        formData.append("discount", offerData.offer_charge);
        formData.append("discountType", offerData.offer_value_type);
        formData.append("description", offerData.description);

        //formData.append("merchant_id", offerData.merchant_id);
        const response = await fetch(API_URL, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: formData,
        });

        if (!response.ok) {
            throw new Error("Error adding product");
        }

        const data = await response.json();
        dispatch(uploadOfferSuccess(data));
    } catch (error) {
        dispatch(uploadOfferFailure(error.message));
    }
};

const CASHBACK_API_URL = `${BACKEND_DOMAIN}/business/merchant/cashback/add`;
export const uploadCashbackRequest = () => ({
    type: "UPLOAD_CASHBACK_REQUEST",
});

export const uploadCashbackSuccess = (data) => ({
    type: "UPLOAD_CASHBACK_SUCCESS",
    payload: data,
});

export const uploadCashbackFailure = (error) => ({
    type: "UPLOAD_CASHBACK_FAILURE",
    payload: error,
});

export const uploadCashback = (offerData, token) => async (dispatch) => {
    try {
        dispatch(uploadOfferRequest());
        const formData = new FormData();
        formData.append("instructions", offerData.instructions);
        formData.append("percentage", offerData.percentage);
        formData.append("start_time", offerData.start_time);
        formData.append("end_time", offerData.end_time);
        formData.append("start_date", offerData.start_date);
        formData.append("end_date", offerData.end_date);
        formData.append("description", offerData.description);
        //formData.append("merchant_id", offerData.merchant_id);
        const response = await fetch(CASHBACK_API_URL, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: formData,
        });

        if (!response.ok) {
            throw new Error("Error adding cashback offer");
        }

        const data = await response.json();
        dispatch(uploadCashbackSuccess(data));
    } catch (error) {
        dispatch(uploadCashbackFailure(error.message));
    }
};
