import React, { useMemo, useState, useEffect } from "react";
import TableContainer from "../../../../components/Common/TableContainer";

//Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import { Alert, Button, Card, CardBody, Container, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import BACKEND_DOMAIN from "../../../../service";

const MerchantProductOffers = () => {
    const [products, setProducts] = useState([]);
    const [offers, setOffers] = useState([]);

    const authToken = useSelector((state) => state.Login.token); // Retrieve token from Redux store

    const [deleteModal, setDeleteModal] = useState(false);
    const [offerIdToDelete, setOfferIdToDelete] = useState(null);
    const [deleteSuccessMessage, setDeleteSuccessMessage] = useState("");

    const [isOfferListEmpty, setIsOfferListEmpty] = useState(false);

    const toggleDeleteModal = () => setDeleteModal(!deleteModal);

    const deleteOffer = (id) => {
        setOfferIdToDelete(id);
        toggleDeleteModal();
    };

    const confirmDelete = () => {
        if (offerIdToDelete) {
            const offerDeletionAPIurl = `${BACKEND_DOMAIN}/business/offer/delete/${offerIdToDelete}/`;
            fetch(offerDeletionAPIurl, {
                method: "DELETE",
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    "Content-Type": "application/json",
                },
            })
                .then((res) => {
                    if (res.ok) {
                        const updatedOffers = offers.filter((offer) => offer.id !== offerIdToDelete);
                        setOffers(updatedOffers);
                        setDeleteSuccessMessage("Offer has been deleted successfully!");
                        setTimeout(() => {
                            setDeleteSuccessMessage("");
                        }, 3000);
                    } else {
                        console.error("Failed to delete offer");
                    }
                })
                .catch((error) => console.error("Error deleting offer: ", error))
                .finally(() => {
                    setOfferIdToDelete(null);
                    toggleDeleteModal();
                });
        }
    };

    useEffect(() => {
        const apiUrlProducts = `${BACKEND_DOMAIN}/business/merchant/products/list`;
        const apiUrlOffers = `${BACKEND_DOMAIN}/business/offers`;
        const fetchData = () => {
            fetch(apiUrlProducts, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${authToken}`, // Include the token in the headers
                    "Content-Type": "application/json",
                    // Add other headers if needed
                },
            })
                .then((response) => response.json())
                .then((data) => setProducts(data.results))
                .catch((error) => console.error("Error fetching products:", error));

            // Fetch categories
            fetch(apiUrlOffers, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${authToken}`, // Include the token in the headers
                    "Content-Type": "application/json",
                    // Add other headers if needed
                },
            })
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    if (data.length === 0) {
                        setIsOfferListEmpty(true);
                    }
                    setOffers(data);
                })
                .catch((error) => console.error("Error fetching offers:", error));
        };

        fetchData();
    }, [authToken]);

    const columns = useMemo(
        () => [
            {
                Header: "Offer Name",
                accessor: "name",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Offer Code",
                accessor: "offer_code",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Offer Type",
                accessor: "offer_value_type",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Value",
                accessor: "value",
                disableFilters: true,
                filterable: false,
            },

            {
                Header: "Description",
                accessor: "description", // Use the correct key
                disableFilters: true,
                filterable: false,
                // Cell: ({ value }) => categoryMapping[value] || value,
            },
            {
                Header: "Start Date",
                accessor: "start_datetime",
                Cell: ({ cell: { value } }) => {
                    const datePart = value.substring(0, 10);
                    return datePart;
                },
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "End Date",
                accessor: "end_datetime",
                Cell: ({ cell: { value } }) => {
                    const datePart = value.substring(0, 10);
                    return datePart;
                },
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Action",
                accessor: (cellProps) => {
                    const offerId = cellProps.id;

                    return (
                        <React.Fragment>
                            <Link
                                to={`/route-money/merchant/product-offer/edit/${offerId}`}
                                className="me-3 text-secondary"
                            >
                                <i className="mdi mdi-pencil font-size-18"></i>
                            </Link>
                            <Link to="#" className="me-3 text-danger" onClick={() => deleteOffer(offerId)}>
                                <i className="mdi mdi-delete font-size-18"></i>
                            </Link>
                        </React.Fragment>
                    );
                },
                disableFilters: true,
                filterable: false,
            },
        ]
        //[categoryMapping]
    );

    const breadcrumbItems = [
        { title: "Offers", link: "/merchant/offers" },
        { title: "Offers List", link: "#" },
    ];

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Offers List" breadcrumbItems={breadcrumbItems} />
                    <Link to="/route-money/merchant/product-offer/add" className="btn btn-primary mb-3">
                        Create Offer
                    </Link>

                    {deleteSuccessMessage && <Alert color="success">{deleteSuccessMessage}</Alert>}

                    <Card>
                        <CardBody>
                            <TableContainer
                                columns={columns || []}
                                data={offers || []}
                                isPagination={false}
                                iscustomPageSize={false}
                                isBordered={false}
                                customPageSize={10}
                                isEmpty={isOfferListEmpty}
                                isEmptyContent="There are no offers available currently."
                            />
                        </CardBody>
                    </Card>
                </Container>
            </div>
            <Modal isOpen={deleteModal} toggle={toggleDeleteModal}>
                <ModalHeader toggle={toggleDeleteModal}>Confirm Delete</ModalHeader>
                <ModalBody>Are you sure you want to delete this Offer?</ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggleDeleteModal}>
                        Cancel
                    </Button>
                    <Button color="danger" onClick={confirmDelete}>
                        Confirm
                    </Button>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
};

export default MerchantProductOffers;
