import React, { useMemo,useRef, useState, useEffect } from "react";
import TableContainer from "../../../../components/Common/TableContainer";

//Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import { Button, Card, CardBody, Container, Modal, ModalHeader, ModalBody, ModalFooter,Spinner, Alert,Row, Col,Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import BACKEND_DOMAIN from "../../../../service";
import '../../utilities/Display.css';
import { useDispatch } from 'react-redux';
import ReactCrop from "react-image-crop";
import { loginUserSuccessful } from "../../../../store/actions";

// import branch recent activities
import MiniWidgets from "../../../Dashboard/Branch/MiniWidgets";
import RevenueAnalytics from "../../../Dashboard/Branch/RevenueAnalytics";
import SalesAnalytics from "../../../Dashboard/Branch/SalesAnalytics";
import EarningReports from "../../../Dashboard/Branch/EarningReports";
// import LatestTransactions from "../../../Dashboard/Branch/LatestTransactions";
import LatestTransactions from "./RecentActivities/LatestTransactions";
import RecentOrders from "../../../Dashboard/Branch/RecentOrders";
import {
    fetchProductsOrdersAndBranches,
    fetchProductOffersData,
    fetchCashbackOffersData,
    
} from "../../../Dashboard/Branch/data_fetching/dashboardData";


const MerchantBranchList = () => {
    const dispatch = useDispatch();
    const [branchproductsCount, setBranchProductsCount] = useState(0);
    const [cumulativebranchproductsCount, setCumulativeBranchProductsCount] = useState(0);
    const [branchesCount, setBranchesCount] = useState(0);
    const [ordersCount, setOrdersCount] = useState(0);
    const [cumulativeordersCount, setCumulativeOrdersCount] = useState(0);
    const [productOffers, setProductOffers] = useState(0);
    const [cashbackOffers, setCashbackOffers] = useState(0);
    const [walletBalance, setWalletBalance] = useState(0);
    const [cumulativewalletBalance, setCumulativeWalletBalance] = useState(0);

    const [errorMessage, setErrorMessage]= useState("");
    const [successMessage, setSuccessMessage]= useState("");
    const [networkmessage, setNetworkMessage] = useState("")

    const [branches, setBranches] = useState([]);
    const [noBranches, setNoBranches] = useState(false);
    const [loading, setLoading] = useState(true);  // Added loading state

    // menu
    const [menu, setMenu] = useState(false);


    const authToken = useSelector((state) => state.Login.token);

    const [deleteModal, setDeleteModal] = useState(false);
    const [branchIdToDelete, setBranchIdToDelete] = useState(null);
    const [deletionSuccessMessage, setDeletionSuccessMessage] = useState("");

    const toggleDeleteModal = () => setDeleteModal(!deleteModal);
    const menuToggle = () => setMenu(!menu);

    // display a branch on modal
    const [branchModal, setBranchModal] = useState(false);
    const [branchIdToDisplay, setBranchIdToDisplay] = useState(null);
    const [selectedBranch, setSelectedBranch]=useState(null);
    //const [deletionSuccessMessage, setDeletionSuccessMessage] = useState("");



    const toggleDisplayModal = () => setBranchModal(!branchModal);

 
 

    // fetch branch dashboard data
    const setDashboardInfo = async (token) => {
        try {
            const [
              
                dashboardData,
                productOffersCount,
                cashbackOffersCount,
                walletBalanceData
            ] = await Promise.all([
                fetchProductsOrdersAndBranches(token),
                fetchProductOffersData(token),
                fetchCashbackOffersData(token),
              
            ]);

           // setProductsCount(dashboardData.cards_data.products_count);
            setBranchesCount(dashboardData.cards_data.branches_count);
          //  setOrdersCount(dashboardData.cards_data.orders_count);
          //  console.log('no of branches ',dashboardData.cards_data.branches_count);
            setProductOffers(productOffersCount);
            setCashbackOffers(cashbackOffersCount);
           // setWalletBalance(walletBalanceData.ledger_balance); // Ensure correct access to ledger_balance
        } catch (e) {
            console.error("Failed to fetch dashboard data ->", e);
        }
    };

    useEffect(() => {
        setDashboardInfo(authToken);
    }, [authToken]);


const fetchWalletBalanceData = async (authToken, branchId) => {
    const branchWalletApi = `https://backoffice.route.money/api/v1/wallets/merchant/branch/balance/${branchId}`;
   
    try {
        
        const response = await axios.get(branchWalletApi, {
            headers: {
                Authorization: `Bearer ${authToken}`,
                "Content-Type": "application/json",
            },
        });
       // console.log('branch balance api ', response.data.data.wallet.ledger_balance);
        setWalletBalance(response.data.data.wallet.ledger_balance); // Ensure correct access to nested data
    } catch (error) {
        console.error("Failed to fetch wallet balance -> ", error);

        // Handle and display the error
        if (error.response) {

            // Set a user-friendly error message
            setNetworkMessage(`Error: ${error.response.data.message || "Failed to fetch wallet balance."}`);
            setTimeout(() => {
                //setErrorMessage("");
                setNetworkMessage("");
            }, 3000);
        } else if (error.request) {
            // The request was made but no response was received
          //  console.log('Request data:', error.request);

            setNetworkMessage("Error: No response received. Please check your network connection.");

            setTimeout(() => {
                //setErrorMessage("");
                setNetworkMessage("");
            }, 3000);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error message:', error.message);

            setErrorMessage("Error: Unable to fetch wallet balance. Please try again later.");

            setTimeout(() => {
                setErrorMessage("");
                //setNetworkMessage("");
            }, 3000);
        }
    }

};




const handleSwitch = async () => {
    const branchEmail = selectedBranch.email;
    const branchname = selectedBranch.name;
    const AccountApi = `https://backoffice.route.money/api/v1/admin/merchants/switch-user/`;

    try {
        const response = await fetch(AccountApi, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${authToken}`,
            },
            body: JSON.stringify({
                email: branchEmail,
            }),
        });

        const data = await response.json();

        if (!response.ok) {
            console.log('Failed to switch account:', data);
            throw new Error(data.message || "Failed to switch account");
        } else {
            const currentMerchInfo = localStorage.getItem("authUser");

           // console.log('current login info ',currentMerchInfo);

            // Ensure the role is set to "Manager" if it's empty
            if (!data.user.role) {
                data.user.role = "Manager";
            }else{
                data.user.role = "Manager";
            }

            // Store the new user data in localStorage
            localStorage.setItem("merchantUser", currentMerchInfo);
            localStorage.setItem("authUser", JSON.stringify(data.user));
            const token = data.user.token;
            localStorage.setItem("authToken", token);
                // Store the refresh flag in localStorage
            localStorage.setItem("refreshSidebarMenu", "true");

            // Check if the data is correctly stored
            const storedMerchantUser = localStorage.getItem("merchantUser");
            const storedAuthUser = localStorage.getItem("authUser");
            const storedAuthToken = localStorage.getItem("authToken");

            if (
                storedMerchantUser === currentMerchInfo &&
                storedAuthUser === JSON.stringify(data.user) &&
                storedAuthToken === token
            ) {
                // Open a new tab and navigate to the dashboard
                setSuccessMessage(`checking into ${branchname} dashboard`);
                const systemAbsolutePath = window.location.href;
                const baseAbsolutePath = systemAbsolutePath.split('#/route-money/')[0] + '#/route-money/';

                dispatch(loginUserSuccessful(data.user));

                // Define the new relative path you want to navigate to
                const newRelativePath = 'branch/dashboard';

                // Construct the new absolute path
                const newAbsolutePath = `${baseAbsolutePath}${newRelativePath}`;

                const newTab = window.open(newAbsolutePath, '_blank');
                setTimeout(() => {
                    setSuccessMessage("");
                 
                }, 3000);
                if (newTab) {
                    newTab.focus();
                } else {
                    console.error('Failed to open a new tab. Please check your browser settings.');
                    setErrorMessage('Failed to open a new tab. Please check your browser settings.');
                    setTimeout(() => {
                        setErrorMessage("");
                     
                    }, 3000);
                }
            } else {
                console.error('Failed to store user data correctly in localStorage.');
                setErrorMessage("Failed to switch into desired branch Account");
                setTimeout(() => {
                    setErrorMessage("");
                 
                }, 3000);
               
            }
        }
    } catch (e) {
        console.error("Failed to switch account-> ", e);
        throw e;
    }
};


const fetchSummaryCards = async () =>{

    const cardsApi=`https://backoffice.route.money/api/v1/business/merchant/branch/cards/`

    try{
            const response = await axios.get(cardsApi,{
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    "Content-Type": "application/json",
                },
            });
    
          //  console.log('cumulative card data is ',response.data.order_count);
            setCumulativeBranchProductsCount(response.data.product_count); // Ensure correct access to nested data
            setCumulativeOrdersCount(response.data.order_count);

    }catch(e){
        console.log('error fetching cards data', e)
    }
}


const fetchProductsOrders = async (authToken, branchId) => {
    const productOrderApi = `https://backoffice.route.money/api/v1/business/merchant/branch/counts/${branchId}`;
  

    try {
        const response = await axios.get(productOrderApi, {
            headers: {
                Authorization: `Bearer ${authToken}`,
                "Content-Type": "application/json",
            },
        });

      //  console.log('branch product count is ',response.data.product_count,' order count ',response.data.order_count);
        setBranchProductsCount(response.data.product_count);
        setOrdersCount(response.data.order_count);

    } catch (e) {
        if (e.response && e.response.status === 500) {
            console.error("Server error (500): ", e.message);
            setNetworkMessage("Your network connection is slow.");
            setTimeout(() => {
               // setErrorMessage("");
                setNetworkMessage("");
            }, 3000);
        } else {
            console.error("Failed to fetch product orders -> ", e.message);
            setErrorMessage("Failed to fetch product orders. Please try again.");

            setTimeout(() => {
                setErrorMessage("");
               // setNetworkMessage("");
            }, 3000);
        }
        setBranchProductsCount(null); // Reset or handle state accordingly
        setOrdersCount(null); // Reset or handle state accordingly
    }
};

    const businessBranchesReports = [
        {
            icon: "ri-currency-line",
            title: "Cumulative Wallet Balance",
            value: `KES 0`,
            rate: "Create pin",
            desc: "From previous month",
            routeTo: "#",
        },
        {
            icon: "ri-shopping-bag-2-line",
            title: "Number of Products",
            value: cumulativebranchproductsCount,
            rate: "2.4%",
            desc: "From previous month",
            routeTo: "/route-money/branch/products",
        },
        {
            icon: "ri-shopping-cart-2-line",
            title: "Total Orders",
            value: cumulativeordersCount,
            rate: "2.4%",
            desc: "From previous month",
            routeTo: "/route-money/branch/order-history",
        },

        
    ];


    const businessperbranchReports = [
        {
            icon: "ri-currency-line",
            title: "Wallet Balance",
            value: `KES ${walletBalance}`,
            rate: "Create pin",
            desc: "From previous month",
            routeTo: "#",
        },
        {
            icon: "ri-shopping-bag-2-line",
            title: "Number of Products",
            value: branchproductsCount,
            rate: "2.4%",
            desc: "From previous month",
            routeTo: "/route-money/branch/products",
        },
        {
            icon: "ri-shopping-cart-2-line",
            title: "Total Orders",
            value: ordersCount,
            rate: "2.4%",
            desc: "From previous month",
            routeTo: "/route-money/branch/order-history",
        },
    ];

    const breadcrumbBranchItems = [
        { title: "Switch Account", link: "#"},
        { title: "Dashboard", link: "#" },
    ];

    const deleteBranch = (branchId) => {
        setBranchIdToDelete(branchId);
        toggleDeleteModal();
    };

    const openBranchModal = (branchId) => {
        const selected = branches.find((branch) => branch.business_id === branchId);

        setSelectedBranch(selected);
        fetchWalletBalanceData(authToken, selected.business_id);
        fetchProductsOrders(authToken, selected.business_id);
        toggleDisplayModal();
    };



    //delete branch
    const confirmDelete = () => {
        if (branchIdToDelete) {
            console.log('branch to delete ',branchIdToDelete);
            const branchDeletionAPIUrl = `${BACKEND_DOMAIN}/business/merchant/branch/delete/${branchIdToDelete}`;

            fetch(branchDeletionAPIUrl, {
                method: "DELETE",
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    "Content-Type": "application/json",
                },
            })
                .then((response) => {
                    if (response.ok) {
                        const updatedBranches = branches.filter((branch) => branch.business_id !== branchIdToDelete);
                        setBranches(updatedBranches);
                        setDeletionSuccessMessage("Branch deleted successfully!");
                        setTimeout(() => {
                            setDeletionSuccessMessage("");
                        }, 3000);
                    } else {
                        console.error("Error deleting branch: ", response.status);
                    }
                })
                .catch((error) => console.error("Error deleting branch: ", error))
                .finally(() => {
                    setBranchIdToDelete(null);
                    toggleDeleteModal();
                });
        }
    };

    const apiUrlBranch = `${BACKEND_DOMAIN}/business/merchant/branch/list`;

  
    const fetchData = async (token, isInitial = true) => {
        try {
            if (isInitial) setLoading(true); 
            const response = await axios.get(apiUrlBranch, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            });
            if (response.data.data.length === 0) {
                setNoBranches(true);
            } else {
                setNoBranches(false);
            }
            setBranches(response.data.data);
        } catch (e) {
            console.log("Error fetching branches -> ", e);
        }
        finally {
            if (isInitial) setLoading(false); // Set loading to false once data is loaded
        }
    };
    
  
    useEffect(() => {
        if (authToken) {
            fetchData(authToken, true); // Pass authToken and true for initial load
            fetchSummaryCards(); // Assuming you have this function defined elsewhere

            const intervalId = setInterval(() => {
                fetchData(authToken, false); // Pass authToken and false for polling
            }, 30000); // Poll every 10 seconds

            // Cleanup interval on component unmount
            return () => clearInterval(intervalId);
        }
    }, [authToken]);


    const columns = useMemo(() => [
        {
            Header: "Branch Name",
            accessor: "name",
            disableFilters: true,
            filterable: false,
        },
        {
            Header: "Location",
            accessor: "location",
            disableFilters: true,
            filterable: false,
        },
        {
            Header: "Contact Person",
            accessor:"contact_person",
            disableFilters: true,
            filterable: false,
        },
        {
            Header: "Email",
            accessor: "email",
            disableFilters: true,
            filterable: false,
        },
        {
            Header: "Phone Numnber",
            accessor: "phone_number",
            disableFilters: true,
            filterable: false,
        },
        {
            Header: "Action",
            accessor: (cellProps) => {
                const branchId = cellProps.business_id;

                return (
                    <React.Fragment>
                           <Link to="#" className="me-3 text-primary" onClick={() => openBranchModal(branchId)}>
                                    <i className="mdi mdi-eye font-size-18"></i>
                                </Link>
                        {/* <Link to={`/route-money/merchant/branch/edit/${branchId}`} className="me-3 text-secondary">
                            <i className="mdi mdi-pencil font-size-18"></i>
                        </Link> */}
                        <Link to="#" className="me-3 text-danger" onClick={() => deleteBranch(branchId)}>
                            <i className="mdi mdi-delete font-size-18"></i>
                        </Link>
                    </React.Fragment>
                );
            },
            disableFilters: true,
            filterable: false,
        },
    ]);

    const breadcrumbItems = [
        { title: "Branches", link: "/" },
        { title: "Branch List", link: "#" },
    ];

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Branch List" breadcrumbItems={breadcrumbItems} />
                    <Link to="/route-money/merchant/branch/add" className="btn btn-primary mb-3">
                        Add Branch
                    </Link>

                    <Row>
               <Col xl={12}>
             
            </Col>
          </Row>

                    {deletionSuccessMessage && <Alert color="success">{deletionSuccessMessage}</Alert>}
                    <Card>
                        <CardBody>
                            <TableContainer
                                columns={columns || []}
                                data={branches || []}
                                isPagination={false}
                                iscustomPageSize={true}
                                isGlobalFilter={true}
                                isBordered={false}
                                customPageSize={10}
                                isEmpty={noBranches}
                                isEmptyContent="No branches have been added yet."
                            />
                        </CardBody>
                    </Card>
                </Container>
            </div>
            <Modal isOpen={deleteModal} toggle={toggleDeleteModal}>
                <ModalHeader toggle={toggleDeleteModal}>Confirm Delete</ModalHeader>
                <ModalBody>Are you sure you want to delete this branch?</ModalBody>

                <ModalFooter>
                    <Button color="secondary" onClick={toggleDeleteModal}>
                        Cancel
                    </Button>
                    <Button color="danger" onClick={confirmDelete}>
                        Confirm
                    </Button>
                </ModalFooter>
            </Modal>

            {/* Branch summary modal */}
            <Modal
            isOpen={branchModal}
            role="document"
            autoFocus={true}
            centered={true}
            fullscreen={true}// or set to true for full screen on all breakpoints
            className="composemodal"
            toggle={toggleDisplayModal}
            >
  <div className="modal-content">
  <ModalHeader className="d-flex justify-content-between align-items-center w-100">
  <span className="d-flex align-items-center" style={{ position:"absolute", top: "-20px",  right:0}}>
    <Dropdown isOpen={menu} toggle={menuToggle} className="user-dropdown">
      <DropdownToggle
        tag="button"
        className="btn header-item waves-effect"
        id="page-header-user-dropdown"
      >
        <span className="ms-1">
          <strong>{selectedBranch && selectedBranch.name}</strong>
        </span>
        <i className="mdi mdi-chevron-down d-none ms-1 d-xl-inline-block"></i>
      </DropdownToggle>

      <DropdownMenu className="dropdown-menu-end">
        <DropdownItem className="text-success" onClick={handleSwitch}>
          <i className="ri-user-line align-middle me-1"></i> Switch Account
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  </span>
</ModalHeader>

    <ModalBody style={{ overflowY: "auto" }}>
      {selectedBranch && (
        <>
     {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
     {networkmessage && <Alert color="warning">{networkmessage}</Alert>}
     {successMessage && <Alert color="success">{successMessage}</Alert>}

          <Row>
            <Col xl={12}>
              <Row>
                <MiniWidgets reports={businessperbranchReports} />
              </Row>
            </Col>
          </Row>
          <Row>
          {selectedBranch ? (
            <>
               <LatestTransactions branchId={selectedBranch.branch_id} />
               {/* <LatestTransactions branchId={selectedBranch.branch_id} /> */}
            </>
                 
                ) : (
                    <p>Please select a branch to view transactions.</p>
                )}
         
          </Row>
        </>
      )}
    </ModalBody>

    <ModalFooter>
      <Button color="warning" onClick={toggleDisplayModal}>
        Cancel
      </Button>
    </ModalFooter>
  </div>
</Modal>

               {/* Modal for Spinner */}
               <Modal isOpen={loading} centered>
                <ModalBody className="text-center">
                    <Spinner style={{ width: '3rem', height: '3rem' }} color="primary" />
                    <div>Loading...</div>
                </ModalBody>
            </Modal>

    

        </React.Fragment>
    );
};

export default MerchantBranchList;
