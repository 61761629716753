import React, { useMemo } from "react";
import TableContainer from "../../../../components/Common/TableContainer";

//Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import { Card, CardBody, Container } from "reactstrap";

const MerchantTransactionFees = () => {
    const columns = useMemo(
        () => [
            {
                Header: "Fee Amount",
                accessor: "fee",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Transacted Amount",
                accessor: "amount",
                disableFilters: true,
                filterable: false,
            },

            {
                Header: "Type",
                accessor: "type",
                disableFilters: true,
                filterable: false,
            },
        ],
        []
    );

    const breadcrumbItems = [
        { title: "Reports", link: "/" },
        { title: "Transaction Fees", link: "#" },
    ];

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Transaction Fees" breadcrumbItems={breadcrumbItems} />
                    <Card>
                        <CardBody>
                            <TableContainer
                                columns={columns || []}
                                data={[] || []}
                                isPagination={false}
                                // isGlobalFilter={false}
                                iscustomPageSize={false}
                                isBordered={false}
                                customPageSize={10}
                            />
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default MerchantTransactionFees;
