export const ORDER_TYPE_COLORS = {
    Delivery: "success",
    "DELIVERY": "success",
    "Book Table": "warning",
    Pickup: "danger",
    "PICK-UP": "danger",
    "IN-STORE": "secondary",
    "Dine In": "secondary",
    "RESERVE": "warning",
};
