import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Row, Col, Alert, Button, Container, Input, Label } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { AvForm } from "availity-reactstrap-validation";
import axios from "axios";
import { forgetUser } from "../../store/actions";
import logodark from "../../assets/images/logo-dark.png";
import logolight from "../../assets/images/logo-light-new.jpg";
import withRouter from "../../components/Common/withRouter";
import { clearEmailFromSession, getEmailFromSession } from "./utilities/sessionWork";
import BACKEND_DOMAIN from "../../service";

const PASSWORD_RESET_SUCCESS_MESSAGE = "Your password has been successfully reset. Redirecting you to the login page.";

const ResetPasswordPage = () => {
    const [email, setEmail] = useState(null);
    const [password1, setPassword1] = useState("");
    const [password2, setPassword2] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [passwordMatch, setPasswordMatch] = useState(true);
    const [passwordResetSuccessful, setPasswordResetSuccessful] = useState(false);
    const [passwordResetErrorMessage, setPasswordResetErrorMessage] = useState(null);
    const [passwordVisible, setPasswordVisible] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        setEmail(getEmailFromSession());
    }, []);

    const handlePasswordChange = (e) => {
        setPassword1(e.target.value);
    };

    const handleConfirmPasswordChange = (e) => {
        setPassword2(e.target.value);
    };

    const handleBlur = () => {
        setPasswordMatch(password1 === password2);
    };

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const handleValidSubmit = async (event) => {
        event.preventDefault();

        if (password1 === "" || password2 === "") {
            setPasswordResetErrorMessage("The fields cannot be empty");
            setTimeout(() => {
                setPasswordResetErrorMessage(null);
            }, 3000);

            return;
        }
        if (!passwordMatch) {
            setPasswordResetErrorMessage("Passwords entered do not match");
            setTimeout(() => {
                setPasswordResetErrorMessage(null);
            }, 3000);

            return;
        }

        setIsLoading(true);

        const apiUrl = `${BACKEND_DOMAIN}/admin/merchants/password-reset-confirm`;

        setIsLoading(true);

        try {
            const formData = new FormData();

            formData.append("email", email);
            formData.append("password", password1);

            await axios.post(apiUrl, formData);

            setPasswordResetSuccessful(true);

            setPasswordResetSuccessful(PASSWORD_RESET_SUCCESS_MESSAGE);

            clearEmailFromSession();

            setTimeout(() => {
                navigate("/route-money/merchant/login");
            }, 3000);
        } catch (e) {
            setPasswordResetErrorMessage("An error occurred.");
            setTimeout(() => {
                setPasswordResetErrorMessage(null);
            }, 3000);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div>
            <Container fluid className="p-0">
                <Row className="g-0">
                    <Col lg={4}>
                        <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                            <div className="w-100">
                                <Row className="justify-content-center">
                                    <Col lg={9}>
                                        <div>
                                            <div className="text-center">
                                                <div>
                                                    <Link to="/" className="">
                                                        <img
                                                            src={logodark}
                                                            alt=""
                                                            height="35"
                                                            className="auth-logo logo-light mx-auto"
                                                        />

                                                        <img
                                                            src={logolight}
                                                            alt=""
                                                            height="35"
                                                            className="auth-logo logo-dark mx-auto"
                                                        />
                                                    </Link>
                                                </div>

                                                <h4 className="font-size-18 mt-4">Change Password</h4>
                                                <p className="text-muted">
                                                    Reset your password to access your account.
                                                </p>
                                            </div>

                                            <div className="p-2 mt-3">
                                                {passwordResetSuccessful && (
                                                    <Alert color="success" className="mb-4">
                                                        {PASSWORD_RESET_SUCCESS_MESSAGE}
                                                    </Alert>
                                                )}

                                                <AvForm className="form-horizontal" onValidSubmit={handleValidSubmit}>
                                                    <div className="mb-1">
                                                        <Label className="form-label" htmlFor="password">
                                                            Password <span className="text-danger">*</span>
                                                        </Label>
                                                        <Input
                                                            name="password"
                                                            value={password1}
                                                            onChange={handlePasswordChange}
                                                            onBlur={handleBlur}
                                                            validate={{
                                                                required: true,
                                                            }}
                                                            type={passwordVisible ? "text" : "password"}
                                                            className="form-control"
                                                            id="password1"
                                                            placeholder="Enter password"
                                                        />
                                                        <div className="d-flex justify-content-end mt-1">
                                                            <small
                                                                className="text-muted cursor-pointer"
                                                                onClick={togglePasswordVisibility}
                                                            >
                                                                {passwordVisible ? "Hide" : "Show"}
                                                            </small>
                                                        </div>
                                                    </div>

                                                    <div className="mb-3 d-flex flex-column">
                                                        <Label className="form-label" htmlFor="confirmPassword">
                                                            Confirm Password <span className="text-danger">*</span>
                                                        </Label>
                                                        <Input
                                                            name="confirmPassword"
                                                            value={password2}
                                                            onChange={handleConfirmPasswordChange}
                                                            onBlur={handleBlur}
                                                            validate={{ required: true }}
                                                            type={passwordVisible ? "text" : "password"}
                                                            className={`form-control ${
                                                                passwordMatch ? "" : "is-invalid"
                                                            }`}
                                                            id="password2"
                                                            placeholder="Confirm password"
                                                        />

                                                        {!passwordMatch && (
                                                            <div className="invalid-feedback">
                                                                Passwords do not match
                                                            </div>
                                                        )}
                                                        <small
                                                            style={{
                                                                marginTop: !passwordMatch ? "-18px" : 2,
                                                            }}
                                                            className="text-muted cursor-pointer align-self-end"
                                                            onClick={togglePasswordVisibility}
                                                        >
                                                            {passwordVisible ? "Hide" : "Show"}
                                                        </small>
                                                    </div>

                                                    {passwordResetErrorMessage && (
                                                        <Alert color="danger" className="mb-4">
                                                            {passwordResetErrorMessage}
                                                        </Alert>
                                                    )}

                                                    <div className="mt-4 text-center">
                                                        <Button
                                                            color="primary"
                                                            className="w-md waves-effect waves-light"
                                                            type="submit"
                                                            disabled={isLoading}
                                                        >
                                                            {isLoading ? (
                                                                <>
                                                                    <span
                                                                        className="spinner-border spinner-border-sm"
                                                                        role="status"
                                                                        aria-hidden="true"
                                                                    ></span>
                                                                    <span className="ms-2">Resetting..</span>
                                                                </>
                                                            ) : (
                                                                "Reset Password"
                                                            )}
                                                        </Button>
                                                    </div>
                                                </AvForm>
                                            </div>

                                            <div className="mt-3 text-center">
                                                <p>
                                                    Back to{" "}
                                                    <Link
                                                        to="/route-money/merchant/login"
                                                        className="fw-medium text-primary"
                                                    >
                                                        {" "}
                                                        Log in{" "}
                                                    </Link>{" "}
                                                </p>
                                                <p className="mt-5">© {new Date().getFullYear()} Route Money.</p>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                    <Col lg={8}>
                        <div className="authentication-bg"></div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

const mapStateToProps = (state) => ({
    message: state.Forget.message,
    forgetError: state.Forget.forgetError,
    loading: state.Forget.loading,
});

export default withRouter(connect(mapStateToProps, { forgetUser })(ResetPasswordPage));
