import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';

const UserRoles = () => {
  const token = useSelector((state) => state.Login.token);
  const [roles, setRoles] = useState([]);
  const [permissions, setPermissions] = useState([]);

  useEffect(() => {
    if (token) {
      const fetchRoles = async () => {
        try {
          const response = await axios.get('https://backoffice.route.money/api/v1/admin/roles', {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          });
          setRoles(response.data.results);
        } catch (error) {
          console.error("Error fetching roles:", error);
        }
      };


      fetchRoles();
  
    }
  }, [token]);

  return { roles };
};

export default UserRoles;
