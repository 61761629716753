import React, { useState, useEffect } from "react";

const CopyLink = ({ url, slugName }) => {
    const [copied, setCopied] = useState(false);
    const [truncatedUrl, setTruncatedUrl] = useState("");

    const combinedUrl = url + "?merch=" + slugName;

    useEffect(() => {
        const maxLength = 150; // Adjust the truncation length as needed
        const truncated =
            combinedUrl.length > maxLength
                ? `...${combinedUrl.slice(-(maxLength - 3))}` // Truncate from the beginning
                : combinedUrl;
        setTruncatedUrl(truncated);
    }, [combinedUrl]);

    const copyToClipboard = async () => {
        try {
            if (navigator?.clipboard?.writeText) {
                await navigator.clipboard.writeText(`${url}?merch=${slugName}`);
                setCopied(true);
                setTimeout(() => setCopied(false), 5000);
            } else {
                // Fallback for unsupported browsers
                console.warn("Navigator clipboard not supported. Text not copied.");
            }
        } catch (error) {
            console.error("Failed to copy to clipboard:", error);
        }
    };

    return (
        <div className="p-2 mb-2 d-flex flex-column align-items-center">
            <p className="mb-2">
                <b>Link to your Store Front :</b>
            </p>
            <div
                className="p-2 d-flex align-items-center border border-gray"
                style={{ borderRadius: 8, width: "fit-content" }}
            >
                <span className="truncated-link">
                    <a href={truncatedUrl} target="_blank">
                        {truncatedUrl}
                    </a>
                </span>
                <span className="ms-2" onClick={copyToClipboard}>
                    {copied ? (
                        <i className="ri-check-double-line" style={{ color: "#90EE90", height: 26, width: 26 }}></i>
                    ) : (
                        <i className="ri-file-copy-line" style={{ height: "26px", width: "26px" }}></i>
                    )}
                </span>
            </div>
        </div>
    );
};

export default CopyLink;
