import BACKEND_DOMAIN from "../../../service";
import { CHECK_LOGIN, LOGIN_USER_SUCCESSFUL, API_ERROR, LOGOUT_USER_SUCCESS, SET_AUTH_TOKEN } from "./actionTypes";

//export const SET_AUTH_TOKEN = 'SET_AUTH_TOKEN';

export const checkLogin = (email, password, navigate) => {
    const apiUrl = `${BACKEND_DOMAIN}/admin/merchants/login/`;
    return {
        type: CHECK_LOGIN,
        payload: { email, password, apiUrl, navigate },
    };
};

// Action creator for setting the auth token
export const setAuthToken = (token) => ({
    type: SET_AUTH_TOKEN,
    payload: token,
});

// Action creator for successful login
export const loginUserSuccessful = (user) => ({
    type: LOGIN_USER_SUCCESSFUL,
    payload: user,
});

// Action creator for API error
export const apiError = (error) => ({
    type: API_ERROR,
    payload: error,
});

export const logoutUser = (navigate) => (dispatch) => {
    // Simulate logout without API call
    dispatch({
        type: LOGOUT_USER_SUCCESS,
    });

    // Redirect to login page or any other page after successful logout
    navigate("/route-money/merchant/login");
};

export const logoutUserSuccess = () => {
    return {
        type: LOGOUT_USER_SUCCESS,
        payload: {},
    };
};
